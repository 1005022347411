.kycFormMainContainerForm {
  padding: 15px;
  margin-right: 20px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
}
.kycFormMainContainer {
  background-color: rgb(255, 255, 255);
}
.kycFormMainContainerDisabled {
  background-color: rgb(255, 255, 255);
  pointer-events: none;
  opacity: 0.4;
}
.kycHeading {
  font-family: "Manrope", sans-serif;
  font-size: 25px;
  font-weight: 900;
}
.flex {
  display: block;
}
.uploadIdProof {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 28px;
}
.ErrorMessage {
  color: #f22;
  text-align: start;
  font-size: 14px;
  margin: 0;
}
.ErrorMessage :hover {
  background-color: white;
}
.center {
  text-align: center;
  font-size: 30px;
  margin-top: 150px;
  font-weight: bold;
}
.statusicon {
  font-size: 40px;
  color: green;
}
.size {
  font-size: 20px;
}
.pending {
  background-color: orange;
  width: fit-content;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
}
.rejected {
  background-color: #ff2222;
  color: white;
  width: fit-content;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
}
.approved {
  background-color: #ffde5d;
  color: #000;
  width: fit-content;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
}
.approveAndSubmitted {
  text-align: center;
}
.loadercenter {
  text-align: center;
  margin-top: 3rem;
}
@media screen and (min-width: 769px) {
  .inputArea {
    margin-top: 4rem;
    text-align: center;
  }
  .flex {
    display: flex;
  }
  .flexIdProof {
    display: flex;
  }
  .widthhalfgender {
    width: 40%;
    padding-right: 3rem;
    margin-top: 8px;
  }
  .widthhalf {
    width: 40%;
    padding-right: 3rem;
  }
  .widthThirtyThree {
    width: 33%;
    padding-right: 2rem;
  }
  .submitButton {
    width: 30%;
    margin: auto;
  }
  .uploadIdProof {
    font-family: "Poppins", sans-serif;
    font-size: large;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .widthimageupload {
    width: 40%;
    padding-right: 2rem;
  }
}
