.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}
.backdrop {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  animation-name: example;
  animation-duration: 0.4s;
}
.modal {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 400px;
  padding: 60px 40px 10px 40px;
  border-radius: 10px;
  max-height: 100vh;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.modalLogout {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 350px;
  padding: 30px 40px 40px 40px;
  border-radius: 10px;
  max-height: 100vh;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.icon {
  display: flex;
  justify-content: center;
  margin-top: -95px;
}
.checkIconMain {
  background-color: green;
  border-radius: 50%;
  padding: 10px;
}
.heading {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  padding-top: 30px;
}
.subHeading {
  text-align: center;
  padding-top: 15px;
  font-size: 18px;
  font-weight: 600;
}
.dashboardBtn {
  color: green;
  margin: 20px 10px 10px 0px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.dashboardBtnYes{
  color: green;
  margin: 20px 10px 10px 0px;
  border: 1px solid green;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.dashboardBtnYes:hover{
  color: rgb(70, 223, 70);
  border-color: rgb(70, 223, 70);
}
.dashboardBtn:hover {
  color: rgb(70, 223, 70);
}
.dashboardIcon {
  margin-top: 2px;
}
.joinBtn {
  color: #1778f2;
  margin: 20px 10px 10px 0px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.joinBtn:hover {
  color: #3f75b8;
}
.joinBtnNo {
  color: #1778f2;
  margin: 20px 10px 10px 0px;
  border: 1px solid #1778f2;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.joinBtnNo:hover {
  color: #3f75b8;
  border-color: #3f75b8;
}
.mandatoryFields {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
}
.actionBtnContainer {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: auto;
  padding: 8px 0px 10px 8px;
}
.actionBtnContainerLogout{
  display: flex;
  justify-content: space-between;
  width: 140px;
  margin: auto;
  padding: 8px 0px 10px 8px;
}
.okBtnContainer {
  display: flex;
  justify-content: center;
}
.okBtn{
  text-align: center;
  border: none;
  border-radius: 6px;
  width: 50px;
  padding: 6px 0px;
  margin: 20px auto 0px;
  background-color: #fcd017;
  color: #000;
  font-weight: 700;
  cursor: pointer;
}
.okBtn:hover{
  background-color: #3f75b8;
  color: #fff;
  font-weight: 700;
}
.cancelBtnContainer{
  font-size: 16px;
  text-align: right;
 
  color: white;
  background-color: rgb(250 0 0);
  opacity: 0.9;
  padding: 5px 10px;
  width: 55px;
  margin: 22px 0px 8px;

  display: flex;
  float: right;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .modal {
    width: 80%;
    padding: 40px 20px 30px 20px;
  }
  .backdrop {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    animation-name: example;
    animation-duration: 0.4s;
  }
  .icon {
    display: flex;
    justify-content: center;
    margin-top: -75px;
  }
  .joinBtn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-left: 26px;
  }
  .dashboardBtn {
    display: flex;
    justify-content: center;
    padding-right: 26px;
  }
  .subHeading {
    text-align: center;
    padding-top: 15px;
    font-size: 16px;
    font-weight: 600;
  }
  .actionBtnContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .cancelBtnContainer{
    font-size: 16px;
    text-align: right;
    color: white;
    background-color: rgb(250 0 0);
    opacity: 0.9;
    padding: 5px 10px;
    width: 55px;
    margin: 22px 0px 8px;
  float: none;
    margin: 10px auto 0px;
    cursor: pointer;
  }
  .actionBtnContainerLogout{
    display: flex;
    justify-content: space-between;
    width: 140px;
    margin: auto;
    padding: 8px 0px 10px 8px;
  }
  .modalLogout {
    background-color: white;
    margin: auto;
    position: relative;
    font-weight: 500;
    width: 250px;
    padding: 30px 40px 20px 40px;
    border-radius: 10px;
    max-height: 100vh;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 30;
  }
}
