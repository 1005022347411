.iconContainer{
    border-radius: 50px; 
  
    color: black;
    background-color: #FCD017;
   }
   .iconContainer:hover{
     background-color: #f5f5f5;
     color: rgb(0, 0, 0);
   }
   .iconContainerLeftMenu{
     border-radius: 50px; 
     background-color: white;
    color:  #1778f2;
   }
   .innerInitial{
     padding: 10px;
     font-size: 18px;
     font-weight: bold;
   }
   .innerInitialLeftMenu{
     padding: 28px;
     font-size: 18px;
     font-weight: bold;
   }
   
   .smalliconContainer{
   width: fit-content;
   border-radius: 50px;
   background-color: #f5f5f5;
   margin-left: 4px;
   margin-right: 4px;
   }
   .smallinnerInitial{
     padding: 6px;
     font-size: 12px;
     font-weight: bold; 
     margin: auto;
    
   }
   .smalliconContainer:hover{
     background-color:  #1778f2;
     color: white;
     cursor: pointer;
   }
   
   .profilePicture{
     object-fit: cover;
    
     border-radius: 50px;
   }
   .profileImage{
   
     width: 40px;
    height: 40px;
   }
   .profileImageLeftMenu{
     width: 60px;
     height: 60px;
   }
   .logooverlaped {
     display: flex;
     justify-content: end;
     margin: 1rem;
   
   }
   .freelancerProfileContainer{
     height: 40px;
   margin-left: -20px;
     width: 40px;
   }
   .logo_images {
     width: 100%;
     height: 100%;
     border: 1px solid #fff;
    cursor: pointer;
     object-fit: cover;
     border-radius: 50%;
   }
  