.incomeBox {
  padding: 10px;
  display: flex;
  border: 1px solid #c8c8c8;
  align-items: center;
  margin: 20px;
  background-color: white;
 
}

.incomeAmount {
  margin-left: 20px;
  width: 80%;
}

.amount {
  font-size: 20px;
  font-weight: bold;
}
.amountHeading {
  font-size: 20px;
}


.amountHeadingSection {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.ActionBtn{
    display: flex;
    justify-content: space-around;
}
@media screen and (min-width: 768px) {
  .mainContainer {
    display: flex;
    justify-content: space-evenly;
  }
  .incomeBox {
    width: 100%;
  }
  
}
