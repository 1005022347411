@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Pushster&family=Teko:wght@500&display=swap);



body {
  margin: 0;
   font-family: 'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

.border{
  border-bottom: 1px solid #dedede;
  width: 90%;
  margin: auto;
 
  padding: 10px;

  
}
.helpborder{
  border-bottom: 1px solid #dedede;
  width: 40%;
  margin: auto;
 padding-top: 35px;
 

  
}

.rsIcon{
  content: "\20B9";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bm-burger-button button{
  width: 50px !important;
  height: 50px !important;
}
.bm-burger-bars{
  height: 1% !important;
}
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}


.mainPage {
  background-color: white;
  -webkit-tap-highlight-color: transparent;
}

div {
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  
}

h1 {
  color: #1e1e1e;
  font-family: 'Manrope', sans-serif;
  font-size: 35px;
  font-weight: 600;
  
  
}
h2 {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}
h3 {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}
label {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 500;
}
p {
  font-family:"Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}


h6{
  font-family: 'Teko', sans-serif;
}

textTrasition{
  font-family: 'Pushster', cursive;
}

.screeWidth{
  max-width: 1600px;

  margin: auto;
}


.linkTag{
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  display: none;
}

.Login_mainContainer__5Jy0j {
  padding: 20px;
}
.Login_inputFields_check__QM4Tg {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  align-items: center;
  cursor: pointer;
}
.Login_input_container__2SK5w {
  margin-top: 25px;
}
.Login_checkbox__1d39Q {
  cursor: pointer;
}
.Login_checkbox_text__2n6Rn {
  color: #858585;
  margin-left: 4px;
  align-items: center;
}
.Login_LoadingBtn__2Rfq7 {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;

}
.Login_signup_link__1dv18 {
  color: #858585;
  text-align: center;
  margin-top: 15px;
  text-decoration: none;
  cursor: pointer;
}
.Login_loginLink__2TY8b {
  display: none;
}
.Login_login_with__vajnz {
  text-align: center;
  color: #858585;
  margin-top: 40px;
}
.Login_terms_link__3_cF- {
  color: #1778f2;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}
.Login_freelancerlink__1NlLG {
  display: block;
  color: #1778f2;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  padding-top: 20px;
}
.Login_checkbox__1d39Q {
  padding: 2px;
}
.Login_forgot_password__bOt8D {
  color: #1778f2;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.Login_ActionBtn__whqG0 {
  display: flex;
  justify-content: center;
}
.Login_back_button__RA9Sf {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.Login_loginLink__2TY8b {
  display: none;
}
.Login_error_message__UUUbg {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Login_loginWith_Otp__2mUYA {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .Login_mainContainer__5Jy0j {
    padding: 30px;
    text-align: center;
    background-color: white;
  }
  .Login_signup_link__1dv18 {
    display: none;
  }


  .Login_Maincontainer_border__3ABxe {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
  .Login_Pagewrapper__QVH2r {
    background-color: #fcd01708;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .Login_freelancerlink__1NlLG {
    padding-top: 8px;
  }
  .Login_loginLink__2TY8b {
    display: block;
    color: #858585;
    text-align: center;
    margin-top: 15px;
    text-decoration: none;
    cursor: pointer;
  }
}

.SignUp_mainContainer__17P0D {
  padding: 20px;
  background-color: white;
}

.SignUp_inputFields_check__3Joaq {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.SignUp_checkbox__QUvKB{
  cursor: pointer;
}
.SignUp_checkbox_text__3-EDF {
  color: #858585;
  align-items: center;
  margin-left: 4px;

}
.SignUp_LinkText__3GbpC{
  text-decoration:none ;
  color: #1778f2;
}
.SignUp_forgot_password__6AN6R {
  color: #858585;
  text-align: center;
  margin-top: 15px;
}
.SignUp_loginLink__xyPej{
  display: none;
}
.SignUp_input_container__3Hl1H{
  margin-top: 25px;
}
.SignUp_login_with__19YqX {
  text-align: center;
  color: #858585;
  margin-top: 20px;
}
.SignUp_terms_link__1SAce {
  color: #1778f2;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 10px;
}
.SignUp_checkbox__QUvKB {
  padding: 2px;
}
.SignUp_ErrorMessage__3q144 {
  color: #ff2222;
  text-align: start;
  
  font-size: 14px;
  margin:0px;
}
.SignUp_error_message__1Uce1 {
  color: rgb(250, 0, 0);
  display: flex;
  text-align: start;
  align-items: center;
 
  font-size: 14px;
  margin:0px;
}

.SignUp_LoadingBtn__3eU2b {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 8px 8px;
  width: 50%;
  margin-top: 25px;
  border: none;
}
.SignUp_back_button__k2VkK {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.SignUp_freelancerlink__VpSsP {
  display: block;
  color: #1778f2;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  padding-top: 8px
}
.SignUp_nameFields__3ethj{
  display: grid;

}
.SignUp_lastName__1_gc8{
  margin-left: 0px;
}
.SignUp_EmailIcon__1Mz1h{
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border: 1px solid #ece9e9;
  margin: 10px 0px 20px 0px;
  font-size: 18px;
  justify-content: space-between;
}
.SignUp_EmailText__1-QsR{
  padding-top: 8px;
}
.SignUp_EmailIconText__9nhaA{
  font-size: 14px;
}
@media screen and (min-width: 1024px) {
  .SignUp_mainContainer__17P0D {
    padding: 20px;
    text-align: center;
  }
  .SignUp_inputFields_check__3Joaq{
    align-items: center;
  }
  .SignUp_freelancerlink__VpSsP {
    display: block;
    color: #1778f2;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    padding-top: 8px
  }
  .SignUp_mainContainer__17P0D:h1{
    font-size: 35px;
    font-weight: 700;
  }
 .SignUp_forgot_password__6AN6R{
   display: none  ;
 }
  .SignUp_loginLink__xyPej {
    display: block;
    color: #858585;
    text-align: center;
    margin: 15px;
  }
  .SignUp_Pagewrapper__1vYSW{
    background-color: #fcd01708;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .SignUp_Maincontainer_border__1LD4H {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .SignUp_nameFields__3ethj{
    display: flex;

  }
  .SignUp_lastName__1_gc8{
    margin-left: 20px;
  }
}

.ForgotPassword_mainContainer__1SBy9 {
  padding: 2px 20px;
  background-color: white;
}
.ForgotPassword_Maincontainer_border__KLR11{
  background-color: white;
}
.ForgotPassword_inputFields_check__3cmzj {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.ForgotPassword_forgot_password__148m3 {
  color: #858585;
  text-align: center;
  margin-top: 15px;
}
.ForgotPassword_LoadingBtn__1fu6F {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 8px 8px;
  width: 50%;
  margin-top: 25px;
  border: none;
}
.ForgotPassword_resend_code__3okhq {
  text-align: center;
  margin-top: 10px;
  color: #1778F2;
}
.ForgotPassword_resend_code_hover__3hvfR {
  text-align: center;
  margin-top: 10px;
  color: #1778F2;
  cursor: pointer;
}
.ForgotPassword_resend_code_hover__3hvfR:hover{
  color: #858585;
}
.ForgotPassword_checkbox__2cnhn {
  padding: 2px;
}
.ForgotPassword_para_text__14SCQ {
  margin-top: 40px;
 
}
.ForgotPassword_error_message__2thOx {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.ForgotPassword_ActionBtn__2nMEi {
  display: flex;
  justify-content: center;
}
.ForgotPassword_back_button__1lKMG {
  background: #fff;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}
.ForgotPassword_LoadingBtn__1fu6F {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;

}
.ForgotPassword_MobileNumber__1GDmb{
  padding-bottom: 30px;

}
@media screen and (min-width: 1024px) {
  .ForgotPassword_mainContainer__1SBy9 {
    padding: 0px 30px 40px
  }
  .ForgotPassword_Pagewrapper__zJZhA{
    background-color: #fcd01708;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .ForgotPassword_Maincontainer_border__KLR11 {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}
.MobileVerification_mainContainer__iDmBV {
  padding: 2px 20px;
  background-color: white;
}
.MobileVerification_Maincontainer_border__3Khd2 {
  background-color: white;
}
.MobileVerification_para_text__3-Obc {
  margin-top: 20px;
}
.MobileVerification_forgot_password__6P4Er {
  color: #858585;
  text-align: center;
  margin-top: 60px;
}
.MobileVerification_resend_code__2geRt {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
  cursor: pointer;
}
.MobileVerification_error_message__2aGPs {
  color: rgb(250, 0, 0);
text-align: center;
}
.MobileVerification_LoadingBtn__1Md_Z {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;

}
.MobileVerification_resend_code_message__3o1lr {
  text-align: center;
  margin-top: 10px;
  color: #858585;
}
.MobileVerification_ActionBtn__29vIx {
  display: flex;
  justify-content: center;
}
.MobileVerification_outer_otp_div__3ZifN {
  width: 190px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.MobileVerification_otp_div__3RvUD {
  position: sticky;
  left: 0;
}

.MobileVerification_otp_input__elP0D {
  padding-left: 10px !important;
  letter-spacing: 33px;
  font-size: 30px !important;
  border: 0 !important;
  background-image: linear-gradient(
    270deg,
    #616161 70%,
    hsla(0, 0%, 100%, 0) 0
  );
  background-position: bottom;
  background-size: 50px 2px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px !important;
  min-width: 220px;
  border-radius: 0;
  outline: none;
  margin: 20px 0px;
}
.MobileVerification_back_button__2DEms {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .MobileVerification_mainContainer__iDmBV {
    padding: 20px 30px;
  }
  .MobileVerification_Pagewrapper__3fg7I {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .MobileVerification_Maincontainer_border__3Khd2 {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}

.EmailVerification_mainContainer__14TYm {
  padding: 2px 20px 10px 20px;
  background-color: white;
}
.EmailVerification_Maincontainer_border__1JjSq {
  background-color: white;
}
.EmailVerification_error_message__39Bzz {
  color: rgb(250, 0, 0);
  text-align: center;
}
.EmailVerification_para_text__18ybs {
  margin-top: 10px;
}
.EmailVerification_LoadingBtn__280TC {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;

}
.EmailVerification_forgot_password__3b233 {

  text-align: center;
  margin-top: 20px;
}
.EmailVerification_resend_code__25A_K {
  color: #858585;
  text-align: center;
  margin-top: 10px;
}
.EmailVerification_resend_code_link__jL8zS {
  color: #1778f2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.EmailVerification_ActionBtn__17uCP {
  display: flex;
  justify-content: center;
}
.EmailVerification_outer_otp_div__2iCrl {
  width: 190px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.EmailVerification_otp_div__RzseF {
  position: sticky;
  left: 0;
}

.EmailVerification_otp_input__1ylJY {
  padding-left: 10px !important;
  letter-spacing: 33px;
  font-size: 30px !important;
  border: 0 !important;
  background-image: linear-gradient(
    270deg,
    #616161 70%,
    hsla(0, 0%, 100%, 0) 0
  );
  background-position: bottom;
  background-size: 50px 2px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px !important;
  min-width: 220px;
  border-radius: 0;
  outline: none;
  margin: 20px 0px;
}
.EmailVerification_back_button__2pVZJ {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .EmailVerification_mainContainer__14TYm {
    padding: 20px 30px;
  }
  .EmailVerification_Pagewrapper__3LwaM {
    background-color: #fcd01708;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .EmailVerification_Maincontainer_border__1JjSq {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}

.PersonalDetails_mainContainer__1RP7M {
  padding: 0px 20px;
  width: 90%;
}

.PersonalDetails_input_container__1NJlZ {
  margin-top: 25px;
}
.PersonalDetails_radio__2eEyA {
  margin: 13px 0px;
}
.PersonalDetails_CheckBoxInput__1ebTU {
  cursor: pointer;
}
.PersonalDetails_inputArea__3hvpw {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}
.PersonalDetails_RadioOne__1GgFv {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
}
.PersonalDetails_RadioTwo__29rho {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.PersonalDetails_image_container__1AWF- {
  display: none;
}
.PersonalDetails_ActionBtn__1MdQK {
  display: flex;
  justify-content: center;
}
.PersonalDetails_back_button__1hIB2 {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.PersonalDetails_socialHeading__1Q0HU {
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px;
}
.PersonalDetails_webView_image__1SOM0 {
  display: flex;
  width: 13vw;
  margin: auto;
}
.PersonalDetails_image_container__1AWF- {
  display: block;
  width: 40%;
}
.PersonalDetails_form_upload_label__1CCL4 {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid #ece9e9;
  align-items: center;
}
.PersonalDetails_uploadLogoText__oIMBJ {
  margin-left: 30px;
}
.PersonalDetails_updateImage__3gKIX {
  display: flex;
  
  border-radius: 60px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.PersonalDetails_imageEditIcon__2xyhc {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
  background: white;
  margin-left: -39px;
  border: 1px solid #848484;
  align-items: center;
}
.PersonalDetails_uploadContainer__3yXtT {
  display: flex;
  align-items: center;
}
.PersonalDetails_profilePhoto__yolOW {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.PersonalDetails_alternateFieldContainer__1AYCr {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.PersonalDetails_aternateFieldText__KIslp {
  padding-left: 10px;
}
.PersonalDetails_updatetImageContainer__1wlMa {
  display: flex;
  align-items: end;
}
.PersonalDetails_uploadIcon__37GGj {
  cursor: pointer;
  color: gray;
  height: 22px;
  width: 22px;
  margin: auto;
}
.PersonalDetails_error_message__1t5aT {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.PersonalDetails_subHeading__1G0jw {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.PersonalDetails_heading__3N-a8 {
  font-size: 19px;
  font-weight: 700;
  padding-top: 30px;
}
.PersonalDetails_Maincontainer_border__1n19C {
  margin: auto;
  background-color: #fff;
}
.PersonalDetails_disabledInput__2xP8f {
  pointer-events: none;
  opacity: 0.4;
}
.PersonalDetails_borderBottomHeading__1GWlp {
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.PersonalDetails_manadatoryFields__2IW-E{
  color: red;
}
@media (min-width: 1023px) {
  .PersonalDetails_mainContainer__1RP7M {
    width: auto;
  }
  .PersonalDetails_web_view__2K88f {
    display: flex;
    width: 80%;
    align-items: center;
  }
  .PersonalDetails_profilePhoto__yolOW {
    padding-top: 30px;
  }
  .PersonalDetails_Maincontainer_border__1n19C {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .PersonalDetails_twoColoumnContainer__18IsL {
    display: flex;
    padding: 5px 0px;
    flex-direction: row;
  }
  .PersonalDetails_genderDobContainer__1RWVt {
    display: flex;
    padding: 5px 0px;
    /* align-items: center; */
    flex-direction: row;
  }
  .PersonalDetails_twoColoumnField__1pbjI {
    width: 350px;
    padding-right: 25px;
  }
  .PersonalDetails_mainHeading__BGA_4 {
    font-size: 25px;
    font-weight: bold;
    margin: 0px;
  }
  .PersonalDetails_socialContainer__2mUgB {
    width: 89%;
  }
}

.LeftMenu_rightMenuMain__NZOUN {
  display: flex;
  border-width: 1px;
  border-style: solid;
  background-color: rgb(255, 255, 255);

  border-color: #e2e2e2;
  border-top-width: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.LeftMenu_rightMenuMain__NZOUN::-webkit-scrollbar {
  display: none;
}
.LeftMenu_leftMenu__1Wi8_ {
  padding: 10px;

  margin-left: 10px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
}
.LeftMenu_leftMenuDisabled__2RwHr {
  padding: 10px;

  margin-left: 10px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
}

.LeftMenu_leftMenuDisabled__2RwHr:hover {
  cursor: not-allowed;
}

.LeftMenu_leftMenuSelected__3OrMu {
  color: #1678f2;
  cursor: pointer;
  border-radius: 3px;
  border-bottom: 4px;
  border-style: solid;
  border-left: none;
  border-top: none;
  border-right: none;
}
.LeftMenu_leftMenuSelected__3OrMu {
  padding: 10px;

  margin-left: 10px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  color: #1678f2;
  cursor: pointer;
  border-radius: 3px;
  border-bottom: 4px;
  border-style: solid;
  border-left: none;
  border-top: none;
  border-right: none;
}
.LeftMenu_leftMenu__1Wi8_:hover {
  cursor: pointer;
}
.LeftMenu_leftMenuHeading__3nVRd {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;

  display: flex;
  align-items: center;
}
.LeftMenu_menuText__31s8C {
  margin-left: 0px;
  width: inherit;
}

.LeftMenu_menuTextDisabled__yH-kr {
  color: gray;
  margin-left: 0px;
  width: inherit;
}
.LeftMenu_horiTag__32O-5 {
  width: 90%;
  text-align: center;
  border-width: 1px;
  border-color: #f1f1f1;
  border-style: solid;
}
.LeftMenu_linkdesign__21VcO {
  text-decoration: none;
  color: #171717;
}
.LeftMenu_subMenu__2tsYL {
  display: none;
}
.LeftMenu_downIcon__TSS2F {
  display: none;
}
@media (min-width: 768px) {
  .LeftMenu_rightMenuMain__NZOUN {
    width: 200px;
    display: inherit;
    overflow: inherit;
    margin: 10px;
    padding-top: 20px;
  }
  .LeftMenu_downIcon__TSS2F {
    display: unset;
  }
  .LeftMenu_subMenu__2tsYL {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
  }
  .LeftMenu_leftMenu__1Wi8_ {
    display: flex;
    align-items: center;
    text-align: inherit;
    width: inherit;
  }
  .LeftMenu_leftMenuSelected__3OrMu {
    color: #1678f2;
    cursor: pointer;
    border-radius: 3px;
    border-right: 4px;
    border-style: solid;
    border-left: none;
    border-top: none;
    border-bottom: none;
  }
  .LeftMenu_leftMenu1__2qpRa {
    display: flex;
    padding-left: 18px;
    text-align: inherit;
    width: inherit;
  }
  .LeftMenu_leftMenuDisabled__2RwHr {
    display: flex;
    align-items: center;
    text-align: inherit;
    width: inherit;
  }
  .LeftMenu_menuText__31s8C {
    margin-left: 10px;
  }
  .LeftMenu_menuTextDisabled__yH-kr {
    margin-left: 10px;
  }
  .LeftMenu_profileMenu__3R3D1 {
    padding-left: 8px;
  }

  .LeftMenu_leftMenu__1Wi8_:hover {
    cursor: pointer;
  }
}

.NewFreelancerDashBoardMobile_pageLayout__zMFbH {
  background-color: #fcd01708;
  padding-top: 10px;
  padding: 10px;
}
.NewFreelancerDashBoardMobile_widgetsContainer__2fnKf {
  width: 100%;
}

.Tab_mainContainer__mHsrj{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    overflow: scroll;
}
.Tab_mainContainer__mHsrj::-webkit-scrollbar {
    display: none;
  }
.Tab_tab__1Xy98{
    padding: 15px 8px;
    white-space: nowrap;
    font-weight: 600;
    margin: 0px 5px;
    color: #000;
    text-decoration: none;
    font-size: 17px;
    background-color: #fff;
    align-self: center;
}
.Tab_tabSelected__3rGrL{
    padding: 15px 8px;
    white-space: nowrap;
    color: #000;
    font-weight: 800;
    font-size: 17px;
    margin: 0px 10px;
    text-decoration: none;
    border-bottom: 3px solid #1778f2;
    align-self: center;
}
.NewFreelancerDashBoard_pageLayout__2XP6E {
  background-color: #fcd01708;
  display: flex;
}
.NewFreelancerDashBoard_widgetsContainer__nMuNw {
  width: 100%;
  padding-bottom: 5%;
}

.TopAlertNotification_mainContainer__FcGVA {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 5%;
  font-weight: bold;
}

.TopAlertNotification_heading__1GT5u {
  font-weight: 800;
  font-size: 20px;
}
.TopAlertNotification_iconDesign__1dk-j {
  font-size: 50px;
  margin-right: 20px;
  margin-left: 10px;
}
.TopAlertNotification_content__3GSmx {
  color: grey;
}
.TopAlertNotification_iconAndContent__CC3xB {
  display: flex;
  align-items: center;
}
.TopAlertNotification_progressbar__cGLia {
  margin-left: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #e9eaff;
}
.TopAlertNotification_mandatoryFields__3N320 {
  display: flex;
}
.TopAlertNotification_mandatoryFieldsText__11XZ1 {
  color: grey;
  margin-left: 5px;
}

.TopAlertNotification_iconAndMandatoryText__3wbyv {
  display: flex;
  color: grey;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
}

.TopAlertNotification_marginForHundred__3xgqQ {
  margin-top: 3%;
}
@media screen and (min-width: 1023px) {
  .TopAlertNotification_mainContainer__FcGVA {
    margin-top: 5%;
  }
}

.ProfileFormErrors_mainContainer__WzgM2{
    margin:10px 5px;
    border:1px solid red;
    background-color: antiquewhite;
    border-radius: 10px;
    
}
.ProfileFormErrors_iconAlignment__1Hcdf{
    display: flex;
    align-items: center;
    padding: 5px;
}
.ProfileFormErrors_errorMessage__3Q_WW{
 padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
}
.AboutUs_top_link__1ReX4 {
  margin: 30px 20px;
  color: #707070;
}
.AboutUs_home_link__hJqJs {
  text-decoration: none;
}
.AboutUs_home_link__hJqJs:first-child {
  color: #1778f2;
}
.AboutUs_para_text_container__2KO8W {
  margin: 0px 20px;
}
.AboutUs_para_text__3JxH2 {
  margin: 10px 0px 30px 0px;
}
.AboutUs_freelancer_para__3YAUV {
  padding: 10px 0px 20px 0px;
  background-color: #999999;
  margin-right: 20px;
  border-radius: 0px 70px 70px 0px;
}
.AboutUs_freelancer_Heading__3fbvB {
  color: #ffffff;
  margin: 20px;
  margin-top: 20px;
}
.AboutUs_freelancer_para_text__2I5S2 {
  margin: 10px 40px 0px 20px;
  color: #ffffff;
}
.AboutUs_clients_para__2HZeb {
  padding: 10px 0px 20px 0px;
  background-color: #64adf7;
  margin: 20px 0px 0px 20px;
  border-radius: 70px 0px 0px 70px;
}
.AboutUs_clients_Heading__1tULN {
  color: #ffffff;
  margin: 20px;
  text-align: right;
}
.AboutUs_clients_para_text__2YDxe {
  margin: 10px 20px 0px 40px;
  text-align: right;
  color: #ffffff;
}
.AboutUs_core_values_container__1XDuc {
  background-color: #f5f5f5;
  padding: 10px 0px;
  margin: 20px 0px;
}
.AboutUs_para_text_mob__1gZ19 {
  margin: 0px 20px;
}
.AboutUs_para_text_mob_right__3w_7D {
  margin: 0px 20px;
}
.AboutUs_core_values_heading__1aTtv {
  text-align: center;
}
.AboutUs_core_values_para__mChQw {
  text-align: center;
}
.AboutUs_core_image__2mbZO {
  
  display: none;
}
.AboutUs_core_imageMob__1L2HK{
  display: block;
  margin-top: 20px;
}
.AboutUs_core_values_text__gD0Gr {
  margin: 20px 35px;
}
.AboutUs_image_bg__biYDK {
  background-color: #1778f2;
  padding: 12px 16px;
  width: 50px;
  text-align: center;
  position: relative;
}
.AboutUs_bottom_text_div__2fih2 {
  margin: -47px 20px 10px 50px;
  background-color: #ffffff;
  border-radius: 0px 28px 28px 0px;
  box-shadow: 10px 5px 12px 12px #00000014;
  padding: 16px 0px 16px 40px;
}
.AboutUs_bottom_text_divLeft__3jp7e {
  margin: -47px 50px 10px 20px;
  background-color: #ffffff;
  border-radius: 28px 0px 0px 28px;
  box-shadow: 10px 5px 12px 12px #00000014;
  padding: 16px 0px 16px 0px;
}
.AboutUs_image_left__GKaPj {
  display: flex;
  justify-content: flex-end;
}
.AboutUs_bottom_text_left__2fK2N {
  text-align: right;
  margin-right: 40px;
}
.AboutUs_image_bg_left__UinsG {
  background-color: #f2c810;
  padding: 12px 16px;
  width: 50px;
  text-align: center;
  position: relative;
}
.AboutUs_key_success_image__hdE8b {
  height: 32px;
}
@media screen and (min-width: 1023px) {
  .AboutUs_top_link__1ReX4 {
    margin: 30px 13vw;
    color: #707070;
  }
  .AboutUs_para_text_container__2KO8W {
    margin: 0px 13vw;
  }
  .AboutUs_freelancer_para__3YAUV {
    padding: 5vw 13vw;
    margin-right: 11vw;
    border-radius: 0px 200px 200px 0px;
  }
  .AboutUs_freelancer_Heading__3fbvB {
    margin: 0px;
  }
  .AboutUs_freelancer_para_text__2I5S2 {
    margin: 20px 40px 0px 0px;
  }
  .AboutUs_clients_para__2HZeb {
    padding: 5vw 0px;
    margin: 50px 0px 0px 11vw;
    border-radius: 240px 0px 0px 240px;
  }
  .AboutUs_clients_Heading__1tULN {
    margin: 0px 13vw;
    text-align: right;
  }
  .AboutUs_clients_para_text__2YDxe {
    margin: 30px 13vw;
    text-align: right;
  }
  .AboutUs_core_values_heading__1aTtv {
    margin: 50px 0px;
  }
  .AboutUs_core_image__2mbZO {
   display: block;
  }
  .AboutUs_core_imageMob__1L2HK{
    display: none;
  }
  .AboutUs_core_values_text__gD0Gr {
    margin: 40px 11vw;
  }
  .AboutUs_key_success_web__3IglY {
    display: flex;
    justify-content: space-around;
  }
  .AboutUs_para_text_mob__1gZ19 {
    margin: 0px 0px 0px 50px;
  }
  .AboutUs_para_text_mob_right__3w_7D {
    margin: 0px 50px 0px 0px;
  }
  .AboutUs_bottom_text_divLeft__3jp7e { 
    width: 35vw;
    margin-bottom: 30px;
    padding: 28px 0px 28px 0px;
  }
  .AboutUs_bottom_text_div__2fih2 {
    width: 35vw;
    margin-bottom: 30px;
    padding: 28px 0px 28px 70px;
  }
  .AboutUs_image_bg__biYDK {
    padding: 18px 26px;
  }
  .AboutUs_image_bg_left__UinsG {
    padding: 18px 26px;
  }
  .AboutUs_bottom_text_left__2fK2N {
    text-align: right;
    margin-right: 70px;
  }
}


.ContactUs_ContactUs_mainheader__1MoRt {
  background-color: white;
  margin: 10px 10px 10px 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.ContactUs_para_text__dfu0h {
  margin: 30px 20px;
}
.ContactUs_contactForm_container__q_2cF {
  margin-top: 20px;
}
.ContactUs_ErrorMessage__sdRe0 {
  color: #ff2222;
  font-size: 14px;
  margin: 0px;
}
.ContactUs_ActionButton__1bM9f {
  display: flex;
  justify-content: center;
}

.ContactUs_faq_padding__XaA04 {
  padding: 30px 0;
}
.ContactUs_faq_firstheading__3xyZU {
  font-size: 30px;
  font-family: "Poppins";
}
.ContactUs_faq_secondheading__2lFti {
  color: #5d5d5d;
  font-size: 16px;
  padding: 5px 0px 25px 0px;
  font-family: "Manrope";
}
.ContactUs_LoadingBtn__2QYKB {
  background-color: #fcd017;
  color: #000;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 6px 10px;
  width: 10%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  font-weight: 800;
}
@media screen and (min-width: 768px) {
  .ContactUs_para_text__dfu0h {
    margin: 40px 14vw 50px 14vw;
  }
  .ContactUs_ContactUs_mainheader__1MoRt {
    margin: 10px 10px 10px 0px;
  }
}

.Faqdropdown_faq_question__1C5OH {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 15px;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}
.Faqdropdown_faq_questiondiv__2LCd7 {
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
}

.Faqdropdown_faq_answer__2DduK {
  border-bottom: 1px solid #dfdfdf;
  margin-top: 20px;
  padding-bottom: 20px;
  color: #5d5d5d;
  font-family: "Manrope";
  font-size: 15px;
}

.Faqdropdown_faq_border__2XtsX {
  margin: 15px 0px;
}

.Faqdropdown_faq_icon__kr9vq {
  width: auto;
}

@media screen and (min-width: 1024px) {
  .Faqdropdown_faq_icon__kr9vq {
    width: auto;
  }
  .Faqdropdown_faq_question__1C5OH {
    font-size: 18px;
  }

  .Faqdropdown_faq_answer__2DduK {
    font-size: 16px;
  }
}

.RIModal_modalContainer__2koi3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.RIModal_modal__2zEFB {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 350px;
  padding: 23px 9px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.RIModal_button__2p2E2 {
  width: 70px;
  color: #f02f39;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.RIModal_ActionBtn__1nvJj {
  display: flex;
  justify-content: space-around;
}

@-webkit-keyframes RIModal_slide-down__2BTRp {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes RIModal_slide-down__2BTRp {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.RIModal_ModalCheckIcon__28p8e {
  text-align: center;
}
.RIModal_ModalCheckTextFirst__4IRYx {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
  font-weight: bold;
}
.RIModal_ModalCheckTextSecond__3TX1K {
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  font-weight: bold;
}
.RIModal_modalcheck__2RttQ {
  height: 40px;
  width: 40px;
  margin-top: -90px;
  border: 1px solid;
  padding: 20px;
  border-radius: 50%;
  background-color: #0c2551;
  color: #fff;
  border-color: #0c2551;
}
.RIModal_ModalCheckIconText__1u8RZ {
  text-align: center;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .RIModal_modal__2zEFB {
    margin: 0px 20px;
    height: 200px;
  }
}

.RITheme_buttonBox__1X3mQ {
  background-color: #fcd017;
  color: black;
  height: auto;
  min-width: 60%;
  border-radius: 6px;
  display: flex;
  margin: auto;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.RITheme_buttonBoxFullWidth__3ujAi {
  background-color: #fcd017;
  color: black;
  height: auto;
  min-width: 100%;
  border-radius: 6px;
  display: flex;
  margin: auto;
  padding: 4px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  cursor: pointer;
}
.RITheme_flagContainerFocus__1G7hh {
  display: flex;
  border: 1px solid #1778f2;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}
.RITheme_flagContainer__BQWh_ {
  display: flex;
  border: 1px solid #ece9e9;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}
.RITheme_uldropdown__cNz2M {
  list-style: none;
  margin-left: -30px;
}
.RITheme_flagImage__2s9gC {
  height: 18px;
  width: 30px;
  margin-right: 10px;
  margin-top: 5px;
}
.RITheme_dialCode__3awWV {
  outline: none;
  border: none;
  font-size: 18px;
  margin: 5px 0px 8px 12px;
}
.RITheme_contriesList__3R-oJ {
  height: 200px;
  overflow: scroll;
  margin-top: -10px;
  border: 1px solid #ece9e9;
  border-top: none;
  background-color: #fff !important;
  transition: all 0.3s ease;
}

.RITheme_mobileWrapper__2LCYJ {
  display: flex;
  align-items: center;
}
.RITheme_dropdownLi__1cPFH {
  background-color: white;
  list-style: none;
  text-align: left;
  margin: 4px;
  cursor: pointer;
}
.RITheme_dropdownLi__1cPFH:hover {
  color: #858585;
}
.RITheme_uploadBoxIcon__2eROW {
  padding-right: 15px;
}
.RITheme_uploadBoxIconContainer__2ZzOb {
  align-items: center;
  display: flex;
}
.RITheme_dualButton__2wcec {
  display: flex;
  justify-content: center;
}
.RITheme_buttonBoxdual__v7jzE {
  background-color: #fcd017;

  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 40%;
}
.RITheme_buttonBoxdualSecond__3cAVf {
  background-color: #dad1d1;
  color: rgb(36, 29, 29);
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 40%;
  margin-right: 10px;
}
.RITheme_buttonBoxWhite___Ug42 {
  background-color: #ffffff;
  color: #0f587d;
  height: auto;
  border-radius: 6px;

  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  border: none;
}
.RITheme_editBtnContainer__1juGf {
  background-color: #fcd017;
  border-radius: 6px;

  padding: 3px 12px;
  border: none;
  cursor: pointer;
}

.RITheme_buttonBoxLeft__2AfnP {
  background-color: #fcd017;

  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 40%;
}

.RITheme_buttonBoxLeft__2AfnP:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.RITheme_buttonBoxsmall__37bRN {
  background-color: #fcd017;

  border-radius: 6px;

  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 100px;
}

.RITheme_buttonBoxsmall__37bRN:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.RITheme_buttonBoxPlan__xWDrd {
  border-radius: 6px;
  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid grey;
  min-width: 100px;
  cursor: pointer;
}
.RITheme_buttonBoxPlan__xWDrd:hover {
  background-color: #fcd017;
  border-radius: 6px;
  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 100px;
  cursor: pointer;
}
.RITheme_buttonBoxHeader__1wjOU {
  background-color: #fcd017;
  height: 38px;
  margin: auto;
  border-radius: 6px;
  display: flex;
  padding: 0px 30px;
  width: 40%;
  margin-top: 25px;
  border: none;
}

.RITheme_buttonBoxmedium__1zEe3 {
  background-color: #000;
  color: #fff;
  height: auto;
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  border: none;
}
.RITheme_buttonBoxmedium__1zEe3:hover {
  cursor: pointer;
  background-color: #1778f2;
}

.RITheme_buttonBoxSignup__24NSs {
  background-color: #000;
  color: #fff;
  height: auto;
  display: flex;
  padding: 5px 3px 5px 3px;
  border: none;
  margin-left: 30px;
}
.RITheme_buttonBoxSignup__24NSs:hover {
  cursor: pointer;
  background-color: #282f39;
}
.RITheme_buttonText__37EHb {
  align-self: center;
  margin: auto;
  padding: 0px 10px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}

.RITheme_buttonTextSignUp__FFbPO {
  align-self: center;
  margin: auto;
  padding: 0px 10px;
}

.RITheme_flagContainer__BQWh_ {
  display: flex;
  border: 1px solid #ece9e9;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}

.RITheme_buttonBox__1X3mQ:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.RITheme_buttonBoxWhite___Ug42:hover {
  background-color: #1778f2;
  color: white;
  cursor: pointer;
}

.RITheme_formContainer__acheK {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.RITheme_inputBoxContainer__2COM1 {
  padding: 20px;
}

/* iconinput--------------------------- */

.RITheme_newRadioButton__3Nyc9 {
  margin-right: 12px;
  margin-top: 20px;
}

.RITheme_iconInputDiv__14yrg {
  border: 1px solid #ece9e9;
  display: flex;
  padding: 4px;
  align-items: center;
}
.RITheme_focuedIconInputDiv__26Js6 {
  border: 1px solid #27b1be;
  display: flex;
  padding: 4px;
  
}

.RITheme_inputAreaIcon__2GtyP {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.RITheme_icon__JG042 {
  margin-left: 10px;
  margin-right: 5px;
}
.RITheme_inputAreaSmall__3toTD {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  max-width: 400px;
  margin: 10px 0px;
  font-family: inherit;
  font-size: 16px;
}
.RITheme_inputAreaSmall__3toTD::-webkit-input-placeholder {
  font-size: 16px;
  font-family: inherit;
}
.RITheme_inputAreaSmall__3toTD:-ms-input-placeholder {
  font-size: 16px;
  font-family: inherit;
}
.RITheme_inputAreaSmall__3toTD::placeholder {
  font-size: 16px;
  font-family: inherit;
}

.RITheme_inputAreaIcon__2GtyP::-webkit-input-placeholder {
  font-size: 16px;
 padding-top: 4px;
}

.RITheme_inputAreaIcon__2GtyP:-ms-input-placeholder {
  font-size: 16px;
 padding-top: 4px;
}

.RITheme_inputAreaIcon__2GtyP::placeholder {
  font-size: 16px;
 padding-top: 4px;
}

.RITheme_divider__3qnH1 {
  margin-left: 20px;
}

/* iconinput------------------------------------ */

.RITheme_inputArea_name__1bbwR {
  padding: 10px 10px;
  border: 1px solid #ece9e9;
  outline: none;
  margin: 10px 0px;
  font-size: 18px;
}
.RITheme_inputArea_name__1bbwR:focus {
  border-color: #27b1be;
}

.RITheme_inputArea__zpLuX {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  word-break: break-word;
  margin: 10px 0px;
  font-size: 18px;
}
.RITheme_inputArea__zpLuX:focus {
  border-color: #27b1be;
}
.RITheme_inputArea_dropdown__3eQgY {
  border: 1px solid #ece9e9;
  height: 45px;
  font-size: 18px;
  outline: none;
  padding: 12px;
  cursor: pointer;
  background-color: white;
  margin: 15px 0px;
  color: #000;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>');
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}

.RITheme_inputArea_dropdown__3eQgY:focus {
  border-color: #27b1be;
}
.RITheme_uploadIcon__byE5h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.RITheme_inputArea_upload__1HtDP {
  padding: 10px 10px;
  outline: none;
  display: flex;
  text-align: center;
  height: 200px;
  width: 50%;
  border: 1px solid #ece9e9;
  margin: 10px 0px 30px;
  cursor: pointer;
  font-size: 18px;
}
.RITheme_inputArea_withIcon__232N8 {
  padding: 12px;
  width: 90%;
  border: none;
  font-size: 18px;
  outline: none;
}
.RITheme_inputArea_withIcon__232N8:focus {
  border-color: #27b1be;
}
.RITheme_option_dropdown__SY4CO {
  height: 45px;
  cursor: pointer;
}
.RITheme_input_fields__hQkX8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  border: 1px solid #ece9e9;
}
.RITheme_input_fields__hQkX8:focus {
  border-color: #27b1be;
}
.RITheme_eyeIcon__32_FS {
  margin-right: 10px;
  height: 23px;
  width: 23px;
  cursor: pointer;
}
.RITheme_tickIcon__IxDu5 {
  color: #2ec72e;
  margin-right: 10px;
  height: 24px;
  width: 24px;
}
.RITheme_CheckboxStyle__1boc7 {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ece9e9;
  margin: 7px 5px;
  cursor: pointer;
}
.RITheme_CheckBoxInput__3ugtV {
  display: flex;
}
.RITheme_form_upload_label__KE9Pn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.RITheme_inputArea_Custom_dropdown__1zGj_ {
  border: none;
  padding: 6px;
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  outline: none;
  padding-top: 9px;
  /* background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>'); */
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}
::-webkit-input-placeholder {
  font-size: 18px;
  color: #858585;
}
:-ms-input-placeholder {
  font-size: 18px;
  color: #858585;
}
::placeholder {
  font-size: 18px;
  color: #858585;
}
.RITheme_inputAreaDateContainer__ojjY3 {
  width: 100%;
  height: 45px;
  margin: 15px 0px;
}
.RITheme_inputAreadate__32Ahr {
  background-color: #fff;
  font-size: 18px;
  width: 97%;
  border: 1px solid #ece9e9;
  outline: none;
  padding: 10px 5px;
}
.RITheme_inputAreadate__32Ahr:focus {
  border: 1px solid #27b1be;
}
.RITheme_CheckBox_Text__139gR {
  padding-left: 28px;
  color: #808080;
  font-size: 14x;
}
.RITheme_CheckBoxInput_Text__1s1Sf {
  padding-left: 8px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.RITheme_checkBox_input__8uuZl {
  height: 19px;
  cursor: pointer;
}

.RITheme_RiTextTransition__3GiC9 {
  color: #000;
  font-size: 25px;
}

.RITheme_mainHeadingContainer__2ly8J {
  font-size: 40px;
  padding: 10px;
  font-family: "Manrope", sans-serif;
}

.RITheme_newHeadingFirst__305dv {
  margin-bottom: 7px;
}

.RITheme_thirdLine__ObMbx {
  color: #858585;
  font-size: 20px;
}
.RITheme_thirdLineWhite__25RAd {
  color: #fff;
  font-size: 30px;
}

.RITheme_headingTwo__1Cglw {
  margin-top: -10px;
  line-height: 1.2;
}
.RITheme_input_fields_mobile__367ZL {
  border: 1px solid #27b1be;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.RITheme_IconContainer__vuD61 {
  display: flex;
  align-items: center;
}
.RITheme_inputArea_nameSocial__2Ve54 {
  padding: 10px 10px;
  border: 1px solid #ece9e9;
  outline: none;
  margin: 10px 0px;
  width: 100%;
  font-size: 18px;
  margin-left: 12px;
}
.RITheme_cityloader__3VMSK {
  margin-top: 4rem;
}

@media screen and (min-width: 1023px) {
  .RITheme_mainHeadingContainer__2ly8J {
    font-size: 60px;
    padding: 0;
    font-family: "Manrope", sans-serif;
  }
  .RITheme_RiTextTransition__3GiC9 {
    color: #000;
    font-size: 40px;
  }
  .RITheme_newHeadingFirst__305dv {
    margin-bottom: 0;
  }

  .RITheme_buttonBoxLeft__2AfnP {
    min-width: 300px;
  }

  .RITheme_buttonBoxLeft__2AfnP {
    min-width: 300px;
  }
}

.RIHeader_headerMainContainer__2kLvG {
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  display: flex;
}

.RIHeader_menuContainerTop__uksxI {
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin-right: 30px;
  margin-left: 25px;
  align-items: center;
}
.RIHeader_menuContainerTopMobile__3zz4S {
  display: flex;
  margin-left: 25px;
  align-items: center;
}

.RIHeader_headerWeb__15nJZ {
  background-color: #ffffff;
  display: flex;
  padding: 15px;

  box-shadow: 0 4px 2px -2px rgb(236, 236, 236);
  align-items: center;
  max-width: 1600px;
  margin: auto;
}
.RIHeader_headerMobile__2FvnF {
  padding: 15px;
}
.RIHeader_topMenuHeads__24gzu {
  display: flex;
}

.RIHeader_menubuttons__3KYgP {
  margin-left: 20px;
}

.RIHeader_menubuttons__3KYgP:hover {
  color: #1778f2;
  cursor: pointer;
}
.RIHeader_topMenuHeadsRight__37d2b {
  display: flex;
  align-content: center;
  align-items: center;
}
.RIHeader_menuDropdown__3_qeo {
  display: block;
  position: absolute;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease-out;
  padding: 20px;
  background-color: white;
  float: right;
  right: 43px;
  top: 72px;
  border-radius: 10px;
  visibility: inherit;
  visibility: visible;
  z-index: 1;
}
.RIHeader_menuDropdownHide__N-Etx {
  display: none;
}

.RIHeader_linkTag__1t7Pa {
  text-decoration: none;
}
.RIHeader_iconmm__1v9EX {
  margin: auto;
}
.RIHeader_userMenuOptions__10jls {
  padding-left: 10px;
}
.RIHeader_iconAndText__3YYYG {
  display: flex;
  align-content: center;
  padding: 10px;
  align-items: center;
}
.RIHeader_userMenuOptions__10jls:hover {
  color: #1778f2;

  cursor: pointer;
}

.RIHeader_headerMainContainer_Mobile__3JzJi {
  position: relative;
  min-height: 70px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.RIHeader_PARBtn_Link__cEz07 {
  text-decoration: none;
}
.RIHeader_ActionButton__2vWgc {
  display: flex;
  margin-right: 20px;
}
.RIHeader_back_button__13rzx {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.RIHeader_riLogo__3Oxcc {
  margin-left: 30px;
}
.RIHeader_riLogo_Mobile__3tgYZ {
  margin-left: 10px;
}

.RIHeader_riLogo_MobileBack__3b9U4 {
  margin: auto;
  padding: 8px;
}
.RIHeader_heading1__10941 {
  font-size: 16px;
  padding: 5px;
  padding-right: 15px;
}
.RIHeader_heading1__10941:hover {
  cursor: pointer;
  color: #1778f2;
}
.RIHeader_hamburger__2NNYz {
  padding-left: 15px;
  color: #69737c;
}

.RIHeader_backIcon__3hPp2 {
  color: #0c2551;
  padding: 5px;
  padding-left: 2%;
}
.RIHeader_join__3YWZk {
  padding: 5px;
  padding-left: 2%;
}
.RIHeader_menuOption__3rRM3 {
  display: flex;
  align-self: center;

  align-items: center;
  justify-content: space-between;
  width: auto;

  margin-right: 30px;
}
.RIHeader_menuTitle__wyC8o {
  font-weight: bold;

  margin-right: 30px;
  color: rgb(36, 36, 36);
}

.RIHeader_menuTitle__wyC8o:hover {
  color: rgb(209, 209, 209);
  cursor: pointer;
}

.RIHeader_logoAndMenu__ZWBWN {
  display: flex;
  padding-left: 2%;
}
.RIHeader_menuWrapper__2e8Mv {
  background-color: #ffffff;
  height: 100%;
}

.RIHeader_close_button__1vXbU {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
}
.RIHeader_crossIcon__2KTLQ {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.RIHeader_JoinBtn_Link__2ZkUl {
  text-decoration: none;
  color: #fff;
}
.RIHeader_drawer_menu_header__rW5UC {
  background-color: #1778f2;
  padding: 15px 15px 33px 15px;
}
.RIHeader_menuProfile_image__h-2j_ {
  width: 85px;
  height: 85px;
  background-color: #a38585;
  border-radius: 50%;
}
.RIHeader_menu_profile__RgBD3 {
  display: flex;
  align-items: center;
  width: 93%;
  justify-content: space-evenly;
  color: #ffffff;
}
.RIHeader_menuProfile_name__Xwyps {
  margin: 0px;
}
.RIHeader_menuProfile_button__15njN {
  padding: 4px 14px;
  margin-top: 13px;
  border-radius: 10px;
  border: none;
}
.RIHeader_menuNav_contaner__1MWHg {
  background-color: #ffffff;
}
.RIHeader_menuNav__1zytO {
  padding: 24px 30px;
}
.RIHeader_link_container__1cKgl {
  display: flex;
  align-items: center;
  height: 50px;
}
.RIHeader_link__1zsOB {
  text-decoration: none;
  color: #000;
  margin-left: 15px;
}
.RIHeader_border__jTOX_ {
  border-bottom: 1px solid #bdb8b8;
  margin: 30px 0px 30px 15px;
}
.RIHeader_link_bottom__3eO8H {
  color: #0c2551;
  text-decoration: none;
  margin-left: 15px;
}
.RIHeader_menu_icon__3419Y {
  color: #0c2551;
  height: 22px;
  width: 22px;
}

.RIHeader_menu_nav__2V_Sv {
  padding: 0px 30px 24px 23px;
}
.RIHeader_linkDesignNone__Olvii {
  text-decoration: none;
  color: #191919;
}
@media screen and (max-width: 768px) {
  .RIHeader_menuDropdown__3_qeo {
    right: 12px;
    top: 65px;
  }
}

.ProfileIcon_iconContainer__3eTNt{
    border-radius: 50px; 
  
    color: black;
    background-color: #FCD017;
   }
   .ProfileIcon_iconContainer__3eTNt:hover{
     background-color: #f5f5f5;
     color: rgb(0, 0, 0);
   }
   .ProfileIcon_iconContainerLeftMenu__1qGy_{
     border-radius: 50px; 
     background-color: white;
    color:  #1778f2;
   }
   .ProfileIcon_innerInitial__xHqNx{
     padding: 10px;
     font-size: 18px;
     font-weight: bold;
   }
   .ProfileIcon_innerInitialLeftMenu__29CVJ{
     padding: 28px;
     font-size: 18px;
     font-weight: bold;
   }
   
   .ProfileIcon_smalliconContainer__3RwDE{
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   border-radius: 50px;
   background-color: #f5f5f5;
   margin-left: 4px;
   margin-right: 4px;
   }
   .ProfileIcon_smallinnerInitial__1QsdU{
     padding: 6px;
     font-size: 12px;
     font-weight: bold; 
     margin: auto;
    
   }
   .ProfileIcon_smalliconContainer__3RwDE:hover{
     background-color:  #1778f2;
     color: white;
     cursor: pointer;
   }
   
   .ProfileIcon_profilePicture__1h_1V{
     object-fit: cover;
    
     border-radius: 50px;
   }
   .ProfileIcon_profileImage__24pYE{
   
     width: 40px;
    height: 40px;
   }
   .ProfileIcon_profileImageLeftMenu__2TeSV{
     width: 60px;
     height: 60px;
   }
   .ProfileIcon_logooverlaped__3iLwp {
     display: flex;
     justify-content: end;
     margin: 1rem;
   
   }
   .ProfileIcon_freelancerProfileContainer__26t3h{
     height: 40px;
   margin-left: -20px;
     width: 40px;
   }
   .ProfileIcon_logo_images__1FvLZ {
     width: 100%;
     height: 100%;
     border: 1px solid #fff;
    cursor: pointer;
     object-fit: cover;
     border-radius: 50%;
   }
  
.SuccessModal_modalContainer__eTLWf {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}
.SuccessModal_backdrop__1DKqc {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  -webkit-animation-name: SuccessModal_example__jVRBC;

          animation-name: SuccessModal_example__jVRBC;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}
.SuccessModal_modal__QiESi {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 400px;
  padding: 60px 40px 10px 40px;
  border-radius: 10px;
  max-height: 100vh;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.SuccessModal_modalLogout__2KQKh {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 350px;
  padding: 30px 40px 40px 40px;
  border-radius: 10px;
  max-height: 100vh;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.SuccessModal_icon__392Hd {
  display: flex;
  justify-content: center;
  margin-top: -95px;
}
.SuccessModal_checkIconMain__cXaYu {
  background-color: green;
  border-radius: 50%;
  padding: 10px;
}
.SuccessModal_heading__39trN {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  padding-top: 30px;
}
.SuccessModal_subHeading__19XzO {
  text-align: center;
  padding-top: 15px;
  font-size: 18px;
  font-weight: 600;
}
.SuccessModal_dashboardBtn__2smIp {
  color: green;
  margin: 20px 10px 10px 0px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.SuccessModal_dashboardBtnYes__2V6PM{
  color: green;
  margin: 20px 10px 10px 0px;
  border: 1px solid green;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.SuccessModal_dashboardBtnYes__2V6PM:hover{
  color: rgb(70, 223, 70);
  border-color: rgb(70, 223, 70);
}
.SuccessModal_dashboardBtn__2smIp:hover {
  color: rgb(70, 223, 70);
}
.SuccessModal_dashboardIcon__HMe9p {
  margin-top: 2px;
}
.SuccessModal_joinBtn__qREbN {
  color: #1778f2;
  margin: 20px 10px 10px 0px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.SuccessModal_joinBtn__qREbN:hover {
  color: #3f75b8;
}
.SuccessModal_joinBtnNo__3MdoO {
  color: #1778f2;
  margin: 20px 10px 10px 0px;
  border: 1px solid #1778f2;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  margin: auto 5px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.SuccessModal_joinBtnNo__3MdoO:hover {
  color: #3f75b8;
  border-color: #3f75b8;
}
.SuccessModal_mandatoryFields__36EJL {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
}
.SuccessModal_actionBtnContainer__-7NNX {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: auto;
  padding: 8px 0px 10px 8px;
}
.SuccessModal_actionBtnContainerLogout__2l3dY{
  display: flex;
  justify-content: space-between;
  width: 140px;
  margin: auto;
  padding: 8px 0px 10px 8px;
}
.SuccessModal_okBtnContainer__1v5gv {
  display: flex;
  justify-content: center;
}
.SuccessModal_okBtn__d8mhC{
  text-align: center;
  border: none;
  border-radius: 6px;
  width: 50px;
  padding: 6px 0px;
  margin: 20px auto 0px;
  background-color: #fcd017;
  color: #000;
  font-weight: 700;
  cursor: pointer;
}
.SuccessModal_okBtn__d8mhC:hover{
  background-color: #3f75b8;
  color: #fff;
  font-weight: 700;
}
.SuccessModal_cancelBtnContainer__1FO2j{
  font-size: 16px;
  text-align: right;
 
  color: white;
  background-color: rgb(250 0 0);
  opacity: 0.9;
  padding: 5px 10px;
  width: 55px;
  margin: 22px 0px 8px;

  display: flex;
  float: right;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .SuccessModal_modal__QiESi {
    width: 80%;
    padding: 40px 20px 30px 20px;
  }
  .SuccessModal_backdrop__1DKqc {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    -webkit-animation-name: SuccessModal_example__jVRBC;
  
            animation-name: SuccessModal_example__jVRBC;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
  }
  .SuccessModal_icon__392Hd {
    display: flex;
    justify-content: center;
    margin-top: -75px;
  }
  .SuccessModal_joinBtn__qREbN {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-left: 26px;
  }
  .SuccessModal_dashboardBtn__2smIp {
    display: flex;
    justify-content: center;
    padding-right: 26px;
  }
  .SuccessModal_subHeading__19XzO {
    text-align: center;
    padding-top: 15px;
    font-size: 16px;
    font-weight: 600;
  }
  .SuccessModal_actionBtnContainer__-7NNX {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .SuccessModal_cancelBtnContainer__1FO2j{
    font-size: 16px;
    text-align: right;
    color: white;
    background-color: rgb(250 0 0);
    opacity: 0.9;
    padding: 5px 10px;
    width: 55px;
    margin: 22px 0px 8px;
  float: none;
    margin: 10px auto 0px;
    cursor: pointer;
  }
  .SuccessModal_actionBtnContainerLogout__2l3dY{
    display: flex;
    justify-content: space-between;
    width: 140px;
    margin: auto;
    padding: 8px 0px 10px 8px;
  }
  .SuccessModal_modalLogout__2KQKh {
    background-color: white;
    margin: auto;
    position: relative;
    font-weight: 500;
    width: 250px;
    padding: 30px 40px 20px 40px;
    border-radius: 10px;
    max-height: 100vh;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 30;
  }
}

.ReportaBug_top_link__hn4bi {
    margin: 10px 0px 30px 0px;
    color: #707070;
  }
  
  .ReportaBug_home_link__3SH4- {
    text-decoration: none;
    color: #707070;
  }
  
  .ReportaBug_home_link__3SH4-:first-child {
    color: #1778f2;
  }
  
  /*........report.........*/
  .ReportaBug_reportabug_bgcolor__1SsJJ {
    background: #f7f7f8;
    padding: 20px;
  }
  
  .ReportaBug_reportabug_header__3AMzg {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
  }
  .ReportaBug_reportabug_heading__3P1y2 {
    padding-bottom: 10px;
  }
  
  .ReportaBug_reportabug_border__mZuOO {
    width: 70%;
    height: 100px;
    text-align: center;
    opacity: 0;
  }
  
  .ReportaBug_reportaabug_image__xOFTN {
    height: 50px;
    width: 50%;
    padding-top: 25px;
  }
  
  .ReportaBug_input_rozgar__zL7rJ {
    border: 2px dotted #ece9e9;
    width: 70%;
    height: 145px;
    margin-bottom: -100px;
    margin-top: 10px;
    text-align: center;
  }
  .ReportaBug_reportabug_dropdown__23_kh {
    width: 100%;
    padding: 13px 10px;
    border-color: #ece9e9;;
    cursor: pointer;
    margin: 10px 0px;
    height: 45px;
    color: #707070;
    outline: none;
    background-color: #fff;
    text-decoration: none;
  
  }
  .ReportaBug_ErrorMessage__AuBzR {
    color: #ff2222;
    font-size: 12px;
    margin-top: 1px;
  }
  .ReportaBug_reportabug_Button__15jmp {
    background-color: #1778f2;
    color: white;
    height: auto;
    width: 60%;
    border-radius: 6px;
    padding: 10px 10px 10px 10px;
    margin-top: 25px;
    margin-bottom: 25px;
    border: none;
  
  }
  .ReportaBug_LoadingBtn__3rF7b {
    background-color: #1778f2;
    color: white;
    border-radius: 6px;
    display: flex;
    margin: auto;
    justify-content: center;
    padding: 10px 10px;
    width: 55%;
    margin-top: 25px;
    margin-bottom: 25px;
    border: none;
  }
  .ReportaBug_ActionButton__2xIJT {
  display: flex;
  justify-content: center;
  }
  /*..............web............*/
  
  @media screen and (min-width:768px) {
    .ReportaBug_reportabug_bgcolor__1SsJJ {
        background: #f7f7f8;
        padding: 20px 80px;
    }
  
    .ReportaBug_reportabug_header__3AMzg {
        padding: 20px 50px;
        width: 60%;
        margin: auto;
    }
  
    .ReportaBug_input_rozgar__zL7rJ {
        padding-top: 30px;
        width: 40%;
        height: 140px;
    }
  
    .ReportaBug_reportabug_border__mZuOO {
        width: 40%;
        height: 111px
    }
  
    .ReportaBug_reportabug_heading__3P1y2 {
        padding-bottom: 10px;
    }
  
  }
.TermsConditions_top_link__1D6p8 {
  margin: 28px 0px;
  color: #707070;
}
.TermsConditions_home_link__kyVr_ {
  text-decoration: none;
  color: #707070;
}
.TermsConditions_home_linkTerm__3MpvQ{
  color: #707070;
  text-decoration: none;
}
.TermsConditions_home_link__kyVr_:first-child {
  color: #1778f2;
}
.TermsConditions_terms_container__2PjHp {
  margin: 20px;
}
.TermsConditions_terms_para__NpEMU {
  margin: 20px 0px;
  text-align: justify;
}
.TermsConditions_terms_heading__3tgOF {
  text-align: left;
  color: #1778f2;
  text-decoration: none;
}
.TermsConditions_ordered_list__1zhGu {
  margin: 30px 0px;
  text-align: justify;
}
.TermsConditions_ordered_li__2sQ9g {
  margin: 20px 0px;
}
.TermsConditions_ordered_li__2sQ9g::marker {
  margin-left: 10px;
}
.TermsConditions_subHeading_overview__sFz04 {
  margin-top: 5px;
  font-weight: 400;
}
.TermsConditions_overView_heading__17Zsx {
  margin: 0;
  margin-top: 40px;
  font-weight: 600;
}
@media screen and (min-width: 1023px) {
  .TermsConditions_terms_container__2PjHp {
    margin: 40px 13vw;
  }
  .TermsConditions_terms_heading__3tgOF {
    margin-bottom: 35px;
  }
}

.PrivacyPolicy_top_link__1oqge {
  margin: 28px 0px;
  color: #707070;
}
.PrivacyPolicy_home_link__3E8HC {
  text-decoration: none;
}
.PrivacyPolicy_privacy_container__3iRoI {
  margin: 20px;
}
.PrivacyPolicy_privacy_para__1hBsL {
  margin: 20px 0px 30px;
}
.PrivacyPolicy_privacy_heading__3g-Bc {
  color: #1778f2;
}
.PrivacyPolicy_ordered_li__3OIXx {
  margin: 20px 0px;
}
.PrivacyPolicy_boldText__1ZHF4 {
  font-weight: 700;
}
@media screen and (min-width: 1023px) {
  .PrivacyPolicy_privacy_container__3iRoI {
    margin: 40px 13vw;
  }
  .PrivacyPolicy_privacy_heading__3g-Bc {
    margin-bottom: 35px;
  }
}

.LoginWithOTP_mainContainer__22uSM {
  padding: 0px 30px 40px;
  background-color: white;

}
.LoginWithOTP_loginWith_Otp__2Qsx_ {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}
.LoginWithOTP_loginWith_Otp__2Qsx_ {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}
.LoginWithOTP_Maincontainer_border__4QbTR {
  background-color: white;
}
.LoginWithOTP_inputFields_check__3KvMH {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.LoginWithOTP_forgot_password__15RS7 {
  color: #858585;
  text-align: center;
  margin-top: 15px;
}
.LoginWithOTP_resend_code__2xEgV {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
}
.LoginWithOTP_resend_code_hover__26hPT {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
  cursor: pointer;
}
.LoginWithOTP_resend_code_hover__26hPT:hover {
  color: #858585;
}
.LoginWithOTP_checkbox__18V8s {
  padding: 2px;
}
.LoginWithOTP_para_text__35nGj {
  margin-top: 40px;
}
.LoginWithOTP_error_message__3CKRH {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.LoginWithOTP_ActionBtn__1p_hM {
  display: flex;
  justify-content: center;
}
.LoginWithOTP_back_button__2zM0i {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.LoginWithOTP_MobileNumber__c5wln {
  padding-bottom: 30px;
}
.LoginWithOTP_LoadingBtn__1tXkh {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;

}
  .LoginWithOTP_actionButtonContainer__1yvct{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
@media screen and (min-width: 1024px) {
  .LoginWithOTP_mainContainer__22uSM {
    padding: 0px 30px 40px;
  }
  .LoginWithOTP_Pagewrapper__2ebr7 {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
    height: 100vh;
  }
  .LoginWithOTP_Maincontainer_border__4QbTR {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}

.ArticlesFreelancers_ArticlesFreelancers_maindiv__2WJvF {
  background: #ececec;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.ArticlesFreelancers_ArticlesFreelancers_Display_Web__3iuWu {
  margin: auto;
  padding-top: 2%;
}
.ArticlesFreelancers_ArticlesFreelancers_image__3DZ25 {
  /* width: 120px; */
  object-fit: cover;

}

.ArticlesFreelancers_imageAndArticleText_Mob__3OTAM {
  display: flex;
  margin: 10px;
  margin-top: 20px;
}
.ArticlesFreelancers_ArticlesFreelancers_headingBold_Web__34f5B {
  font-weight: bold;
  font-size: 14px;
}

.ArticlesFreelancers_ArticlesFreelancers_headingBold_Web__34f5B:hover {
  color: #1878f2;
  cursor: pointer;
}
.ArticlesFreelancers_subhedingArticle_Web__2XQ1l {
  font-size: 12px;
  color: gray;
}
.ArticlesFreelancers_subhedingArticle_Web__2XQ1l:hover {
  color: rgb(0, 0, 0);
  cursor: pointer;
}
.ArticlesFreelancers_articlesHeadingSubHeading__1fRdS {
  margin-left: 10px;
}
@media screen and (min-width: 1024px) {
  .ArticlesFreelancers_ArticlesFreelancers_Display_Web__3iuWu {
    width: 60%;
    display: flex;
    margin: auto;
    padding-top: 2%;
    justify-content: space-around;
    align-items: flex-start;
  }

  .ArticlesFreelancers_ArticlesFreelancers_Container_Web__2BGUP {
    width: 100%;
  }

  .ArticlesFreelancers_imageAndArticleText_Web__2kF3k {
    width: 70%;
  }
  .ArticlesFreelancers_ArticlesFreelancers_headingBold_Web__34f5B {
    font-weight: bold;
    font-size: 14px;
  }
  .ArticlesFreelancers_subhedingArticle_Web__2XQ1l {
    font-size: 12px;
    color: gray;
  }
  .ArticlesFreelancers_ArticlesFreelancers_Container__mcvRL {
    display: block;
    width: 28%;

    border: none;
  }
  .ArticlesFreelancers_ArticlesFreelancers_image__3DZ25 {
 
    object-fit: cover;
  }
 
  
}

.FreelancerTheme_buttonBoxCenter__ocbdN {
    background-color: #FCD017;;
    color: black;
   border-radius: 3px;
    display: flex;
    padding: 6px 6px 6px 6px;
margin: auto;
    margin-bottom: 25px;
    border: none;
    margin-top: 10px;
    
  }
  .FreelancerTheme_buttonBoxleft__3zqXI {
    background-color: #FCD017;;
    color: black;
   border-radius: 3px;
    display: flex;
    padding: 10px 10px 10px 10px;

    margin-bottom: 25px;
    border: none;
    margin-top: 20px;
    
  }
  .FreelancerTheme_buttonText__1fGJj {
    align-self: center;
    margin: auto;
    padding: 0px 10px;
    font-weight: bold;
  cursor: pointer;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .FreelancerTheme_centerHeading__34V-w{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  .FreelancerTheme_subHeading__3JIw6{
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .FreelancerTheme_buttonBoxLeft__1-R3F:hover {
    background-color: white;
    border-width: 1px;
    border-color: #8600b0;
    border-style: solid;
    color: #8600b0;
    cursor: pointer;
  }

  .FreelancerTheme_buttonBoxWhite__2EnFx {
    background-color: #ffffff;
    color: #0f587d;
    height: auto;
    border-radius: 6px;
  
    display: flex;
    padding: 10px 10px 10px 10px;
    margin-top: 25px;
    border: none;
  }
  
  @media screen and (min-width: 768px) {
    .FreelancerTheme_centerHeading__34V-w{
      font-size: 60px;
    }
  .FreelancerTheme_subHeading__3JIw6{
  margin-top: -10px;
  }
  }

.BannerArea_imageAndContentContainer__2YTq3 {
  position: relative;
  text-align: center;
}

.BannerArea_mainContent__30kMp {
  position: absolute;
  color: rgb(255, 255, 255);

  line-height: 1.2;

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}

.BannerArea_mainContentStyle__3mwaV {
  font-size: 30px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin: 0px;
}

.BannerArea_kickStart__vNeoF {
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #fcd017;
}
.BannerArea_yellowLine__1mBpB {
  width: 40%;
  height: 5px;
  padding-left: 10%;
  margin: 10px;
  background-color: #fcd017;
  margin-left: 20px;
}

@media screen and (min-width: 1024px) {
  .BannerArea_imageAndContentContainerWeb__sEqEg {
    position: relative;
  }
  .BannerArea_mainContent__30kMp {
    margin-left: 5%;
    left: 25%;
    width: auto;
  }
  .BannerArea_yellowLine__1mBpB {
    width: 2%;
    margin-left: 0px;
  }
  .BannerArea_mainContentStyle__3mwaV {
    font-size: 50px;
  }
  .BannerArea_kickStart__vNeoF {
    font-size: 50px;
  }
}

.FutureOfWorkFreelancer_mainContainer__3iTSI{
    background-color: #F6F2F1;
    padding:15px;
    padding-bottom: 0px;
    margin-top: 40px;
}

.FutureOfWorkFreelancer_contentForFuture__4CPEA{

    color: grey;
    text-align: center;
}
.FutureOfWorkFreelancer_imageAlignment__iZxlp{
    text-align: center;
    margin-top: 30px;
   
}

.FutureOfWorkFreelancer_KnowMoreTab__29gtO{
    text-align: right;
    color: #9398a1;
font-weight: 500;
font-size: 16px;
padding: 10px;

align-items: center;
align-content: center;
justify-content: center;
display: flex;
}

.FutureOfWorkFreelancer_iconArrow__G_C8o{
    margin-top: 3px;
    font-size: 16px;
}
.FutureOfWorkFreelancer_webImageAlignment__2YJZV{
    display: none;
}
.FutureOfWorkFreelancer_knowmoreClick__1JAhe{
    text-decoration: none;
    cursor: pointer;
    color: grey;
    align-items: center;
    display: flex;
}
@media screen and (min-width: 768px) {
  
  .FutureOfWorkFreelancer_imageAlignment__iZxlp{
     display: none;
  }
  .FutureOfWorkFreelancer_imageAndContent__3XUJ9{
    display: flex;
    justify-content: space-around;
  }
  .FutureOfWorkFreelancer_webContentArea__2OUSr{
      display: grid;
      width: 50%;
  }
  .FutureOfWorkFreelancer_contentForFuture__4CPEA{
font-size: 22px;
}
.FutureOfWorkFreelancer_webImageAlignment__2YJZV{
    display: flex;
}
}
.HelpFreelancers_HelpFreelancers_flex__276Nu {
  display: block;
  margin-top: 30px;
  width: 100%;
  justify-content: space-around;
  
}
.HelpFreelancers_HelpFreelancers_image__2_iT1 {
  display: none;
}
.HelpFreelancers_HelpFreelancers_imagemob__2KcBI {
 
  text-align: center;
  margin: auto;
  display: block;
  padding: 20px;
  
}
.HelpFreelancers_HelpFreelancers_mapdiv__1hhPN {
  text-align: center;
}
.HelpFreelancers_HelpFreelancers_icons__1VREH {
margin: auto;
 
  
}
.HelpFreelancers_HelpFreelancers_iconradiusYellow__22VRd {
   border-radius: 50px;
   height: 50px;
   width: 50px;
    padding: 10px;
    background: #fff5d6;
    margin: auto;
    display: flex;
    justify-content: center
}
.HelpFreelancers_HelpFreelancers_iconradiusBlueOther__zmQ5x {
   
  border-radius: 50px;
   height: 50px;
   width: 50px;
  padding: 10px;
  background: #C7D0DD;
  margin: auto;
  display: flex;
  justify-content: center
}

.HelpFreelancers_HelpFreelancers_iconradiusblue__lzy65 {
   
  border-radius: 50px;
   height: 50px;
   width: 50px;
  padding: 10px;
  background: #f0efff;
  margin: auto;
  display: flex;
  justify-content: center
}
.HelpFreelancers_HelpFreelancers_iconradiusGreen__ihZAO{
  border-radius: 50px;
   height: 50px;
   width: 50px;
  padding: 10px;
  background: #C6E0CB;
  margin: auto;
  display: flex;
  justify-content: center
}


.HelpFreelancers_HelpFreelancers_textflex__2HSyK {
  display: flex;
  padding-top: 20px;
}
.HelpFreelancers_HeadingText__2e4Bz {
  color: #828282;
  padding: 10px;

}
.HelpFreelancers_HelpFreelancers_mainheader__38yRA {
  padding: 15px;
  margin-top: 40px;
}
.HelpFreelancers_mainHeading__2OtMF{
  font-weight: bold;
  margin-top: 5px;
}
@media screen and (min-width: 1024px) {
  .HelpFreelancers_HelpFreelancers_flex__276Nu {
    display: flex;
    margin-top: 40px;
  }
  .HelpFreelancers_HeadingText__2e4Bz {
    width: 50%;
    margin: auto;
  
  }
  .HelpFreelancers_HelpFreelancers_image__2_iT1 {
    display: block;
   
     margin-right: 1rem;
     
  }
  .HelpFreelancers_HelpFreelancers_maindiv__3eBYD {
      width: 75%;
  } 
  .HelpFreelancers_HelpFreelancers_imagemob__2KcBI {
    display: none;
  }

  .HelpFreelancers_HelpFreelancers_mainheader__38yRA {
    padding-top: 20px;
    padding-left: 0;
  }
}

@media screen and (min-width: 1400px) {
    .HelpFreelancers_HelpFreelancers_image__2_iT1 {
        margin-top: 0;
   
      }
}





.LogoComp_mainLogoDiv__1JvLk{
    width: 100%;
   margin-top: -10px;
    height: 90px;
    display: flex;
    width: 100%;
    justify-content:space-evenly;
  align-content: center;
  margin: auto;
}

.LogoComp_logoArea__2IXNc{
    width: 18%;
}


.LogoComp_logoAreaWeb__2p8o7{
    width: 7%;
}

.LogoComp_mainContainer__1blXS{
    background-color: #27B1BE;
    margin-top: -10px;
   
}
.LogoComp_mapdiv__1E4M6 {
    height: 50px;
    margin: auto;
    padding-top: 30px;
  }
  
  .LogoComp_mob_img__1KX5z {
   
    margin: auto;
    outline: none;
  }

@media screen and (min-width: 768px) {
    .LogoComp_logoArea__2IXNc{
        width: 12%;
    }
    .LogoComp_mainLogoDiv__1JvLk{
        max-width: 1366px;
        margin-top: -10px;
    }
   
}

  

.RemoteWorkCareerBoxes_mainContainer__xXLyz{
    padding: 10px;
    margin-top: 40px;
}
.RemoteWorkCareerBoxes_boxesAlingment__1KU_O{
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    
}
.RemoteWorkCareerBoxes_headingBox__AlZtP{
    width: 45%;
   font-size: 30px;
    
    font-family: "Poppins", sans-serif;
    line-height: 30px;
    margin: auto;
}
.RemoteWorkCareerBoxes_contentBoxHeading__2w_ii{
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
    padding-top: 20px;
}
.RemoteWorkCareerBoxes_contentBoxSubHeading__y3D3E{
    font-size: 13px;
}
.RemoteWorkCareerBoxes_contentBoxMainContent__3Jkdd{
    font-size: 15px;
    padding-top: 20px;
    padding: 10px;
}
.RemoteWorkCareerBoxes_BoxA__gHJzq{
    width: 48%;
    background-color: #282F39; 
    height: 180px;
    color: white;
    text-align: center;
    
    
}

.RemoteWorkCareerBoxes_BoxColorChange__3T9wG{
    width: 48%;
    background-color: #606060; 
    height: 180px;
    color: white;
    text-align: center;
}
.RemoteWorkCareerBoxes_imageBox__1Nj39{
    width: 48%;
    background-color: #282F39; 
    height: 180px;
}
.RemoteWorkCareerBoxes_boxImage__2MPqP{
    /* width: auto;
    max-width: 100%;
    object-fit:cover; */
}

.RemoteWorkCareerBoxes_wideImageAlingment__LgapK{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.RemoteWorkCareerBoxes_iconInBoxes__1UQca{
    font-size: 20px;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
.RemoteWorkCareerBoxes_boxesAlingment__1KU_O{
    justify-content: center;
}
    .RemoteWorkCareerBoxes_headingBox__AlZtP{
        width: 200px;
       font-size: 40px;
       height: 250px;
        font-family: "Poppins", sans-serif;
        line-height: 50px;
        margin: 10px;
    }
.RemoteWorkCareerBoxes_BoxA__gHJzq{
    width: 200px;
    background-color: #282F39; 
    height: 250px;
    color: white;
    text-align: center;
    margin: 10px;
     
}
.RemoteWorkCareerBoxes_BoxColorChange__3T9wG{
    width: 200px;
    background-color: #606060; 
    height: 250px;
    color: white;
    text-align: center;
    margin: 10px;
}
.RemoteWorkCareerBoxes_imageBox__1Nj39{
    width: 200px;
    background-color: #282F39; 
    height: 250px;
    margin: 10px;
}
.RemoteWorkCareerBoxes_mainContainer__xXLyz{
    width: 80%;
    margin: auto;
    padding-top: 100px;
}

.RemoteWorkCareerBoxes_wideImageAlingment__LgapK{
    margin-left: 0px;
    width:auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin: 10px;
   
    height: 250px;
}



}
.NewRequirementCard_mainContainer__1qBnY {
  padding: 15px;
  background-color: white;
  margin: 10px;
}

.NewRequirementCard_headingFromProps__2qN_G {
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}

.NewRequirementCard_pageLoader__1wxCq {
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NewRequirementCard_mainCardContainer__32AgJ {
  width: 90%;

  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.NewRequirementCard_imagecontainer__1dzLx {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.NewRequirementCard_workType__2pfe5 {
  color: indigo;
  font-weight: bolder;

  margin-left: 10px;
}
.NewRequirementCard_skillImageContainer__RFRJ0 {
  display: flex;
  height: 40%;
}
.NewRequirementCard_skillContainer__P0q3f {
  font-size: 18px;
  font-weight: bold;
}

.NewRequirementCard_titleArea__2JNoS {
  margin-top: 10px;
  color: grey;
}
.NewRequirementCard_workStatus__1Y1nQ {
  background-color: #d6ffe3;
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
  color: #43c962;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.NewRequirementCard_actionContainer__1ZdBN {
  height: 100px;
}
.NewRequirementCard_ActionButton__35keE {
  text-align: right;
}
.NewRequirementCard_PARBtn_Link__2CQYK {
  text-decoration: none;
}
.NewRequirementCard_postedDate__3TPsg {
  font-style: italic;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
}
.NewRequirementCard_budget__2QI2e {
  font-weight: bold;
  font-size: 22px;
}

.NewRequirementCard_iconAndButtonContainer__1rJ-3 {
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
}

.NewRequirementCard_workPolicy__ISzoO {
  padding: 3px;
  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.NewRequirementCard_workPolicyAndWorkType__2FUPv {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.NewRequirementCard_statusAnddate__5riuS {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.NewRequirementCard_Commission__1ZEWW {
  color: #fea11b;
}

.NewRequirementCard_Contract__20uZW {
  color: #486a31;
}
.NewRequirementCard_One-Time__2tXVE {
  color: #365aa9;
}
.NewRequirementCard_Monthly__pUMwk {
  color: #125552;
}

.NewRequirementCard_knowMore__9GKRr {
  text-align: right;
  color: #9398a1;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;

  align-items: center;
  align-content: center;
  justify-content: flex-end;
  display: flex;
}

.NewRequirementCard_knowMore__9GKRr:hover {
  cursor: pointer;
  color: #1778f2;
}

.NewRequirementCard_iconArrow__3-Axv {
  margin-top: 3px;
}

.NewRequirementCard_heartIcon__2zDL4 {
  cursor: pointer;
}
.NewRequirementCard_mainCardContainer__32AgJ:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.NewRequirementCard_cardHeightSetting__2xOQ2 {
  cursor: pointer;
}
.NewRequirementCard_budgetUnit__2VS-2 {
  font-size: 14px;
  font-weight: 500;
}
@media screen and (min-width: 1023px) {
  .NewRequirementCard_webLayout__3YyU_ {
    display: flex;

    padding-left: 30px;
    padding-right: 30px;
  }
  .NewRequirementCard_mainContainer__1qBnY {
    padding: 0px;
  }
  .NewRequirementCard_mainCardContainer__32AgJ {
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px;
  }
  .NewRequirementCard_cardHeightSetting__2xOQ2 {
    height: 280px;
  }

  .NewRequirementCard_knowMore__9GKRr {
    margin-right: 3%;
    margin-bottom: 20px;
  }
}

.RIFooter_footer_container__1kA7X {
  background-color: #171717;
  margin-top: 25px;
  color: #ffffff;
}
.RIFooter_footer_aboutUs__tB2Zq {
  display: flex;
  width: 57%;
  justify-content: space-between;
}
.RIFooter_footer_top__1rbiV {
  display: flex;
  padding: 20px;

  justify-content: flex-end;
  border-bottom: 1px groove grey;
}
.RIFooter_footer_contact__1IeEh {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.RIFooter_aboutUs_list__3p5xn {
  text-align: center;

  width: 95%;
}
.RIFooter_BottomBorder__1zGYs {
  border-bottom: 1px groove grey;
}
.RIFooter_plus_icon__3TvNb {
  background-color: #171717;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  font-size: 16px;
  width: 5%;
}
.RIFooter_plus_iconText__1mlJd {
  background-color: #171717;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  font-size: 16px;
  width: 95%;
}
.RIFooter_riLogo__1da-o {
  margin: 30px 0;
  height: 58px;
  cursor: pointer;
}

.RIFooter_social_icons__jTg-z {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 260px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.RIFooter_icons__2HD5C {
  padding: 0px 10px;
  height: 16px;
  cursor: pointer;
  border-right: 1px groove grey;
}
.RIFooter_icons__2HD5C:last-child {
  border-right: hidden;
}
.RIFooter_payment_icon__2MgXJ {
  margin-top: 20px;
}
.RIFooter_all_rights__Vw5uP {
  text-align: center;
  padding: 10px 0px;
}
.RIFooter_list_open__1MLIX {
  margin: 10px 0px;
}
.RIFooter_web_view_container__2NW6x {
  display: none;
}
.RIFooter_Footer_Link__16lt2 {
  text-decoration: none;
  color: #ffffff;
}
.RIFooter_Footer_Link__16lt2:hover {
  text-decoration: none;
  color: #1778f2;
}
.RIFooter_icons_web__29Emh:hover {
  color: #1778f2;
  border: 1px solid #1778f2;
}
.RIFooter_icons_web__29Emh {
  margin-right: 8px;
  border: 1px solid white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
}
.RIFooter_MailBtn__1Lb_O {
  background: none;
  border: none;
  padding: 0px;
  color: #fff;
}
.RIFooter_followUsText__zsWG3 {
  font-size: 22px;
}
.RIFooter_haedingName__1-Rbx {
  font-weight: bold;
}
@media screen and (min-width: 1023px) {
  .RIFooter_web_view__tFDmg {
    display: flex;
    margin: 0px 130px;
  }

  .RIFooter_web_view_container__2NW6x {
    display: block;
  }
  .RIFooter_mob_view__cdKw- {
    display: none;
  }
  .RIFooter_aboutUs_list_web__2CFeQ {
    text-align: left;
    margin: 30px 0px;
    width: 33%;
  }
  .RIFooter_social_icons_web__AH5wx {
    display: flex;
    width: 336px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
  }

  .RIFooter_banking_partners__1Ruu7 {
    border-top: 1px groove gray;
    display: flex;
    justify-content: center;
  }
  
  .RIFooter_footer_bottom__2qkiC {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 130px;
  }
}
.RIFooter_icons_web_Link__2d17A {
  text-decoration: none;
  color: white;
}
@media screen and (min-width: 500px) {
  .RIFooter_footer_aboutUs__tB2Zq {
    width: 52%;
  }
  .RIFooter_footer_contact__1IeEh {
    width: 55%;
  }
}

.WorkProfile_mainContainer__1b2jU {
  padding: 10px 20px;
  width: 90%;
}
.WorkProfile_input_container__1WBYc {
  margin-top:5%;

}

.WorkProfile_error_message__37iPb {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}

.WorkProfile_ActionBtn__1Uqko {
  display: flex;
  justify-content: center;
}
.WorkProfile_webView_image__Rv_1X {
  display: flex;
  width: 13vw;
  margin: auto;
}
.WorkProfile_subHeading__2jUrn {
  font-size: 14px;
  padding-bottom: 10px;
  color: grey;
}
.WorkProfile_heading__2fsft {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
    margin-bottom: 10px;

}
.WorkProfile_Maincontainer_border__1Sgx0 {

  margin: auto;
  background-color: #fff;
}
.WorkProfile_inputSections__-5sFX{
  margin: 20px 0;
}

.WorkProfile_borderBottomHeading__34lBS{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.WorkProfile_relatedSkillContainer__OriFl {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: scroll;
}

.WorkProfile_relatedSkillBox__1Hfor {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.WorkProfile_relatedSkillBox__1Hfor:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}
.WorkProfile_addIcon__2ytR3 {
  margin-left: 5px;
}
.WorkProfile_subskillHeading__2g6cm{
  font-size: 19px;
  font-weight: 700;
  padding: 10px 0px;
}

.WorkProfile_manadatoryFields__Ts3Gi{
  color: red;
}

.WorkProfile_pageArealayout__BBnLu{
  margin-top: 5%;
}
@media (min-width: 1023px) {
  .WorkProfile_Maincontainer_border__1Sgx0 {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .WorkProfile_input_container__1WBYc{
    margin-top: 2%;
  }
  .WorkProfile_fields__2BNxR {
    padding: 10px 20px;
  }
  .WorkProfile_CheckboxStyle__1dI54 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .WorkProfile_workPolicyCheck__2uTIi {
    width: 270px;
  }
  .WorkProfile_countryStateContainer__3NijD {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .WorkProfile_cityPincodeContainer__3yU8W {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .WorkProfile_cityDropdown__2QwyV {
    width: 400px;
  }
  .WorkProfile_workPolicyCheckbox__3sy2i {
    width: 200px;
  }
  .WorkProfile_mainHeading__3qOiy {
    font-size: 25px;
    font-weight: bold;
margin: 0px;
  
  }
  .WorkProfile_mainContainer__1b2jU {
   width: auto;
    padding-left: 20px;
  }

  .WorkProfile_heading__2fsft {
    font-size: 18px;
    font-weight: 700;
  
  }
  .WorkProfile_subHeading__2jUrn {
    font-size: 16px;
   
  }
  .WorkProfile_web_view__1wfk0 {
    display: flex;
    width: 80%;
    
    align-items: center;
  }
  .WorkProfile_pageArealayout__BBnLu{
    margin-top: 5%;
  }
}

.AutoCompelete_mainDiv__3VWhf {
  max-width: 800px;
  flex-direction: column;
margin-bottom: 12px;
}
.AutoCompelete_input__19OGQ {
  width: 100%;
  height: 42px;
  margin-top: 5px;
  border: 1px solid #ededed;
  flex-direction: column;
  display: flex;
  padding: 12px;
  outline: none;
  font-size: 18px;
}

.AutoCompelete_input__19OGQ {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}
.AutoCompelete_input__19OGQ:focus {
  border-color: #1778f2;
}

.AutoCompelete_suggestion__BhXrk {
  max-width: 493px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 3px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.AutoCompelete_suggestionLabel__1Wvo5{
  -webkit-tap-highlight-color: transparent;
  
}
.AutoCompelete_suggestionTagFirst__SA05X {
  background: linear-gradient(45deg, #4568dc, #b06ab3);
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.AutoCompelete_closeIcon__2IDfF {
  margin-top: 4px;
  align-items: center;
  margin-left: 6px;
  float: right;
}
.AutoCompelete_primary__1PD-c {
  font-size: 12px;
  text-align: right;
  margin-right: 5px;
  font-weight: bold;
  font-style: italic;
}
.AutoCompelete_suggestionTag__3Km-W {
  background-color: #1678f2;
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.AutoCompelete_skillSuggestionList__3VHYm {
  padding: 6px;
}

.AutoCompelete_skillSuggestionList__3VHYm:hover {
  background-color: #ededed;
  cursor: pointer;
}
.AutoCompelete_dropdown_items_active__3zx-j {
  background-color: #1678f2;
  color: #fff;
}
.AutoCompelete_tagMainDiv__2FbHr {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.AutoCompelete_suggestion__BhXrk p {
  padding: 6px;
  cursor: pointer;
  font-size: 15px;
  margin: 0;
}

p:hover {
  background-color: #ededed;
}
.AutoCompelete_icon__sb96O {
  position: absolute;
  margin-left: 49vw;
  margin-top: 13px;
  align-items: flex-end;
}

#AutoCompelete_Search__3vhE4 {
  padding-left: 48px;
  padding-top: 1px;
  font-size: 22px;
  color: black;
  background-repeat: no-repeat;
  background-position: center;
  outline: 0;
}

#AutoCompelete_Search__3vhE4::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: black;
}
.AutoCompelete_searchIcon__22PMz {
  position: absolute;
  top: 12px;
  left: 15px;
}
.AutoCompelete_highlighted__EsXrv {
  background: #ededed;
}
.AutoCompelete_tagMainDiv__2FbHr {
  margin-bottom:20px;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.AutoCompelete_suggestionTagLanguage__544DE {
  
  margin:4px 4px;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.AutoCompelete_closeIcon__2IDfF {

  margin-top: 4px;
  align-items: center;

  margin-left: 6px;
  float: right;
}
.AutoCompelete_dropdownLanguageFluency__2o3Qg{
  border: 1px solid #cccccc;
  background-color: #fff;
  outline: none;
margin: 0px 10px;
border-radius: 5px;
padding: 0px 5px;
}
@media only screen and (min-width: 1024px) {
  .AutoCompelete_mainDiv__3VWhf {
    flex-direction: column;
    margin: 0 0px;
  }
  
}

.AboutFreelancer_mainContainer__3rtLm {
  background-color: rgb(255, 255, 255);

  padding: 20px;
 
}
.AboutFreelancer_editBtn__xM4Yj {
  display: flex;
  justify-content: flex-end;
}

.AboutFreelancer_heading__2mt3P {
  font-size: 20px;
  font-weight: bold;
}

.AboutFreelancer_SkillTab__3Hz8a {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
}
.AboutFreelancer_tabContainer__3T967 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.AboutFreelancer_SkillTab__3Hz8a:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}

.AboutFreelancer_mainHeading__Kx_dy {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}

@media (min-width: 1023px) {
  .AboutFreelancer_mainContainer__3rtLm {
 
    width: 60%;
    margin: 10px 0px 0px 10px;
    
  }
}

.MenuArea_mainContainer__AScgp {
  background-color: white;
  margin-top: 10px;
}
.MenuArea_CrossIconContainer__2twAa {
  display: flex;
}
.MenuArea_experienceListContainer__1cH7Q {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 20px;
  border-radius: 3px;
  width: 100%;
}
.MenuArea_updateImage__3gwTe {
  display: flex;
  height: 100px;
  width: 100px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.MenuArea_fileIcon__3BoLJ {
  margin: 10px;
  border-radius: 10px;
  padding: 34px;
  border: 1px solid #ece9e9;
}

.MenuArea_menuContainer__2cxpU {
  padding: 10px;
  display: flex;
  background-color: #f0efef;
  justify-content: space-between;
  overflow: scroll;
}
.MenuArea_menuOptions__1HNKd {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
}

.MenuArea_menuOptions__1HNKd:hover {
  cursor: pointer;
  color: #1678f2;
}

.MenuArea_selectedMenu__1glbd {
  border-bottom-width: 3px;
  border-bottom-color: #1678f2;
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
  border-bottom-style: solid;
}
.MenuArea_portfolioImageContainer__3zYxM {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.MenuArea_selectedMenu__1glbd:hover {
  cursor: pointer;
  color: #1678f2;
}

.MenuArea_portofolioImage__1mzKE {
  width: 100%;
}

.MenuArea_imageDiv__1L8hY {
  height: 200px;
  margin: 20px;
  width: 200px;
}

.MenuArea_imageMainArea__2yQKO {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.MenuArea_certificationListContainer__1N5LL {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 20px;
  border-radius: 3px;
  width: 90%;
}
.MenuArea_CertificationContainer__2cQB- {
  display: flex;
  width: 100%;
}
.MenuArea_editBtn__p-DwJ {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.MenuArea_noCertifications__3liLZ {
  margin: 20px auto;
}
.MenuArea_noPortfolio__5r24i{
  margin: 20px auto;
}
.MenuArea_noExperience__2sst1{
  padding: 20px 0px;
  display: flex;
  justify-content: center;
}

@media (min-width: 1023px) {
  .MenuArea_menuContainer__2cxpU {
    overflow: hidden;
  }
}

.ProfileImage_mainContainer__2PNdK {
  background-color: #fff;
  width: 100%;
  padding-bottom: 30px;
}
.ProfileImage_uploadIcon__3naXi {
  position: absolute;
  right: 130px;
  top: 119px;
}
.ProfileImage_mainProfileImageContainer__dVSIa {
  height: 200px;
  background-color: green;
  width: 100%;
}
.ProfileImage_mainProfileImage__sQTsu {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.ProfileImage_editBtn__XyutP {
  display: flex;
  justify-content: flex-end;

  margin: -60px 20px 44px 0px;
}

.ProfileImage_profilePictureNameContainer__21-MS {
  margin-top: -80px;
  text-align: center;

  width: 100%;
}
.ProfileImage_profilePicture__11Fey {
  height: 150px;
  width: 150px;
  background-color: #ffff00;
  border-radius: 50%;
  margin: auto;
}
.ProfileImage_freelancerName__18gSi {
  font-size: 30px;
  font-weight: bold;
  padding: 10px 0px;
}

.ProfileImage_nameAndSkillContainer__3a1eC {
  text-align: center;
}

.ProfileImage_skillCat__nETxi {
  font-size: 20px;
  text-transform: capitalize;
}
.ProfileImage_updateImage__1HiMV {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.ProfileImage_editBtn__XyutP {
  display: flex;
  justify-content: flex-end;
}
.ProfileImage_mainHeading__185UQ {
  font-weight: bold;
  font-size: 18px;
}
.ProfileImage_socialContainer__1aRVQ {
  text-align: center;
  padding: 20px 0px;
}
.ProfileImage_ratingContainer__Iwe3d {
  text-align: center;
}
.ProfileImage_iconContainer__1F6zo {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  justify-content: center;
}
.ProfileImage_socialIcon__2oHDN {
  padding-right: 12px;
  cursor: pointer;
}
.ProfileImage_flagImage__3gDiI {
  height: 25px;
  margin-right: 10px;
  margin-top: 5px;
}
.ProfileImage_ratings__3SZQL {
  padding: 10px;
}
.ProfileImage_ratingStar__3lfrZ {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1023px) {
  .ProfileImage_profilePictureNameContainer__21-MS {
    width: auto;
    margin-top: -70px;
    margin-left: 2%;
  }
  .ProfileImage_uploadIcon__3naXi {
    position: absolute;
    right: 20px;
    top: 117px;
  }
  .ProfileImage_mainContainer__2PNdK {
    background-color: #fff;
    width: 35%;
    padding-left: 60px;
    padding-bottom: 30px;
  }
  .ProfileImage_skillCat__nETxi {
    font-size: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .ProfileImage_nameAndSkillContainer__3a1eC {
    text-align: left;
  }
  .ProfileImage_profilePicture__11Fey {
    height: 150px;
    width: 150px;
    background-color: #ffff00;
    border-radius: 50%;
    margin: 0px;
  }
  .ProfileImage_socialContainer__1aRVQ {
    text-align: left;
    padding: 20px 0px;
  }
  .ProfileImage_ratings__3SZQL {
    text-align: left;
    padding: 10px 0px;
  }
  .ProfileImage_ratingStar__3lfrZ {
    justify-content: unset;
  }
  .ProfileImage_iconContainer__1F6zo {
    display: flex;
    flex-direction: row;
    margin: 8px 0px;
    justify-content: unset;
  }
}

.WorkDetail_mainContainer__XGJNk {
  background-color: white;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
}
.WorkDetail_mainHeading__2jKe2 {
  font-weight: bold;
  font-size: 18px;
}
.WorkDetail_workAvailability__Mh0y_ {
  padding-top: 10px;
}
.WorkDetail_sectionContainer__1356B {
  margin-top: 20px;
}
.WorkDetail_editBtn__vkXDO {
  display: flex;
  justify-content: flex-end;
}

.FreelancerProfileMain_pageLayout__aKgaI {
  background-color: #f5f6fb;
}

.FreelancerProfileMain_leftArea__1GjQD {
  width: 70%;
  margin-right: 10px;
}

.FreelancerProfileMain_pageContainer__ei0N3 {
  display: flex;
}
.FreelancerProfileMain_rightArea__150Ll {
  width: 30%;
}
.FreelancerProfileMain_closeBtn__3hVwy {
  display: flex;
  justify-content: flex-end;
}
.FreelancerProfileMain_mainProfileImageContainer__3IURS {
  height: 200px;
  background-color: green;
  width: 100%;
}
.FreelancerProfileMain_mainProfileImage__1mgko {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.FreelancerProfileMain_profileAboutContainer__1pJ3G {
  display: flex;
  width: 100%;
}
.FreelancerProfileMain_MenuAreaContainer__pBUW6 {
  padding: 10px 0px 30px 0px;
}

.KycForm_kycFormMainContainerForm__3IoEo {
  padding: 15px;
  margin-right: 20px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
}
.KycForm_kycFormMainContainer__33Cpz {
  background-color: rgb(255, 255, 255);
}
.KycForm_kycFormMainContainerDisabled__1fhg6 {
  background-color: rgb(255, 255, 255);
  pointer-events: none;
  opacity: 0.4;
}
.KycForm_kycHeading__dPY94 {
  font-family: "Manrope", sans-serif;
  font-size: 25px;
  font-weight: 900;
}
.KycForm_flex__1XOMb {
  display: block;
}
.KycForm_uploadIdProof__2kkRZ {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 28px;
}
.KycForm_ErrorMessage__mlc04 {
  color: #f22;
  text-align: start;
  font-size: 14px;
  margin: 0;
}
.KycForm_ErrorMessage__mlc04 :hover {
  background-color: white;
}
.KycForm_center__3B33d {
  text-align: center;
  font-size: 30px;
  margin-top: 150px;
  font-weight: bold;
}
.KycForm_statusicon__3-t6G {
  font-size: 40px;
  color: green;
}
.KycForm_size__3EEa- {
  font-size: 20px;
}
.KycForm_pending__3RmYZ {
  background-color: orange;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
}
.KycForm_rejected__PoZ11 {
  background-color: #ff2222;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
}
.KycForm_approved___KkhU {
  background-color: #ffde5d;
  color: #000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
}
.KycForm_approveAndSubmitted__222Uk {
  text-align: center;
}
.KycForm_loadercenter__27Kqe {
  text-align: center;
  margin-top: 3rem;
}
@media screen and (min-width: 769px) {
  .KycForm_inputArea__42z2n {
    margin-top: 4rem;
    text-align: center;
  }
  .KycForm_flex__1XOMb {
    display: flex;
  }
  .KycForm_flexIdProof___hTX- {
    display: flex;
  }
  .KycForm_widthhalfgender__1nlif {
    width: 40%;
    padding-right: 3rem;
    margin-top: 8px;
  }
  .KycForm_widthhalf__376Zp {
    width: 40%;
    padding-right: 3rem;
  }
  .KycForm_widthThirtyThree__1xSVG {
    width: 33%;
    padding-right: 2rem;
  }
  .KycForm_submitButton__1R8ZY {
    width: 30%;
    margin: auto;
  }
  .KycForm_uploadIdProof__2kkRZ {
    font-family: "Poppins", sans-serif;
    font-size: large;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .KycForm_widthimageupload__1__Lo {
    width: 40%;
    padding-right: 2rem;
  }
}

.ExploreSkills_ExploreSkills_maincontainer__5na6f {
  padding: 30px 0px;
}
.ExploreSkills_ExploreSkills_img__28Q-J {
  
  display: block;
  position: absolute;
}
.ExploreSkills_ExploreSkills_heading__E8CFu {
  font-size: 24px;
  font-weight: bold;
}
.ExploreSkills_ExploreSkills_subheading__1p8mL{
  margin: 0px;
}
.ExploreSkills_ExploreSkills_maintext__21PGN {
  position: absolute;
  padding-top: 13px;
  position: relative;
  padding-left: 10px;
}
.ExploreSkills_ExploreSkills_imgweb__2rzU9 {
  display: none;
}
.ExploreSkills_partnerCompanies_about__2Oy_Y {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .ExploreSkills_ExploreSkills_imgweb__2rzU9 {
    position: absolute;
   
    display: block;
    
  }
  .ExploreSkills_ExploreSkills_img__28Q-J {
    display: none;
  }
  .ExploreSkills_ExploreSkills_maintext__21PGN {
    position: relative;
    padding-top: 26px;
    font-size: 20px;
    text-align: center;
  }
  .ExploreSkills_ExploreSkills_heading__E8CFu {
    font-size: 40px;
    font-weight: bold;
  }
  .ExploreSkills_partnerCompanies_border__S3eH7 {
    border-bottom: 6px solid #3d2086;
    width: 15%;
    margin: 30px auto;
    border-radius: 6px;
  }
}

.ExploreSkillsNavbar_header_container__4eRTO {
  width: 100%;
}
.ExploreSkillsNavbar_scroll_container__rrBR_ {
  overflow-x: scroll;
}
.ExploreSkillsNavbar_navbar__1tEoh {
  display: flex;
  font-size: 24px;
  border-bottom: 2px solid #f0f0f0;
  width: 455px;
  overflow-y: hidden;
}
.ExploreSkillsNavbar_menuOptionSelected__2l1Sc {
  font-size: 24px;
  margin: 5px 15px 0px;
  font-weight: bold;
  color: #060606;
  text-decoration: none;
  border-bottom-width: 4px;
  border-bottom-color: #422883;
  border-bottom-style: solid;
  padding-top: 20px;
  padding-bottom: 10px;
}
.ExploreSkillsNavbar_menuOptionContent__2gKCR:hover {
  cursor: pointer;
}
.ExploreSkillsNavbar_menuOptionContent__2gKCR {
  font-size: 24px;
  margin: 5px 15px 0px;
  font-weight: 500;
  color: #060606;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 10px;
}
.ExploreSkillsNavbar_TextLineFirst__3ystm {
  cursor: pointer;
  margin-top: 20px;
}
.ExploreSkillsNavbar_TextLineFirstbold__2zuGR {
  font-weight: bold;
  margin-top: 20px;
}
.ExploreSkillsNavbar_skills_Link__s4uNn {
  text-decoration: none;
  color: #000;
}

.ExploreSkillsNavbar_jshondata_map__1bxBi {
  padding-top: 20px;
}
.ExploreSkillsNavbar_dflex__ygpDF {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-left: 10px;
}
.ExploreSkillsNavbar_jshondata__15nuc {
  margin-left: 36px;
}
.ExploreSkillsNavbar_relatedSkillBox__2yvec {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ExploreSkillsNavbar_relatedSkillBox__2yvec:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}

.ExploreSkillsNavbar_relatedSkillContainer__unQKu {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: scroll;
}
.ExploreSkillsNavbar_mainDiv__2K-K8 {
  width: 100%;
  margin-top: 5px;
}
@media screen and (min-width: 768px) {
  .ExploreSkillsNavbar_header_container__4eRTO {
    width: 85%;
    margin: auto;
    display: flex;
  }
  .ExploreSkillsNavbar_navbar__1tEoh {
    display: block;
    border-bottom: none;
    width: 100%;
    overflow: hidden;
  }
  .ExploreSkillsNavbar_MenuOnHover_active__1npq5 {
    border-bottom: none;
    font-size: 28px;
  }

  .ExploreSkillsNavbar_menuOptionSelected__2l1Sc {
    border-bottom: none;
    cursor: pointer;
    font-size: 28x;
  }
  .ExploreSkillsNavbar_dflex__ygpDF {
    display: flex;
    flex-direction: row;
  }
  .ExploreSkillsNavbar_jshondata__15nuc {
    flex-wrap: wrap;
  }
  .ExploreSkillsNavbar_jshondata_map__1bxBi {
    padding-top: 20px;
    cursor: pointer;
  }

  .ExploreSkillsNavbar_TextLineFirst__3ystm {
    width: 100%;
    cursor: pointer;
  }
  .ExploreSkillsNavbar_TextLineFirstbold__2zuGR {
    font-weight: bold;
    cursor: pointer;
  }
  .ExploreSkillsNavbar_TextLine__zFExq {
    width: 100%;
  }
  .ExploreSkillsNavbar_scroll_container__rrBR_ {
    overflow-x: hidden;
  }
  .ExploreSkillsNavbar_ItemName__1_XTh {
    width: 100%;
    margin-left: 30px;
    cursor: pointer;
    padding-top: 12px;
  }
  .ExploreSkillsNavbar_mainDiv__2K-K8 {
    width: 600px;
    margin-top: 5px;
  }
}

.AutoCompelete_mainDiv__fPAEt {
  max-width: 800px;
  flex-direction: column;
  margin-bottom: 12px;
}
.AutoCompelete_input__3PRz9 {
  width: 100%;
  height: 42px;
  margin-top: 5px;
  border: 1px solid #ededed;
  flex-direction: column;
  display: flex;
  padding: 12px;
  outline: none;
  font-size: 18px;
}
.AutoCompelete_skillSuggestionList__361ap {
  padding: 6px;
}

.AutoCompelete_skillSuggestionList__361ap:hover {
  background-color: #ededed;
  cursor: pointer;
}
.AutoCompelete_dropdown_items_active__3tm2d {
  background-color: #1678f2;
  color: #fff;
}
.AutoCompelete_primary__2fAVs {
  font-size: 12px;
  text-align: right;
  margin-right: 5px;
  font-weight: bold;
  font-style: italic;
}
.AutoCompelete_input__3PRz9 {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}
.AutoCompelete_input__3PRz9:focus {
  border-color: #1778f2;
}

.AutoCompelete_suggestion__1mNcO {
  max-width: 493px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 3px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.AutoCompelete_suggestion__1mNcO p {
  padding: 6px;
  cursor: pointer;
  font-size: 15px;
  margin: 0;
}

.AutoCompelete_skillSuggestionList__361ap {
  padding: 6px;
 
}

.AutoCompelete_skillSuggestionList__361ap:hover {
  background-color: #ededed;
  cursor: pointer;
}

p:hover {
  background-color: #ededed;
}
.AutoCompelete_icon__16WEu {
  position: absolute;
  margin-left: 49vw;
  margin-top: 13px;
  align-items: flex-end;
}

#AutoCompelete_Search__3cLSv {
  padding-left: 48px;
  padding-top: 1px;
  font-size: 22px;
  color: black;
  background-repeat: no-repeat;
  background-position: center;
  outline: 0;
}

#AutoCompelete_Search__3cLSv::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: black;
}
.AutoCompelete_searchIcon__I7Xdb {
  position: absolute;
  top: 12px;
  left: 15px;
}
.AutoCompelete_highlighted__3rQ1C {
  background: #ededed;
}
.AutoCompelete_tagMainDiv__3VzYZ {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.AutoCompelete_suggestionTag__pAcGa {
  background-color: #1678f2;
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.AutoCompelete_suggestionTagFirst__2Zhy2 {
  background: linear-gradient(45deg, #4568dc, #b06ab3);
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.AutoCompelete_closeIcon__3GG3E {
  margin-top: 4px;
  align-items: center;
  margin-left: 6px;
  float: right;
}

@media only screen and (min-width: 1024px) {
  .AutoCompelete_mainDiv__fPAEt {
    flex-direction: column;
    margin: 0 0px;
  }
}

.ArticlesRozgaar_shadowDiv__1X83J {
  border-radius: 12px;
}
.ArticlesRozgaar_shadowMainDiv__2Rala {
  position: relative;
}
.ArticlesRozgaar_cardImageDiv__pU3-e {
  width: 210px;
}
.ArticlesRozgaar_banner__3czI3 {
  width: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  opacity: 1;
}
.ArticlesRozgaar_bannerDiv__3z4nV {
  width: 100%;
  position: absolute;
}
.ArticlesRozgaar_bannerDiv__3z4nV .ArticlesRozgaar_banner__3czI3 {
  opacity: 0.5;
}
.ArticlesRozgaar_articleBanner__1dOXq {
  
  margin: auto;
  display: block;
}
.ArticlesRozgaar_postedDateDiv__3EgkZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.ArticlesRozgaar_iconDiv__z9AGi {
  font-size: 15px;
}
.ArticlesRozgaar_postedText__1M54P {
  font-size: 12px;
  font-weight: 700;
  padding-right: 2px;
}
.ArticlesRozgaar_shadowDivHeading__1YYPB {
  padding: 15px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  background-color: #fff;
}
.ArticlesRozgaar_cardMainDiv__1cgze {
  padding: 20px;
}
.ArticlesRozgaar_contentMainDiv__1W9ex {
  padding: 15px;
}
.ArticlesRozgaar_imageCard__bo7wK {
  
  object-fit: cover;
}
.ArticlesRozgaar_shadowDivContentHeading__gHg1A {
  font-weight: 800;
  font-size: 17px;
  margin: 20px 0 10px 0;
}
.ArticlesRozgaar_cardHeading__35R4i {
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 5px;
  line-height: 1.2;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ArticlesRozgaar_articleDiv__39k8B {
  display: flex;
  overflow: scroll;
}
.ArticlesRozgaar_articleCard__m_ySY {
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.ArticlesRozgaar_cardContent__13yPr {
  font-size: 13px;
  color: black;
  font-weight: 500;
  margin-top: 5px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.ArticlesRozgaar_articleCardInfoDiv__2A4VC {
  display: flex;
  align-items: center;
}
.ArticlesRozgaar_info__3m3wL {
  margin-left: 7px;
  font-size: 13px;
}
.ArticlesRozgaar_articleDiv__39k8B::-webkit-scrollbar {
  display: none;
}
.ArticlesRozgaar_infoMainDiv__2DftR {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  color: black;
  width: 100%;
}
.ArticlesRozgaar_icon__36oA7,
.ArticlesRozgaar_watchIcon__3E_o6 {
  color: 848484px !important;
}
.ArticlesRozgaar_iconLink__14lZH {
  text-decoration: none;
  color: black;
  margin-left: 10px;
}

.ArticlesRozgaarWeb_banner__1vLry {
  width: 100%;
  height: 499px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  opacity: 1;
}
.ArticlesRozgaarWeb_bannerDiv__2iHSR {
  position: absolute;
  width: 100%;
}
.ArticlesRozgaarWeb_bannerDiv__2iHSR .ArticlesRozgaarWeb_banner__1vLry {
  opacity: 0.5;
}

.ArticlesRozgaarWeb_articleTitleMargin__211X5 {
  font-size: 34px;
  text-align: center;
  color: black;
  font-weight: 800;
  margin-bottom: 2rem;
}
.ArticlesRozgaarWeb_icon__9esXG {
  color: #848484;
  font-size: 13px;
  margin-right: 4px;
}
.ArticlesRozgaarWeb_watchIcon__2vc8y {
  color: #848484;
  font-size: 16px;
  margin-right: 4px;
}
.ArticlesRozgaarWeb_recommendedArticles__2lK74 {
  font-size: 34px;
  text-align: center;
  color: black;
  font-weight: 800;
  margin: 0px;
  padding-top: 40px;
}
.ArticlesRozgaarWeb_recommendedArticlesSubHeading__TReOj {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 0px;
}
.ArticlesRozgaarWeb_cardMainDiv__3Zw4Y {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
  cursor: pointer;
}
.ArticlesRozgaarWeb_cardHeading__ov8-f {
  font-size: 16px;
  font-weight: 800;
  color: black;
  margin-top: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ArticlesRozgaarWeb_articleCardInfoDiv__mNTvi {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: black;
  font-size: 12px;
}
.ArticlesRozgaarWeb_infoMainDiv__2la_L {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}
.ArticlesRozgaarWeb_cardContent__1pbSJ {
  font-size: 13px;
  font-weight: 600;
  color: black;
  margin-top: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.ArticlesRozgaarWeb_image__3Z7gF {
  
  object-fit: cover;
}
.ArticlesRozgaarWeb_cardInnerDiv__2bVYd {
  margin-right: 20px;
  width: 23%;
}
.ArticlesRozgaarWeb_shadowDiv__3Yt1I {
  background-color: #fff;
  border-radius: 10px;
  margin-left: 5rem;
  margin-right: 5rem;
}
.ArticlesRozgaarWeb_shadowMainDiv__Koy-W {
  padding: 60px;
}


.ArticlesRozgaarWeb_postedDateDiv__1jFSg {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.ArticlesRozgaarWeb_iconDiv__1mhAP {
  margin-bottom: 10px;
  font-size: 25px;
  color: #5d5b5b;
  float: right;
  text-align: center;
}
.ArticlesRozgaarWeb_iconLink__SEul4 {
  text-decoration: none;
  color: black;
  margin-left: 10px;
}
.ArticlesRozgaarWeb_postedText__1zL4u {
  font-size: 16px;
  font-weight: 800;
  float: right;
}
.ArticlesRozgaarWeb_shadowDivHeading__2QjU9 {
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 55px;
}
.ArticlesRozgaarWeb_contentMainDiv__28UJp {
  padding-top: 15px;
}
.ArticlesRozgaarWeb_shadowDivContentHeading__1e6nz {
  font-weight: 700;
  font-size: 26px;
  margin: 10px 0 10px 0;
  padding-top: 10px;
}

.PublicClientRequirementList_pageLayout__3xph9 {
  padding: 15px;
}

.PublicClientRequirementList_pageDivider__395Z1 {
  display: flex;
}

.PublicClientRequirementList_filterContainer__3Ge40 {
  height: auto;
  width: 25%;
  background-color: white;
  padding: 20px;
}

.PublicClientRequirementList_filterByHeading__mgoba {
  font-weight: bold;
  padding: 10px 10px 3px 10px;
  font-size: 20px;

  border-bottom-width: 2px;
  border-bottom-color: black;
  border-bottom-style: solid;
}

.PublicClientRequirementList_filterOptionHeadings__njyz1 {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
}

.PublicClientRequirementList_checkBoxContainer__1tEDa {
  display: flex;
  align-items: center;
}

.PublicClientRequirementList_budgetTextInput__1-GOo {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.PublicClientRequirementList_textInput__gjHrD {
  width: 50%;
  margin: 3px;
  padding: 5px 10px;
  font-size: 14px;
}

.PublicClientRequirementList_goButton__2y-49 {
  padding: 3px;
  border: 1px solid gray;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  border-radius: 3px;
}
.PublicClientRequirementList_goButton__2y-49:hover {
  cursor: pointer;
  background-color: #1678f2;
  color: white;
}

.PublicClientRequirementList_sortAndRequirmentContainer__13C8u {
  background-color: white;
  margin-left: 10px;
}

.PublicClientRequirementList_sortAndPageContainer__1cnuI {
  justify-content: center;
}
.PublicClientRequirementList_sortContainer__113nI {
  display: flex;
  overflow: scroll;
  width: 100%;
  justify-content: space-around;
}

.PublicClientRequirementList_sortHeading__3NNHU {
  font-weight: bold;
}

.PublicClientRequirementList_sortHeading__3NNHU:hover {
  cursor: pointer;
}

.PublicClientRequirementList_sortOptions__161i9:hover {
  cursor: pointer;
}
.PublicClientRequirementList_sortOptionSelected__9VkW5 {
  border-bottom-color: #c07fff;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #c07fff;
  font-weight: bold;
}

.PublicClientRequirementList_sortOptionSelected__9VkW5:hover {
  cursor: pointer;
}

.PublicClientRequirementList_pageNumbersContainer__1qkcu {
  display: flex;
  margin-right: 10%;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.PublicClientRequirementList_pageNumbersContainerBottom__3q2Ww {
  display: flex;
  margin-right: 10%;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  justify-content: end;
  margin-top: 3%;
  margin-bottom: 3%;
}

.PublicClientRequirementList_pageCount__mGO2Y {
  background-color: #1778f2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 28px;
  margin: 2px 6px;
  color: #fff;
  font-size: 14px;
  height: 28px;
}
.PublicClientRequirementList_searchBarContainer__WvFAn {
  display: flex;
  justify-content: center;

  width: 100%;
}
.PublicClientRequirementList_searchIcon__jOmh6 {
  padding: 10px 9px;
  height: 22px;
  background-color: #1678f2;
  color: white;
  margin-top: 13px;
  display: flex;
  align-items: center;
}

.PublicClientRequirementList_searchIcon__jOmh6:hover {
  cursor: pointer;
  background-color: #0c58b4;
}

.PublicClientRequirementList_searchInput__ROFTG {
  padding: 3px 0px;
  width: 50%;
}
.PublicClientRequirementList_searchInput__ROFTG:focus {
  outline: none;

  border: 1px solid #1678f2;
  border-right-width: 0px;
}
.PublicClientRequirementList_searchInput__ROFTG::-webkit-input-placeholder {
  font-size: 16px;
  color: rgb(197, 197, 197);
}
.PublicClientRequirementList_searchInput__ROFTG:-ms-input-placeholder {
  font-size: 16px;
  color: rgb(197, 197, 197);
}
.PublicClientRequirementList_searchInput__ROFTG::placeholder {
  font-size: 16px;
  color: rgb(197, 197, 197);
}

.PublicClientRequirementList_borderLine__xDonA {
  border-bottom: 1px solid #dedede;
  width: 40%;
}

.PublicClientRequirementList_buttons1__3CzYG {
  display: flex;
}
.PublicClientRequirementList_pageCountIcon__1gusn {
  color: #1778f2;
  margin: 2px 6px;
}
.PublicClientRequirementList_pageCountIcon__1gusn:hover {
  cursor: pointer;
}
.PublicClientRequirementList_PaginationContainer__3Vtcc {
  padding: 22px 0px;
  text-align: center;
  
}
.PublicClientRequirementList_buttonone__3Gxxn {
  color: #fff;
  background-color: #1778f2;
  padding: 8px 19px;
  border: none;
  margin-left: 4px;
  margin-right: 8px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}
.PublicClientRequirementList_CurrentPageText__2sBXE{
  border: 1px solid #1778f2;
  border-radius: 8px;
  padding: 3px 10px;
  width: 30px;
  margin-right: 5px;
  color: #1778f2;
  margin-left: 8px;
}
.PublicClientRequirementList_CurrentPageNext__1qs_3{
  border: 1px solid #1778f2;
  border-radius: 8px;
  padding: 3px 10px;
  width: 30px;
  margin-right: 5px;
  color: #1778f2;
  margin-left: 8px;
  cursor: pointer;
}
.PublicClientRequirementList_CurrentPageTextNext__h3csV{
  border: 1px solid gray;
  border-radius: 8px;
  padding: 3px 10px;
  width: 30px;
 cursor: pointer;
}

.PublicClientRequirementList_buttononePre__DAbVN{
 color: gray;
  font-size: 15px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 3px 14px; 
  margin-top: 6px;
  margin-left: 3px;
}

@media screen and (min-width: 768px) {
  .PublicClientRequirementList_pageLayout__3xph9 {
    padding: 15px;
    background-color: #f5f6fb;
  }
  .PublicClientRequirementList_PaginationContainer__3Vtcc {
    padding: 22px 22px 8px 22px;
    text-align: right;
  }
  .PublicClientRequirementList_searchInput__ROFTG {
    padding: 3px 0px;
    width: 100%;
  }
  .PublicClientRequirementList_sortAndPageContainer__1cnuI {
    display: flex;
    padding: 10px;
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .PublicClientRequirementList_pageNumbersContainer__1qkcu {
    justify-content: inherit;
    margin-top: 0;
  }
  .PublicClientRequirementList_sortContainer__113nI {
    display: grid;
    justify-content: inherit;
  }
}

.PublicRecRequirementCard_mainContainer__27Y5I {
  background-color: white;
}

.PublicRecRequirementCard_mainCardContainer__2y5pL {
  background-color: white;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);

  margin-top: 20px;

  border-radius: 5px;
}
.PublicRecRequirementCard_imagecontainer__1ZQ8t {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.PublicRecRequirementCard_workType__2X7NC {
  color: indigo;
  font-weight: bolder;

  margin-left: 10px;
}
.PublicRecRequirementCard_tagFeatured__25pP- {
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;
  margin-left: 6px;
  margin-top: 8px;
}
.PublicRecRequirementCard_featuredListing__2-Olm {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.PublicRecRequirementCard_addonNameIcon__1Xxhw {
  margin-right: 5px;
 
}
.PublicRecRequirementCard_AddonName__TFQA7 {
  font-size: 15px;
  color: #7c7c7c;
}
.PublicRecRequirementCard_addonContainer__31nzn {
  display: flex;
  align-items: center;
}
.PublicRecRequirementCard_twoSectionDivider__2w2Wi {
  display: flex;
  flex-direction: column;
}

.PublicRecRequirementCard_skillContainer__1-GBd {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}

.PublicRecRequirementCard_titleArea__2rTo3 {
  margin-top: 10px;
}

.PublicRecRequirementCard_postType__grXdo {
  margin-left: 5px;
}
.PublicRecRequirementCard_skillTags__2j6sS {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  color: #707070;
  background-color: #f5f5f5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
}
.PublicRecRequirementCard_skilltagsContainer__6PeP7 {
  display: flex;
  flex-wrap: wrap;
}

.PublicRecRequirementCard_statusAndMenu__XjuVw {
  font-weight: bold;
  font-size: 20px;
}
.PublicRecRequirementCard_menuWithStatus__1YAH3 {
  text-align: left;
  width: 100%;
}
.PublicRecRequirementCard_dropDownStatus__BNWpd {
  align-self: flex-end;
}
.PublicRecRequirementCard_ViewBtnContainer__2tTqy {
  margin: 0px 7px;
}
.PublicRecRequirementCard_rightSection__2X2k5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2%;
}
.PublicRecRequirementCard_icondots__vc8no {
  margin-top: 6px;
}
.PublicRecRequirementCard_icondots__vc8no:hover {
  cursor: pointer;
}
.PublicRecRequirementCard_workPolicy__3VqFq {
  padding: 3px 7px;
  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.PublicRecRequirementCard_iconContainer__9tM_v {
  display: flex;
  margin-bottom: 5px;
}

.PublicRecRequirementCard_workingType__StAy3 {
  display: flex;
  align-items: center;
  margin-left: 2%;
}
.PublicRecRequirementCard_mobileMenuOption__3RVAX {
  display: flex;
}
.PublicRecRequirementCard_statusAnddate__2izxb {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  color: grey;
}
.PublicRecRequirementCard_highlightText__3BlDm {
  font-weight: bold;
  margin-top: 10px;
}

.PublicRecRequirementCard_Commission__2ZUfk {
  color: #fea11b;
}

.PublicRecRequirementCard_Contract__2G_KG {
  color: #486a31;
}
.PublicRecRequirementCard_One-Time__10WDc {
  color: #365aa9;
}
.PublicRecRequirementCard_Monthly__2oW-7 {
  color: #125552;
}
.PublicRecRequirementCard_requirementCardButton__10eCs {
  padding: 10px;
  border-width: 1px;
  border-color: #365aa9;
  border-style: solid;
  margin-top: 10px;
  color: #365aa9;
  font-size: 14px;
}
.PublicRecRequirementCard_requirementCardButton__10eCs:hover {
  cursor: pointer;
}
.PublicRecRequirementCard_buttonWithIconContainer__2UVC0 {
  display: flex;
  justify-content: end;
}
.PublicRecRequirementCard_colorBlue__3E6yB {
  color: #365aa9;
  font-weight: normal;
}
.PublicRecRequirementCard_workStatus__AZqhP {
  background-color: #d6ffe3;
  border-radius: 5px;
  padding: 5px;

  font-weight: bold;
  font-size: 14px;
  color: #43c962;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.PublicRecRequirementCard_postedDate__NWWs4 {
  font-style: italic;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
}
.PublicRecRequirementCard_NoRequirementCard__9pZEI {
  margin: 10px 12px;
}
.PublicRecRequirementCard_NameDiv__3_EYP {
  padding: 2px 0px 0px 0px;
}
.PublicRecRequirementCard_leftSectionWidth__2rkCb {
  width: 100%;
  margin: 0px 7px;
}
@media (min-width: 768px) {
  .PublicRecRequirementCard_mainContainer__27Y5I {
    padding: 15px;
    margin-left: 10px;
  }
  .PublicRecRequirementCard_mainCardContainer__2y5pL {
    margin-right: 10px;
    width: 80%;
  }
  .PublicRecRequirementCard_leftSectionWidth__2rkCb {
    width: 60%;
    margin: 2%;
  }

  .PublicRecRequirementCard_highlightText__3BlDm {
    margin-top: 0;
    justify-content: end;

    display: flex;
  }
  .PublicRecRequirementCard_twoSectionDivider__2w2Wi {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .PublicRecRequirementCard_menuWithStatus__1YAH3 {
    width: 40%;
  }
  .PublicRecRequirementCard_featuredListing__2-Olm {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .PublicRecRequirementCard_tagFeatured__25pP- {
    margin-top: 4px;
  }
  .PublicRecRequirementCard_ViewBtnContainer__2tTqy {
    margin: 0px;
    text-align: center;
  }
}

.NoApplication_mainContainer__1ydqE{
    background-color: white;
    margin: auto;
    text-align: center;
    padding: 20px;
}
.NoApplication_headingFont__26Ge_{
    font-size: 20px;
    font-weight: 800;
}
@media (min-width: 768px) {
    .NoApplication_contentWeb__2cfrG{
        width: 50%;
    margin: auto;

    }
}
.AutoSuggestionSkills_mainDiv__1bdDV {
  max-width: 800px;
  flex-direction: column;
  margin-bottom: 12px;
}
.AutoSuggestionSkills_input__1zQYr {
  width: 100%;
  height: 42px;
  margin-top: 5px;
  border: 1px solid #ededed;
  flex-direction: column;
  display: flex;
  padding: 12px;
  outline: none;
  font-size: 18px;
}

.AutoSuggestionSkills_primary__ipL8- {
  font-size: 12px;
  text-align: right;
  margin-right: 5px;
  font-weight: bold;
  font-style: italic;
}
.AutoSuggestionSkills_input__1zQYr {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
  border-radius: 20px;
}
.AutoSuggestionSkills_input__1zQYr:focus {
  border-color: #1778f2;
}

.AutoSuggestionSkills_suggestion__3bpcr {
  max-width: 493px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 3px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.AutoSuggestionSkills_suggestion__3bpcr p {
  padding: 6px;
  cursor: pointer;
  font-size: 15px;
  margin: 0;
}

.AutoSuggestionSkills_skillSuggestionList__2ZdEz {
  padding: 6px;
}

.AutoSuggestionSkills_skillSuggestionList__2ZdEz:hover {
  background-color: #ededed;
  cursor: pointer;
}
.AutoSuggestionSkills_dropdown_items_active__3XMjZ {
  background-color: #1678f2;
  color: #ffff;
}
p:hover {
  background-color: #ededed;
}
.AutoSuggestionSkills_icon__1nKKT {
  position: absolute;
  margin-left: 49vw;
  margin-top: 13px;
  align-items: flex-end;
}

#AutoSuggestionSkills_Search__3h_sp {
  padding-left: 48px;
  padding-top: 1px;
  font-size: 22px;
  color: black;
  background-repeat: no-repeat;
  background-position: center;
  outline: 0;
}

#AutoSuggestionSkills_Search__3h_sp::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: black;
}
.AutoSuggestionSkills_searchIcon__R6-ZE {
  position: absolute;
  top: 12px;
  left: 15px;
}
.AutoSuggestionSkills_highlighted__3ptDF {
  background: #ededed;
}
.AutoSuggestionSkills_tagMainDiv__lDFEf {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.AutoSuggestionSkills_suggestionTag__2GkNK {
  background-color: #1678f2;
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.AutoSuggestionSkills_suggestionTagFirst__1BJOG {
  background: linear-gradient(45deg, #4568dc, #b06ab3);
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.AutoSuggestionSkills_closeIcon__2QwFE {
  margin-top: 4px;
  align-items: center;
  margin-left: 6px;
  float: right;
}

@media only screen and (min-width: 1024px) {
  .AutoSuggestionSkills_mainDiv__1bdDV {
    flex-direction: column;

    margin: 0 0px;
  }
  .AutoSuggestionSkills_input__1zQYr {
    padding: 10px 10px;
    outline: none;
    border: 1px solid #ece9e9;
    margin: 10px 0px;
    font-size: 18px;
    border-radius: 0px;
  }
}

.ApplicationDetail_mainContainer__3-o8L {
  background-color: white;
  margin: 0px 10px 10px 10px;

  padding: 5px 20px 20px;
}

.ApplicationDetail_budgetContainer__1dEgE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ApplicationDetail_ApplicationDetail_image__1KXob {
  width: 30px;
  height: 30px;
}

.ApplicationDetail_budgetAmount__3r46A {
  font-size: 14px;
  color: #7c7c7c;
}
.ApplicationDetail_workType__82UU6 {
  color: #7c7c7c;
  text-align: center;
}
.ApplicationDetail_budgetHeading__3novF {
  text-align: center;
}
.ApplicationDetail_budgetAndWork__3zTEk {
  margin-left: 10px;
  text-align: center;
}

.ApplicationDetail_contentAlign__2iw5u {
  text-align: center;
  margin-top: 10px;
}
.ApplicationDetail_featuredListing__3WFE_ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
.ApplicationDetail_tagFeatured__1C7bJ {
  margin-left: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;
}
.ApplicationDetail_error_message__2Wyh- {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.ApplicationDetail_sponsoredPost__2mM2p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ApplicationDetail_alignButton__2XGkH {
  text-align: right;
}

.ApplicationDetail_addonNameIcon__1YyeE {
  margin-right: 5px;
  width: 20px;
}
.ApplicationDetail_addonContainer__3UAJJ {
  display: flex;
}
.ApplicationDetail_AddonName__15f4L {
  font-size: 15px;
  color: #7c7c7c;
}

.ApplicationDetail_workTypeCommission__2dYmw {
  color: #fea11b;
}

.ApplicationDetail_workTypeContract__3nNhl {
  color: #486a31;
}
.ApplicationDetail_workTypeOneTime__3CYC4 {
  color: #365aa9;
}
.ApplicationDetail_workTypeMonthly__xawWU {
  color: #125552;
}

.ApplicationDetail_Loader__2VFOv {
  display: flex;
  justify-content: center;
  padding: 100px 0px;
}
.ApplicationDetail_AppliedCase__1917C {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ApplicationDetail_AppliedCaseChat__cz98w {
  font-size: 18px;
  color: #365aa9;
}
.ApplicationDetail_ClientRStatusText__3X-U2 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 4px;
  color: #365aa9;
  text-align: center;
  border: 1px solid #365aa9;
  padding: 3px 16px;
  font-style: italic;
  border-radius: 4px;
}
.ApplicationDetail_ClientRStatusTextR__3Zvua {
  font-size: 18px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 0px;
  color: rgb(250, 0, 0);
  text-align: center;
}
.ApplicationDetail_AccpetedProposal__244Js {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  flex-direction: row;
}
.ApplicationDetail_closePostStatus__1AiQJ {
  font-size: 18px;
  font-style: italic;
  margin-top: 12px;
  font-weight: 600;
  color: #bb2409;
  border: 1px solid #bb2409;
  padding: 2px 8px;
  border-radius: 4px;
  width: 76px;
  text-align: center;
}
.ApplicationDetail_closePostSection__20r1o {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 1023px) {
  .ApplicationDetail_budgetContainer__1dEgE {
    align-items: baseline;
    justify-content: end;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .ApplicationDetail_AccpetedProposal__244Js {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 12px;
    flex-direction: column;
  }
  .ApplicationDetail_workType__82UU6 {
    text-align: right;
  }
  .ApplicationDetail_ClientRStatusText__3X-U2 {
    text-align: right;
  }
  .ApplicationDetail_ClientRStatusTextR__3Zvua {
    text-align: right;
  }
  .ApplicationDetail_AppliedCase__1917C {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .ApplicationDetail_budgetAmount__3r46A {
    font-size: 30px;
    color: #000;
  }
  .ApplicationDetail_budgetHeading__3novF {
    text-align: end;
  }
  .ApplicationDetail_featuredListing__3WFE_ {
    justify-content: end;
  }
  .ApplicationDetail_sponsoredPost__2mM2p {
    justify-content: end;
  }
  .ApplicationDetail_contentAlign__2iw5u {
    text-align: right;
  }
  .ApplicationDetail_mainContainer__3-o8L {
    background-color: white;
    margin: 10px;
    margin-left: 0;
    padding: 5px 20px 20px;
  }
  .ApplicationDetail_workType__82UU6 {
    font-weight: bold;
    color: #7c7c7c;
    text-align: center;
  }
  .ApplicationDetail_workType__82UU6 {
    color: #496a32;
    text-align: right;
  }
}

.CustomModalTheme_cancelModel__39080 {
  text-align: end;
}

.CustomModalTheme_modalContainer__2JHrT {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}

.CustomModalTheme_modal__2hPRf {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 525px;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 2;
  padding-bottom: 30px;
}
.CustomModalTheme_backIcon__2pWIS {
  cursor: pointer;
  height: 25px;
}
.CustomModalTheme_button__1vRj6 {
  width: 70px;
  color: #1778f2;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.CustomModalTheme_mainContainer__8GYnB {
  padding: 0px 30px;
}
@media screen and (max-width: 1023px) {
  .CustomModalTheme_modal__2hPRf {
    width: 80%;
  }
}

.RequirementDetailArea_mainContainer__15g11 {
  background-color: white;
  margin: 10px 10px 0px 10px;
  padding: 10px;
}

.RequirementDetailArea_title__RsMdK {
  font-size: 20px;
  font-weight: bold;
}
.RequirementDetailArea_addonNameIcon__1_or8 {
  margin-right: 5px;
  width: 15px;
}
.RequirementDetailArea_AddonName__-gnX0 {
  font-size: 12px;
  color: #7c7c7c;
}
.RequirementDetailArea_dateAndPostType__1YBOa {
  color: grey;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  padding-bottom: 5px;
}
.RequirementDetailArea_dateAndPostTypeSection__1NZjB {
  margin-top: 10px;
}
.RequirementDetailArea_LocationSection__1jv1B {
  display: flex;
  align-items: center;
}
.RequirementDetailArea_contentIndetail__3o6O8 {
  margin-top: 20px;
  font-size: 14px;
}
.RequirementDetailArea_logoPlacement__1z6bN {
  margin-top: 20px;
}
.RequirementDetailArea_riLogo__3-dwg {
  width: 50px;
  margin-right: 10px;
}
.RequirementDetailArea_LocationText__t22GR {
  font-size: 15px;
  color: gray;
}
.RequirementDetailArea_CompanyLogowithText__2osL6 {
  display: flex;
  align-items: center;
}
.RequirementDetailArea_Comapany_Name__37tiV {
  padding-top: 10px;
}
.RequirementDetailArea_skilltagsContainer__Wqrr8 {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}
.RequirementDetailArea_CompanyLogo__2mXGy {
  max-width: 120px;
  max-height: 300px;
}
.RequirementDetailArea_skillTags__W-uOS {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  font-size: 14px;
}

.RequirementDetailArea_location__3CWi- {
  margin-top: 20px;
}
.RequirementDetailArea_tagFeatured__1JYaM {
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;
  margin-right: 8px;
}
.RequirementDetailArea_featuredListing__1loac {
  display: flex;
  align-items: center;
  margin: 3px 0px 12px 0px;
}
.RequirementDetailArea_addonContainer__2vrM0 {
  display: flex;
}
.RequirementDetailArea_AccpetedProposal__ZQcWY{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 12px; */
  /* flex-direction: column; */
}
.RequirementDetailArea_ClientRStatusText__1OxiU{
  font-size: 18px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 4px;
  color: #365aa9;
  text-align: center;
  border: none;
  padding: 3px 16px;
  font-style: italic;
  border-radius: 4px;
}
.RequirementDetailArea_ClientRStatusTextR__2yAw6{
  font-size: 18px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 0px;
  color: rgb(250, 0, 0);
  text-align: center;
}
.RequirementDetailArea_AppliedCase__2MJN7{
  display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.RequirementDetailArea_AppliedCaseChat__28Xeh{
  font-size: 18px;
  color: #365aa9;
}
.RequirementDetailArea_error_message__3yBeK {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.RequirementDetailArea_Loader__Mu_yA{
  display: flex;
  justify-content: center;
  padding: 100px 0px;
}
.RequirementDetailArea_timeApplyBtn__2NU0e{
display: flex;
justify-content: space-between;
align-items: center;
}
.RequirementDetailArea_alignButton__3RyTr{
  display: block;
}
.RequirementDetailArea_closePostStatus__2rfTu{
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  color: #bb2409;
  padding-right: 10px;
  border: 1px solid #bb2409;
  padding: 2px 8px;
  border-radius: 2px;
}
@media (min-width: 1023px) {
  .RequirementDetailArea_skillTags__W-uOS {
    margin: 5px;
    font-size: 14px;
  }
  .RequirementDetailArea_mainContainer__15g11 {
    background-color: white;
    margin: 10px;
    padding: 20px;
    min-height: 80%;
  }
  .RequirementDetailArea_title__RsMdK {
    width: 70%;
  }
  .RequirementDetailArea_featuredListing__1loac {
    display: none;
  }
  .RequirementDetailArea_alignButton__3RyTr{
    display: none;
  }
}

.RequirementPageHeader_mainContainer__1ivoR {
  padding: 20px;
  background-color: #315add;

  color: white;
}
.RequirementPageHeader_commission__3ogYr {
  background-color: #fea11b;
}

.RequirementPageHeader_contract__2A6-5 {
  background-color: #486a31;
}
.RequirementPageHeader_oneTime__2QW1x {
  background-color: #365aa9;
}
.RequirementPageHeader_monthly__A0Aaa {
  background-color: #125552;
}

.RequirementPageHeader_workingPolicy__19J9z {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}
.RequirementPageHeader_mainSkill__3NLgB {
  font-size: 30px;
  font-weight: bolder;
  margin-left: 10px;
}

.RequirementPageHeader_skillAndStatus__i9uY5 {
  display: flex;
  justify-content: space-between;
}
.RequirementPageHeader_backArrow__2Jp54 {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
}
.RequirementPageHeader_arrowAndMenuOption__3jR5i {
  display: flex;
  justify-content: space-between;
}
.RequirementPageHeader_menuOption__1fwBQ {
  display: flex;
}

.RequirementPageHeader_menuButton__FsJMw {
  padding: 3px 5px;
  border: 1px solid white;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 3px;
}
.RequirementPageHeader_menuButton__FsJMw:hover {
  background-color: white;
  cursor: pointer;
  color: black;
}
.RequirementPageHeader_contractGreen__BLZEc {
  color: #caffa6;
}
.RequirementPageHeader_OneTimeBlue__2ZXkJ {
  color: #d5dfff;
}
.RequirementPageHeader_monthlyGrey__rbwK8 {
  color: #85fffa;
}
.RequirementPageHeader_comYellow__13sc5 {
  color: #4c2c00;
}

.RegisterModal_backdrop__1DjZe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .RegisterModal_modal__2NaWm {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    background-color: #FCD017;
    color: black;
    position: relative;
    font-weight: bold;
    top: 12vh;
    height: 80px;
    width: 250px;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 30;
    align-items: center;
  }
  .RegisterModal_button__53mOH {
    width: 70px;
    color: black;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  @media (min-width: 768px) {
    .RegisterModal_modal__2NaWm {
      width: 20rem;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
    }
  }
  
.PublicClientRequirementDetail_pageLayout__3vblA {
  background-color: #f5f6fb;
}
.PublicClientRequirementDetail_pageColumn__jMX8q {
  display: flex;
  justify-content: center;
}
.PublicClientRequirementDetail_leftArea__2tCok {
  width: 70%;
}
.PublicClientRequirementDetail_rightArea__2ZgzL {
  width: 30%;
}
.PublicClientRequirementDetail_pageLoader__3t1ot{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SharePost_ShareIcon__15uq4 {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.SharePost_shareIconContainer__2BIt9 {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
}
.SharePost_iconColor__22yx7 {
  color: white;
  height: 24px;
  width: 24px;
}
.SharePost_iconBg__2t-n5 {
  border-radius: 50%;
  padding: 20px;
  margin: 14px;
}
.SharePost_Email__E7GGp {
  background-color: #7f7f7f;
}

.SharePost_LinkedIn__1PJfy {
  background-color: #007fb1;
}
.SharePost_Whatsapp__2hAzh {
  background-color: #25d366;
}
.SharePost_Facebook__19EDT {
  background-color: #007bff;
}
.SharePost_Twitter__3qBTO {
  background-color: #00aced;
}
.SharePost_textInputLabel__2EKGU {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.SharePost_copyIcon__2VG9P {
  border: none;
  outline: none;
  cursor: pointer;
}
.SharePost_textInput__Rm9Y6 {
  border: none;
  width: 100%;
  outline: none;
}
.SharePost_error_message__2YUJR {
  color: #007bff;
  position: absolute;
  padding-top: 50px;
  width: 80%;
  text-align: right;
}



  .NewHeadingRI_mainHeadingContainer__RrYta{
    font-size: 40px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    text-align: center;
  } 
  
   .NewHeadingRI_thirdLine__1Ga3a{
    color: #252525;
    font-size: 20px;
padding-top:5px;
   
  } 
   .NewHeadingRI_thirdLineWhite__Sy7rd{
    color: #fff;
    font-size: 20px;
   
  } 
  
  .NewHeadingRI_headingTwo__2eJOG{
    margin-top: -10px;
    line-height: 1.2;
    
  
  }
  @media screen and (min-width: 1023px) {
    .NewHeadingRI_mainHeadingContainer__RrYta{
      font-size: 60px;
      font-weight: 500;
      font-family: 'Manrope', sans-serif;
    }
 
  }
.NewRequirementCard_mainContainer__8SqT_ {
  background-color: white;
}

.NewRequirementCard_headingFromProps__3iNF9 {
  padding: 20px;
  font-size: 25px;
  font-weight: 800;
}
.NewRequirementCard_noRequirement___9bQK {
  padding-left: 20px;
}
.NewRequirementCard_pageLoader__iyd2k {
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NewRequirementCard_mainCardContainer__3hM4- {
  width: 90%;

  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.NewRequirementCard_imagecontainer__3rsTH {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.NewRequirementCard_workType__2R1M6 {
  color: indigo;
  font-weight: bolder;

  margin-left: 10px;
}



.NewRequirementCard_skillImageContainer__2cWPF {
  display: flex;
  height: 40%;
}
.NewRequirementCard_skillContainer__22FrF {
  font-size: 18px;
  font-weight: bold;
}

.NewRequirementCard_titleArea__GSTi1 {
  margin-top: 10px;
  color: grey;
}
.NewRequirementCard_workStatus__1rIb1 {
  background-color: #d6ffe3;
  border-radius: 5px;
  padding: 5px;

  font-weight: bold;
  font-size: 14px;
  color: #43c962;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.NewRequirementCard_actionContainer__11jn6 {
  height: 100px;
}
.NewRequirementCard_ActionButton__3bnG8 {
  text-align: right;

}
.NewRequirementCard_PARBtn_Link__lTVu4 {
  text-decoration: none;
}
.NewRequirementCard_postedDate__1gwCX {
  font-style: italic;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
}
.NewRequirementCard_budget__2NMTU {
  font-weight: bold;
  font-size: 22px;
}

.NewRequirementCard_iconAndButtonContainer___91xa {
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
}

.NewRequirementCard_workPolicy__QppLW {
  padding: 3px;
  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.NewRequirementCard_workPolicyAndWorkType__V83DB {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.NewRequirementCard_statusAnddate__1f_ih {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.NewRequirementCard_Commission__3gDww {
  color: #fea11b;
}

.NewRequirementCard_Contract__3-I5S {
  color: #486a31;
}
.NewRequirementCard_One-Time__2SQfV {
  color: #365aa9;
}
.NewRequirementCard_Monthly__3zZDY {
  color: #125552;
}

.NewRequirementCard_knowMore__3y7Av {
  text-align: right;
  color: #9398a1;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;

  align-items: center;
  align-content: center;
  justify-content: flex-end;
  display: flex;
}

.NewRequirementCard_knowMore__3y7Av:hover {
  cursor: pointer;
  color: #1778f2;
}

.NewRequirementCard_iconArrow__3QSOq {
  margin-top: 3px;
}

.NewRequirementCard_heartIcon__ALCK2 {
  cursor: pointer;
}
.NewRequirementCard_mainCardContainer__3hM4-:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.NewRequirementCard_cardHeightSetting__RG4rA {
  cursor: pointer;
}
.NewRequirementCard_budgetUnit__INX92 {
  font-size: 14px;
  font-weight: 500;
}
@media screen and (min-width: 1023px) {
  .NewRequirementCard_webLayout__2nv__ {
    display: flex;
    padding-left: 30px;
    flex-wrap: wrap;
    padding-right: 30px;
  }
  .NewRequirementCard_mainContainer__8SqT_ {
    padding: 0px;
    margin: 10px;
  }
  .NewRequirementCard_mainCardContainer__3hM4- {
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px;
    width: 40%;
  }
  .NewRequirementCard_cardHeightSetting__RG4rA {
    height: 250px;
  }

  .NewRequirementCard_knowMore__3y7Av {
    margin-right: 3%;
    margin-bottom: 20px;
  }
}

.CreateProposal_mainDiv__2A4hI {
  background-color: #f8f8f8;
}

.CreateProposal_radioSubLabel__qtRZr {
  text-align: left;
  font-size: 15px;
  color: grey;
}

.CreateProposal_newLabel__UnU-T {
  margin-left: 28px;
  font-weight: 700;
  font-size: 17px;
  margin-top: 23px;
}

.CreateProposal_finalAmount__13uwc {
  margin-left: 10px;
}
.CreateProposal_addonNameIcon__2x_L6 {
  margin-right: 5px;
  width: 20px;
}
.CreateProposal_addonContainer__1VHyj {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.CreateProposal_AddonName__YmwLx {
  font-size: 15px;
  color: #7c7c7c;
}

.CreateProposal_mainHeading__1Ach5 {
  font-weight: bolder;
  font-size: 20px;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.CreateProposal_mainHeadingInner__KDAHw {
  font-weight: 900;
}

.CreateProposal_formDiv__1fbIM {
  padding: 20px;
  margin-top: 10px;
  background-color: #ffffff;
}
.CreateProposal_units__MoN6f {
 font-size: 18px;
  font-weight: 600;
}

.CreateProposal_riInputs__3iDG6 {

  margin-left: 28px;

}
.CreateProposal_clientsBudgetDiv__PBH6K {
  display: flex;
  align-items: center;
}

.CreateProposal_tipDiv__2R4ux {
  border: 1px solid gray;
  margin: 20px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
}

.CreateProposal_mainSubHeading__2Wqkm {
  font-size: 15px;
  color: grey;
}
.CreateProposal_tipTextMainDiv__ZLOLG {
  display: flex;
}
.CreateProposal_clientsBudget__JcVL5 {
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 14px;
}
.CreateProposal_subHeading__3JuO4 {
  font-weight: bolder;
  font-size: 20px;
}
.CreateProposal_type__klZcg {
  font-weight: 700;
  margin-left: 5px;
}
.CreateProposal_addOnDiv__1g0Ww {
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.CreateProposal_duration__X8UVA {
  display: flex;
  align-items: center;
  font-weight: 500;
    font-size: 14px;
    font-style: italic;
color: grey;
}
.CreateProposal_durationIcon__1-rcc {
  margin-right: 5px;
  margin-top: 5px;
}
.CreateProposal_descriptionContainer__ibguS {
  margin-top: 40px;
  width: 100%;
}
.CreateProposal_tipIcon__2xaCm {
  color: goldenrod;
}
.CreateProposal_tipText__1o12J {
  font-size: 14px;
  color: grey;
  margin-bottom: 10px;
}
.CreateProposal_tipsHeading__1L7-- {
  font-size: 20px;
  color: grey;
  margin-bottom: 10px;
}
.CreateProposal_totalAmountHeading__1Nret {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 800;
  margin-top: 14px;
  margin-left: 4px;
  margin-bottom: 3px;
}
.CreateProposal_error_message__1b2Ud {
  color: rgb(250, 0, 0);
  font-size: 12px;
  margin-left: 26px;
  margin-top: 5px;
}
.CreateProposal_agreementDiv__20CSN {
  display: flex;
  
  margin-top: 25px;
}

.CreateProposal_agreementText__3J-VF {
  margin-left: 10px;
}

.CreateProposal_freelancerUnit__kaeFW {
  font-weight: 600;
  color: grey;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 7px;
}

.CreateProposal_agreementTextBlue__3hqcu {
  color: #1778f2;
  font-weight: bolder;
  margin-left: 3px;
}
.CreateProposal_decisionButtonsDiv__3qL7G {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 26px;
}
.CreateProposal_chat__3-ZNK {
  font-size: 18px;
  margin-right: 3px;
  color: #1778f2;
  font-weight: 700;
}
.CreateProposal_closeForm__16nGv {
  font-size: 9px;
  font-weight: 700;
  cursor: pointer;
}
.CreateProposal_cancel__2TCUY {
  font-size: 12px;
  margin-right: 15px;
  margin-left: 15px;
}
.CreateProposal_mainInputContainer__uNuQM {
  margin-top: 17px;
}

.CreateProposal_closeIcon__3e6-T {
  text-align: end;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.CreateProposal_addonNameIcon__2x_L6 {
  margin-right: 5px;
}
.CreateProposal_ClientBudgetText__3aw_e{
  display: flex;
  
  margin: 14px 0px 0px 0px;
  
}
.CreateProposal_ClientBudgetHeading__1_36w{
  font-size: 18px;
  font-weight: 600;
  margin: 0px 6px 0px 0px;
}
.CreateProposal_ClientBudgetSubText__2nV1O{
  font-size: 18px;
  font-weight: 600;
  margin: 12px 20px 0px 0px;
  width: 220px;
}
.CreateProposal_ErrorMessage__d_Hta{
  color: crimson;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
}
.CreateProposal_buttonBoxsmall__15oLB {
  background-color: #fcd017;
color: #1778f2;
  border-radius: 6px;
text-align: center;
  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 100px;
}


@media (min-width: 1023px) {
  .CreateProposal_mainHeadingDiv__jGHTv {
    padding-top: 8px;
  }
  .CreateProposal_ClientBudgetSubText__2nV1O{
    
    width: 145px;
  }
  .CreateProposal_addOnsMain__OQxmd {
    display: flex;
    align-items: center;
  }
  .CreateProposal_addOnDiv__1g0Ww {
    display: flex;
  }
  .CreateProposal_mainSubHeading__2Wqkm {
    font-size: 16px;
    color: grey;
  }
  .CreateProposal_radioSubLabel__qtRZr {
    text-align: left;
    font-size: 15px;
    color: grey;
  }

  .CreateProposal_closeForm__16nGv {
    font-size: 12px;
    font-weight: 700;
  }
  .CreateProposal_riInputs__3iDG6 {

    display: flex;
    align-items: center;
    margin-top: 15px;

  }

  .CreateProposal_freelancerUnit__kaeFW {
    font-weight: 600;
    margin-left: 20px;
    margin-top: 7px;
    font-size: 16px;
  }

  .CreateProposal_mainHeading__1Ach5 {
    font-weight: 900;
    font-size: 30px;
    padding-left: 50px;
    background-color: #ffffff;
  }
  .CreateProposal_mainHeadingInner__KDAHw {
    font-weight: 900;
  }
  .CreateProposal_clientsBudget__JcVL5 {
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
    margin-top: 14px;
  }
  .CreateProposal_tipDiv__2R4ux {
    border: 1px solid gray;
    padding: 20px;
    width: 24%;
    height: 0%;
    text-align: left;
    border-radius: 10px;
  }

  .CreateProposal_tipTextMainDiv__ZLOLG {
    display: flex;
  }
  .CreateProposal_formDiv__1fbIM {
    padding:30px 50px;
    margin-top: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }
  .CreateProposal_subHeading__3JuO4 {
    font-weight: 800;
    font-size: 30px;
  }
  .CreateProposal_addonNameIcon__2x_L6 {
    
    margin-right: 8px;
  }
  .CreateProposal_duration__X8UVA {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 5px;
  }
  .CreateProposal_proposalDetailsHeading__1m5JL {
    font-weight: 700;
    margin-top: 26px;
    font-size: 24px;
  }
  .CreateProposal_multilineInput__2lxjg::-webkit-input-placeholder {
    font-size: 16px;
    padding: 10px;
  }
  .CreateProposal_multilineInput__2lxjg:-ms-input-placeholder {
    font-size: 16px;
    padding: 10px;
  }
  .CreateProposal_multilineInput__2lxjg::placeholder {
    font-size: 16px;
    padding: 10px;
  }
  .CreateProposal_amountDuration__12j19 {
    font-size: 14px;
    font-weight: bolder;
  }
  .CreateProposal_totalAmountHeading__1Nret {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 700;
    margin-top: 20px;
  }
  .CreateProposal_inputContainer__2eFhF {
    margin-top: 20px;
  }
  .CreateProposal_addOn__1sCCw {
    margin-left: 10px;
  }
  .CreateProposal_addOnCheckText__nX0GF {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 800;
  }
  .CreateProposal_cancel__2TCUY {
    font-size: 16px;
    cursor: pointer;
  }
  .CreateProposal_addOnSubText__3OKh- {
    font-size: 16px;
  }

  .CreateProposal_descriptionContainer__ibguS {
    margin-left: 4px;
  }
}

.RI_Modal_modalContainer__19Kdo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.RI_Modal_modal__2Feix {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;

  padding: 8px 9px 23px 9px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}

.RI_Modal_ActionBtn__2wB8w {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 30px;
}

@-webkit-keyframes RI_Modal_slide-down__3rbv6 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes RI_Modal_slide-down__3rbv6 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.RI_Modal_ModalCheckIcon__3ZbIo {
  text-align: center;
}
.RI_Modal_ModalCheckTextFirst__3QfYq {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
}
.RI_Modal_ModalCheckTextSecond__rtPGt {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
  font-weight: bold;
}
.RI_Modal_modalcheck__AAM2g {
  width: 25%;
}
.RI_Modal_modalthird__2tsJx {
  width: 30%;
  height: 30px;
}
.RI_Modal_crossicon__30u0Q {
  text-align: end;
}

@media screen and (max-width: 768px) {
  .RI_Modal_modal__2Feix {
    margin: 0px 20px;
    height: 200px;
  }
}

.FrequentlyAskedQuestions_faq_head__33Thj {
  background: #fff;
  padding: 30px;
}
.FrequentlyAskedQuestions_faq_padding__3br-y {
  padding: 40px 0;
}
.FrequentlyAskedQuestions_faq_firstheading__nfCB7 {
  font-size: 30px;
  font-family: "Poppins";
}
.FrequentlyAskedQuestions_faq_secondheading__2OJKy {
  color: #5d5d5d;
  font-size: 16px;
  padding-top: 15px;
  font-family: "Manrope";
}
.FrequentlyAskedQuestions_faq_ActionButton__19w4n {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .FrequentlyAskedQuestions_faq_question_mainhead__38JM4 {
    background-color: #f5f5f5;
    padding: 40px 100px;
  }
  .FrequentlyAskedQuestions_faq_secondheading__2OJKy {
    color: #5d5d5d;
    font-size: 20px;
    font-family: "Manrope";
  }
  .FrequentlyAskedQuestions_faq_firstheading__nfCB7 {
    font-size: 40px;
    font-family: "Poppins";
  }
}

.MyApplications_pageLayout__2I8NV {
  padding: 15px;
}
.MyApplications_sortAndRequirmentContainer__1Tnt- {
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 10px;
}
.MyApplications_headingforApplication__2QQUy{
  font-weight: bold;
  font-size: 20px;

}

.MyApplications_sortAndPageContainer__3Da6F {
  justify-content: center;
}
.MyApplications_sortContainer__EPgv8 {
  display: flex;
}
.MyApplications_sortHeading__16L1p{
    font-weight: bold;
}

.MyApplications_sortHeading__16L1p:hover{
    cursor: pointer;
}

.MyApplications_sortOptions__eS_aW{
    color: blue;
   
    margin-left: 20px;
}

.MyApplications_sortOptions__eS_aW:hover{
    cursor: pointer;
}

.MyApplications_pageNumbersContainer__3zYZe{
 display: flex;
margin-right: 10%;
font-weight: bold;
font-size: 20px;
align-items: center;
justify-content: center;
margin-top: 5%;

}

.MyApplications_pageCount__3phlt{
    margin-left: 20px;
}
.MyApplications_pageCount__3phlt:hover{
    cursor: pointer;

}

@media (max-width: 768px) {
  .MyApplications_pageLayout__2I8NV {
    padding: 15px;
    background-color: #f5f6fb;
  }

  .MyApplications_sortAndPageContainer__3Da6F {
    display: flex;
    padding: 10px;
    background-color: white;
    justify-content: space-between;
    margin-left: 10px;
  }
  .MyApplications_pageNumbersContainer__3zYZe {
    justify-content: inherit;
    margin-top: 0;
  }
}

.FreelancerMembership_packageHeading__31h1F {
  text-align: center;
  margin-top: 25px;
  font-weight: 800;
  font-size: 25px;
}

.FreelancerMembership_planSelection__1cIXL {
  border: 1px solid black;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}
.FreelancerMembership_planMainDiv__3i7qF {
  display: flex;
  overflow: scroll;
  margin-bottom: 10px;
}

.FreelancerMembership_planMainDiv__3i7qF::-webkit-scrollbar {
  display: none;
}
.FreelancerMembership_active__3WDTH {
  background-color: #4cb3be;
  color: #ffff;
  border: 1px solid #4cb3be;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}
.FreelancerMembership_selectButtonDiv__1RChD {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.FreelancerMembership_selectButton__AT_6Y {
  padding: 9px;
  width: 150px;
  border: #f6ca48;
  border-radius: 5px;
  background-color: #f6ca48;
  font-weight: 700;
}

.FreelancerMembership_currentPlanMainDiv__1Dp9s {
  margin: 10px;
  border: 1px solid #7070702e;
  border-radius: 5px;
}
.FreelancerMembership_currentPlanDiv__1pXsW {
  display: flex;
  justify-content: space-between;
}
.FreelancerMembership_currentPlanHeading__2IBSs {
  font-size: 18px;
  padding: 15px;
}
.FreelancerMembership_currentPlanSubHeading__3ibpa {
  font-size: 15px;
  font-weight: 900;
  padding: 15px;
}
.FreelancerMembership_planDurationDiv__3OVkH {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #7070702e;
}
.FreelancerMembership_planDurationMainDiv__159UU {
  margin-top: 20px;
}

.FreelancerMembership_planDuration__2lQyk {
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
}
.FreelancerMembership_packDetailsHeading__2kOUx {
  padding: 2px 15px;
  font-size: 15px;
  font-weight: 900;
}

.PackageDetail_mainDiv__a_pgE{
    margin-top: 20px;
    border: 1px solid #7070702E;
    border-bottom: none;
    background-color: #FFFEF8;
    padding: 9px 0px;
    margin: 10px;
}
.PackageDetail_planMainDiv__3Vbbd{
    display: flex;
    justify-content: center;
    align-items: center;
}
.PackageDetail_dataDiv__1r6-b{
    display: flex;
}

.PackageDetail_mainMapDiv__2Cyjs{
    width: 30%;
    background-color: #4CB3BE
}

.PackageDetail_headingMainDiv__2tP06{
    width: 70%;
}

.PackageDetail_planType__2obgd{
    display: flex;
    justify-content: flex-end;
    width: 92%;
    color: #4CB3BE;
    font-weight: 700;
    margin-bottom: 10px;    
}

.PackageDetail_planHeading__qdffT{
 padding: 9px 6px;
font-size: 11px;
border-bottom: 1px solid #7070702E ; 
}

.PackageDetail_planDetailsDiv__teN7G{ 
    text-align: center;
    padding: 6px;
    margin: 0px;
    color: #FFFFFF;  
    background-color: #4CB3BE
}


.FreelancerMemberShipWeb_packageHeading__2MgZe {
  text-align: center;
  margin-top: 25px;
  font-weight: 800;
  font-size: 45px;
}
.FreelancerMemberShipWeb_planDiv__L2fMY {
  margin-top: 50px;
  margin-left: 100px;
  width: 79%;
  border: 1px solid #7070702e;
}
.FreelancerMemberShipWeb_plans__2jw6E {
  display: flex;
  width: 100%;
}

.FreelancerMemberShipWeb_planType__2mwuv {
  width: 21%;
  background-color: #ffffff;
  padding: 10px;

  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}

.FreelancerMemberShipWeb_oneSection__28QyC {
  background-color: #f6ca48;
}
.FreelancerMemberShipWeb_activePlanType__3iktT {
  width: 21%;
  background-color: #4cb3be;
  font-weight: bold;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}

.FreelancerMemberShipWeb_planHeadingMain__2Jw4D {
  background-color: #ffffff;
  padding: 9px;
  font-weight: bolder;
  font-size: 18px;
  width: 40%;
}

.FreelancerMemberShipWeb_planSelectionDiv__1lS-7 {
  display: flex;
  width: 79%;
  margin-left: 100px;
  border: 1px solid #7070702e;
}

.FreelancerMemberShipWeb_planDetails__3eG5z {
  padding: 10px;
  border-bottom: 1px solid #7070702e;
}

.FreelancerMemberShipWeb_planDetailsButtonDiv__2fs5y {
  padding: 10px;
  border-top: none;
  border-radius: 5px;
}

.FreelancerMemberShipWeb_activePlanDetails__3cJoK {
  padding: 10px;
  border-bottom: 1px solid #7070702e;
  background-color: #4cb3be;
  color: #ffffff;
}

.FreelancerMemberShipWeb_planDetailsHeadingMainDiv__2NRxX {
  width: 38%;
}
.FreelancerMemberShipWeb_conatiner__1HeHW {
  width: 21%;
  text-align: center;
  cursor: pointer;
}
.FreelancerMemberShipWeb_planHeading__2N-Yf {
  padding: 10px;
  border-bottom: 1px solid #7070702e;
}

.FreelancerMemberShipWeb_planConfirm__Ev62R {
  background-color: #4cb3be;
  border: 1px solid #4cb3be;
  color: #ffffff;
  padding: 5px;
}

.FreelancerMemberShipWeb_planPriceDiv__2yb_W {
  width: 300px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-right: 100px;
}

.FreelancerMemberShipWeb_totalPrice__3FFWU {
  margin-right: 10px;
  font-weight: 900;
}

.FreelancerMemberShipWeb_perYear__anBhN {
  font-size: 21px;
  font-weight: lighter;
}
.FreelancerMemberShipWeb_planPrices__352A7 {
  width: 25%;
  font-weight: 700;
  font-size: 32px;
  margin-left: 15px;
}
.FreelancerMemberShipWeb_active__1q7mf {
  background-color: #4cb3be;
  color: #ffff;
  border: 1px solid #4cb3be;
  width: 40%;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}
.FreelancerMemberShipWeb_selectButtonDiv__2MFXI {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.FreelancerMemberShipWeb_currentPlanMainDiv__11Aql {
  margin: 10px;
  border: 1px solid #7070702e;
  border-radius: 5px;
  margin-left: 100px;
  width: 34%;
}
.FreelancerMemberShipWeb_currentPlanDiv__q_aBA {
  display: flex;
  justify-content: space-between;
}
.FreelancerMemberShipWeb_currentPlanHeading__3laUv {
  padding: 11px;
}
.FreelancerMemberShipWeb_currentPlanSubHeading__1kUUV {
  padding: 11px;
}
.FreelancerMemberShipWeb_planDurationDiv__1gyvQ {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #7070702e;
}
.FreelancerMemberShipWeb_planDurationMainDiv__1xl9X {
  margin-top: 20px;
}

.FreelancerMemberShipWeb_planDuration__3YDiJ {
  font-weight: 500;
  padding: 6px 11px;
}
.FreelancerMemberShipWeb_upgradePlan__11H4C {
  margin: auto;
}

.FreelancerMemberShipWeb_upgradePlanMainDiv__1ypZL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}
.FreelancerMemberShipWeb_packDetailsHeading__3RxUe {
  padding: 0px 11px;
}
.FreelancerMemberShipWeb_selectButton__lm5xm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  width: 163px;
  border: #f6ca48;
  border-radius: 5px;
  background-color: #f6ca48;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .FreelancerMemberShipWeb_planHeading__2N-Yf:last-child {
    padding: 0 10px;
  }
  .FreelancerMemberShipWeb_planPriceDiv__2yb_W {
    width: 210px;
  }
  .FreelancerMemberShipWeb_planHeadingMain__2Jw4D {
    background-color: #ffffff;
    padding: 9px;
    font-weight: bolder;
    font-size: 18px;
    width: 41%;
  }
}

.KycBox_mainContainer__1lzNb{
    background-color: white;
    border-radius: 3px;
    border: 1px solid #c8c8c8;
    padding: 10px 20px;
    margin-top: 10px;
    height: 76px;

}
.KycBox_heading__3pqLV{
    margin: 5px 0;
    font-weight: 800;
    font-size: 25px;

}

.KycBox_arrowIcon__3KDPS{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  font-size: 15px;
}




.Package_Container__2YwU2 {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
 
  border-radius: 3px;
  border: 1px solid #c8c8c8;
  padding: 10px 20px;

}
.Package_priceWrapper__2mJK9 {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c8c8c8;
}
.Package_packageTitle__1EuX- {
  margin: 0;
  font-size: 25px;
  margin-bottom: -3px;
  font-weight: 900;
  
}

.Package_GetStarted__34B3l{
  text-decoration: none;
  font-size: 21px;
}
.Package_price__3CXZX {
  font-size: 25px;
  font-weight: bold;
}
.Package_perYear__I_KN9 {
  font-size: 12px;
}
.Package_planStartWrapper__1wOPL {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c8c8c8;
  margin-top: 1rem;
  padding-bottom: 5px;
}
.Package_buttonplan__3BoPf {
  background-color: #fdca51;
  border: none;
  outline: none;
  margin-top: 1rem;
  padding: 7px 18px;
  border-radius: 4px;
}
.Package_buttonplan__3BoPf > svg {
  margin-right: 4px;
}

.Package_creditCount__IGrN1{
  font-size: 25px;
}
.Package_creditLeft__18WHk{
  font-size: 14px;
}

@media screen and (min-width: 760px) {

  .Package_Container__2YwU2 {
    box-sizing: border-box;  
  }

}

.ProfileHealth_mainContainer__3ozhs {
    background-color: white;
    
    margin-top: 10px;
    padding: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
   
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
  .ProfileHealth_nameAndDetailArea__2MyNY {
    background: linear-gradient(45deg, #134e5e, #71b280);
    background-color: rgb(61, 119, 80);
    padding: 10px;
    color: white;
  }
  .ProfileHealth_infoArea__2quhR {
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(133 177 160);
    font-weight: bold;
    font-size: 14px;
    font-weight: 14px;
  }
  .ProfileHealth_infoAreaIcon__1s89v {
    margin-right: 10px;
  }
  .ProfileHealth_nameWithInitials__Wtcza {
    font-size: 20px;
    font-weight: bold;
  }
  .ProfileHealth_GetStarted__3bVKv{
    display: flex;
    justify-content: flex-end;
    color: #fff;
    font-size: 16px;
  }
  .ProfileHealth_memberShipContent__1_xCO {
    text-align: right;
    font-weight: bold;
  }
  .ProfileHealth_fullName__1ng2Z {
    font-size: 20px;
  }
  
  .ProfileHealth_headingContainer__2y2Xl {
    padding: 10px;
    margin-top: 10px;
  }
  .ProfileHealth_accontStatusHeading__1w1vT {
    font-weight: bold;
    font-weight: 14px;
  }
  .ProfileHealth_hireAssistant__2xU3d {
    display: grid;
  }
  
  .ProfileHealth_progressbar__3758F {
    width: 80%;
    margin-left: 10px;
    border-radius: 5px;
  
    background-color: #dcf0fa;
  }
  
  .ProfileHealth_profileTask__1u2Q2 {
    padding: 10px;
    background-color: rgb(243, 243, 243);
    color: #1678f2;
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .ProfileHealth_accountBalance__24mUv {
    font-weight: bold;
    text-align: center;
  }
  .ProfileHealth_accontStatussub__1Cvqj {
    font-size: 14px;
    color: gray;
  }
  

  
.ProfileProgress_profileProgressCard__3EdZ3 {
  border: 1px solid #c8c8c8;
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
}
.ProfileProgress_mainDiv__16ytB {
  display: flex;
  background-color: #fbfbf5;
}
.ProfileProgress_amountDiv__2XVfm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.ProfileProgress_amount__3iah_ {
  font-weight: 900;
  font-size: 40px;
}
.ProfileProgress_profileCompeletion__1c5pz {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
}
.ProfileProgress_myEarnings__1B1pr {
  font-weight: 800;
  font-size: 25px;
}

.ProfileProgress_arrowIconDiv__iOUR8 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fdca51;
  padding: 3px;
}
.ProfileProgress_profileCompleteButton__2m0xS {
  margin-top: 20px;
  background-color: #fdca51;
  border: 1px solid #fdca51;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .ProfileProgress_mainDiv__16ytB {
    flex-wrap: wrap;
  }
  .ProfileProgress_profileProgressCard__3EdZ3 {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
}

.MyApplicationSnippet_mainContainer__27iNT {
  background-color: #ffffff;
  height: -webkit-min-content;
  height: min-content;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.MyApplicationSnippet_mainHeading__3SlEd {
  font-weight: 800;
  padding: 10px;
  font-size: 25px;
}

@media (min-width: 768px) {
  .MyApplicationSnippet_mainContainer__27iNT {
    border: 1px solid #c8c8c8;
    padding: 20px;
  }
}

.MyProposals_mainContainer__1Mxyp{
    padding: 10px;
    background-color: white;
    
    margin-bottom: 10px;
   
    
}

.MyProposals_proposalBox__3uhvh{
padding: 10px;
}

.MyProposals_updateDate__y-Yhi{
    font-size: 12px;
    color: gray;
   
}

.MyProposals_rejectedColor__32jWb{
  
    color: #D0342C;
}

.MyProposals_accptedColor__ArhSa{
    color: #228B22;
}

.MyProposals_mainHeading__2xaTs{
    font-weight: 800;
    padding: 10px;
    font-size: 25px;
}

.MyProposals_noProposalHeading__1cyJk{
    padding: 10px;
}

.MyProposals_proposalAmount__Qx_J1{
   
    font-weight: 800;

}
.MyProposals_chatIcon__8L_h9{
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: end;
}
.MyProposals_chattag__16drb{
    margin-left: 5px;
}

@media (min-width: 768px) {
    .MyProposals_mainContainer__1Mxyp {
       
        border: 1px solid #c8c8c8;
        padding: 20px;
        max-height: 500px;
        overflow: scroll;
        margin-top: 10px;
    }
    .MyProposals_proposalBox__3uhvh{
        justify-content: space-between;
    
  
    }
  
  }
.WorkPlace_welcomeMessage__3x7uX{
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.WorkPlace_firstWelcome__1gU83{
    font-size: 20px;

    display: flex;
}

.WorkPlace_snippetsCollection__3ZDCP{
    display: flex;
    
}
.WorkPlace_welcomeName__BmYrd{

    margin-left: 5px;
    font-weight: bold;
}
.WorkPlace_bannerContainer__lBJpC{
  margin-left: 10px;
    margin-right: 10px;
}
.WorkPlace_banner__1msNQ{
    
   margin-top: 10px;
}
.WorkPlace_noreqAndProfile__j7JB4{
    display: flex;
}


.WorkPlace_reqAndUpgradeOption__3rSK_{
    display: flex;
    align-items: center;
    width: 100%;
}
.WorkPlace_RequirementCard_Package__2HHKG{
    width: 60%;
}
.WorkPlace_PackageCard__N0GvE{
    width: 30%;
}
.WorkPlace_recReq__1x8lh{
  
    margin-top: 10px;
    margin-bottom: 10px;
}

.WorkPlace_leftArea__1awCJ{

width: 65%;
margin-right: 10px;
}

.WorkPlace_rightArea__X0yE4{
    width: 35%;
    margin-right: 10px;
}

.WorkPlace_pageDivider__3KbLR{
    display: flex;
}

@media (min-width: 768px) {
    .WorkPlace_welcomeMessage__3x7uX{
        margin-right: 10px;
    }

    .WorkPlace_bannerContainer__lBJpC{
        margin-left: 0px;
    }
   
    .WorkPlace_recReq__1x8lh{
        border: 1px solid #c8c8c8;
        background-color: white;
    }

    .WorkPlace_pageDivider__3KbLR{
        display: flex;
    }
  
}
.Notification_Notification_maindiv__2NQLP {
  width: 100%;
  background: #fff;
}
.Notification_Notification_flex__1VA6D {
  display: flex;
  font-size: 20px;
  padding: 30px 0px 5px 0px;
  border-bottom: 1px solid lightgrey;
}

.Notification_icon__Uk3L9 {
  margin-right: 20px;
  margin-top: 5px;
}
.Notification_Notification_heading__3VrD4 {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  background-color: white;
  margin: 10px 0px;


}
.Notification_Notification_container__1Dzes {
  width: 85%;
  margin: auto;
}
.Notification_Notification_time__3HDaZ {
  font-size: 12px;
  margin-bottom: 10px;
}
.Notification_blank_Notification_div__6QZ39 {
  text-align: center;
  padding-top: 35vh;
  height: 350px;
}
.Notification_blank_Notification_heading__1bWff {
  font-size: 20px;
  font-weight: bold;
}
.Notification_blank_Notification_text__2Hdge {
  font-size: 12px;
  margin: auto;
}

.Notification_blank_Notification_img__1mJ6R {
  width: 20%;
}
.Notification_notification_text__31a_k {
  font-size: 15px;
}
.Notification_notification_textbold__23TJ2 {
  font-size: 15px ;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .Notification_blank_Notification_img__1mJ6R {
    width: 10%;
  }
  .Notification_notification_text__31a_k {
    font-size: 18px;
  }
  .Notification_notification_textbold__23TJ2 {
    font-size: 18px ;
    font-weight: bold;
    
  }
  
}

.DashboardContact_welcomeMessage__1Zfun {
  background-color: white;
 
  margin-top: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.DashboardContact_firstWelcome__32d-5 {
  font-size: 20px;

  display: flex;
}

.DashboardContact_snippetsCollection__VvLiu {
  display: flex;
}
.DashboardContact_welcomeName__3SXDt {
  margin-left: 5px;
  font-weight: bold;
}
.DashboardContact_bannerContainer__2rTI4 {
  margin-left: 10px;
  margin-right: 10px;
}
.DashboardContact_banner__3IYcm {
  width: 100%;
  margin-top: 10px;
}
.DashboardContact_noreqAndProfile__2Rny4 {
  display: flex;
}

.DashboardContact_reqAndUpgradeOption__1tI9i {
  display: flex;
  align-items: center;
  width: 100%;
}
.DashboardContact_RequirementCard_Package__247_E {
  width: 60%;
}
.DashboardContact_PackageCard__1pU-1 {
  width: 40%;
}

@media (min-width: 768px) {
  .DashboardContact_bannerContainer__2rTI4 {
    margin-left: 0px;
  }
}

.AccountBalance_incomeBox__-Ch-P {
  padding: 10px;
  display: flex;
  border: 1px solid #c8c8c8;
  align-items: center;
  margin: 20px;
  background-color: white;
 
}

.AccountBalance_incomeAmount__1RKZK {
  margin-left: 20px;
  width: 80%;
}

.AccountBalance_amount__2NccD {
  font-size: 20px;
  font-weight: bold;
}
.AccountBalance_amountHeading__15m4t {
  font-size: 20px;
}


.AccountBalance_amountHeadingSection__1imdu {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.AccountBalance_ActionBtn__3zshe{
    display: flex;
    justify-content: space-around;
}
@media screen and (min-width: 768px) {
  .AccountBalance_mainContainer__3Ldog {
    display: flex;
    justify-content: space-evenly;
  }
  .AccountBalance_incomeBox__-Ch-P {
    width: 100%;
  }
  
}

.IncomeCard_mainContainer__19jDM{
    margin: 20px;
    background-color: white;
    border: 1px solid #c8c8c8;
}
.IncomeCard_mainSkill__2mBAu{
    font-weight: bold;
    padding-top: 8px;
}
.IncomeCard_userName__mysov{
    font-weight: 400;
}
.IncomeCard_description__3Jc6B{
margin-top: 10px;
}
.IncomeCard_requirementStatus__2wr57{
    margin-top: 10px;
}

.IncomeCard_reqStatusHeading__vnW-1{
    font-weight: bold;
    color: gray;
    margin-top: 10px;
  
}
.IncomeCard_reqStatusHeadingFirst__33SVZ{
    font-weight: bold;
    color: gray;
    margin-top: 10px;
}
.IncomeCard_reqStatusData__2PJeG{
 
    color: black;
    
}
.IncomeCard_payment__rrVUc{
    font-weight: bold;
}

.IncomeCard_paymentAndOtherDetail__2GTbJ{
    margin-top: 20px;
    font-size: 20px;
}
.IncomeCard_viewButtom__3fjk0{
    font-weight: bold;
    margin-top: 20px;
    text-align: right;
    font-size: 20px;
}
.IncomeCard_viewReq__1C7l2{
    text-decoration: none;
    color: black;
}
.IncomeCard_mainSection__2YQ-e{
    border-bottom: 1px solid #c8c8c8;
    padding: 2%;
}
@media screen and (min-width: 768px) {

    .IncomeCard_mainContainer__19jDM{
        padding: 0%;
    }
    .IncomeCard_paymentAndOtherDetail__2GTbJ{
        display: flex;
    }
    .IncomeCard_webMarginInOrder__3yXrH{
        margin-left: 20px;
    }
    .IncomeCard_requirementStatus__2wr57{
        display: flex;
   
    }
    .IncomeCard_reqStatusHeading__vnW-1{
        margin-left: 20px;
    }
    
}
.Income_loaderDiv__3pClp{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.NoIncomeData_mainContainer__1S8Np{
padding: 10px;
background-color: white;
border: 1px solid #c8c8c8;
margin-top: 10px;
margin-right: 10px;
text-align: center;
}
.NoIncomeData_iconForIncome__1D162{
    margin: 3%;
}
.NoIncomeData_incomeMessage__3GAla{
    font-size: 20px;
    margin: 3%;
}
.ProfessionAndEducation_mainContainer__14icS {
  padding: 0px 20px;
  width: 90%;
}
.ProfessionAndEducation_radio__1KPCn{
  display: flex;
  flex-direction: row;
}

.ProfessionAndEducation_portfolio__3AbQu{
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
}
.ProfessionAndEducation_portfolioContainer__2h9nx{
  padding-left: 20px;
}
.ProfessionAndEducation_rozgaarAppQuestion__2MBPL{
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.ProfessionAndEducation_RadioOne__1DwCL {
  border: 1px solid #ece9e9;
  padding: 8px;
  width: 140px;
  cursor: pointer;
}
.ProfessionAndEducation_RadioTwo__34Qhf {
  border: 1px solid #ece9e9;
  padding: 8px;
  width: 140px;
  cursor: pointer;
  margin-left: 10px;
}
.ProfessionAndEducation_input_container__BM5xB{
  margin-top: 25px;
}

.ProfessionAndEducation_ActionBtn__1aikj {
  display: flex;
  justify-content: center;
}
.ProfessionAndEducation_back_button__13I1c {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.ProfessionAndEducation_error_message__8zQQd {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}

.ProfessionAndEducation_webView_image__5zQWS {
  display: flex;
  width: 13vw;
  margin: auto;
}
.ProfessionAndEducation_image_container__2keXT {
  display: block;
  width: 40%;
}

.ProfessionAndEducation_inputSections__27sk-{
  margin: 20px 0;
}

.ProfessionAndEducation_certificationContainer__3rVVB{

  margin: 10px 0px;
}
.ProfessionAndEducation_plusIcon__APc7y{
  padding: 7px 10px;
  cursor: pointer;
  background-color: #339933;
  display: table;
  border-radius: 8px;
  color: white;
  margin: auto;
}
.ProfessionAndEducation_updateImage__u1LGw {
  display: flex;
  height: 100px;
  width: 100px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.ProfessionAndEducation_radio__1KPCn {
  margin: 13px 0px;
}
.ProfessionAndEducation_CrossIconContainerExperience__yBnTG {
  display: flex;
  cursor: pointer;
  width: 100%;
}
.ProfessionAndEducation_CrossIconContainer__12Byw{
  display: flex;
}
.ProfessionAndEducation_crossIcon__YbM7Y{
  
  margin-left: -15px;
}
.ProfessionAndEducation_crossIconExperience__29Ghc {
  margin-left: -10px;
}
.ProfessionAndEducation_fileIcon__2s5Yp{
  margin: 10px;
  border-radius: 10px;
  padding: 34px;
  border: 1px solid #ece9e9;
}
.ProfessionAndEducation_CertificationContainer__3twE0{
  display: flex;
  width: 100%;
}
.ProfessionAndEducation_crossIconCertificate__1VWqx{
  margin-left: -10px;
  cursor: pointer;
}
.ProfessionAndEducation_certificationListContainer__HSrQL{
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
  
}
.ProfessionAndEducation_certificationLoader__Bsh9c{
  align-self: center;
}
.ProfessionAndEducation_experienceContainer__b6bFB{
  margin: 10px 0px;
  border-radius: 3px;
}
.ProfessionAndEducation_experienceListContainer__1pUhE {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
}
.ProfessionAndEducation_RadioTwoFreelance__1LyFl {
  border: 1px solid #ece9e9;
  padding: 8px 25px;
  margin-top: 8px;
  cursor: pointer;
}

.ProfessionAndEducation_radioFreelancer__1cVWi {
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ProfessionAndEducation_Maincontainer_border__2TGwL {

  margin: auto;
  background-color: #fff;
}
.ProfessionAndEducation_subHeading__1YIN5 {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.ProfessionAndEducation_subHeadingExperience__3AlHV{
  font-size: 19px;
  font-weight: 600;
  padding-bottom: 10px;
}
.ProfessionAndEducation_heading__3Thei {
  font-size: 19px;
  font-weight: 700;
  padding-top: 30px;
}
.ProfessionAndEducation_certificationHeadingContainer__2sKRm{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
  justify-content: space-between;
}
.ProfessionAndEducation_addExperience__3ou86{
  padding: 5px 10px;
  background-color: #339933;
  color: white;
  text-align: center;
  border-radius: 3px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
  cursor: pointer;
}
.ProfessionAndEducation_borderBottomHeading__2JnRm{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.ProfessionAndEducation_manadatoryFields__M2f5c{
  color: red;
}
@media (min-width: 1023px) {
  .ProfessionAndEducation_Maincontainer_border__2TGwL {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
   
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .ProfessionAndEducation_web_view__3jmNc {
    display: flex;
    width: 80%;
    align-items: center;
  }
  .ProfessionAndEducation_mainContainer__14icS {
    width: auto;
     padding-left: 20px;
   }
   .ProfessionAndEducation_mainHeading__26Xwg {
    font-size: 25px;
    font-weight: bold;
  margin: 0px;
  }
    .ProfessionAndEducation_RadioOne__1DwCL {
      width: 300px;
    }
    .ProfessionAndEducation_RadioTwo__34Qhf {
     width: 300px;
     margin-top: 0px;
     margin-left: 10px;
    }
    .ProfessionAndEducation_RadioTwoFreelance__1LyFl{
      width: 265px;
    }
}

.MyProfileSuccess_backdrop__2TgSZ {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .MyProfileSuccess_modal__38xou {
    position: absolute;
    background-color: #fff;
    font-weight: 500;
    top: 20vh;
    height: 300px;
    width: 100vw;
    
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    -webkit-animation: MyProfileSuccess_slide-down__Apabh 300ms ease-out forwards;
            animation: MyProfileSuccess_slide-down__Apabh 300ms ease-out forwards;
    
  }
  .MyProfileSuccess_button__3PTdZ {
    width: 70px;
    color: #f02f39;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .MyProfileSuccess_ActionBtn__2vChu {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 768px) {
    .MyProfileSuccess_modal__38xou {
      width: 20rem;
      left: calc(60% - 20rem);
    }
  }
  @-webkit-keyframes MyProfileSuccess_slide-down__Apabh {
    from {
      opacity: 0;
      -webkit-transform: translateY(-3rem);
              transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes MyProfileSuccess_slide-down__Apabh {
    from {
      opacity: 0;
      -webkit-transform: translateY(-3rem);
              transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  .MyProfileSuccess_ModalCheckIcon__130bX {
    text-align: center;
  }
  .MyProfileSuccess_ModalCheckTextFirst__Hhe3E {
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    
  }
  .MyProfileSuccess_ModalCheckTextSecond__3uIWI {
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    font-weight: bold;
  }
  .MyProfileSuccess_ModalCheckTextthird__2zh4U {
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    
  }
  .MyProfileSuccess_modalcheck__3SVAc {
    height: 60px;
    width: 60px;
    margin-top: -60px;
    border: 1px solid;
    padding: 20px;
    border-radius: 50%;
    background-color: #0c2551;
    color: #fff;
    border-color: #0c2551;
  }
  .MyProfileSuccess_ModalCheckIconText__5XLt- {
    text-align: center;
    font-weight: bold;
  }
  
.Portfolio_mainContainer__3-_oS {
  padding: 20px;
  width: 90%;
}

.Portfolio_radio__lGPZ5 {
  margin: 13px 0px;
}
.Portfolio_heading__2vuke {
  font-size: 18px;
  font-weight: 600;
}
.Portfolio_portfolio__1Eg7s {
  margin-top: 20px;

  font-size: 18px;
  font-weight: 600;
}
.Portfolio_rozgaarAppQuestion__1HbL5 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.Portfolio_RadioOne__2ygGg {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
}
.Portfolio_RadioTwo__1PwAo {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.Portfolio_input_container__1siQE {
  margin-top: 25px;
}

.Portfolio_ActionBtn__2OcGj {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.Portfolio_back_button__11Ktn {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.Portfolio_error_message__fyqtx {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Portfolio_webView_image__1g9kV {
  display: flex;
  width: 13vw;
  margin: auto;
}
.Portfolio_image_container___Yb3N {
  display: block;
  width: 40%;
}

.Portfolio_portfolioImageContainer__22744 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Portfolio_certificationContainer__2gIny {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
}
.Portfolio_plusIcon__2h7r0 {
  display: flex;
  margin: auto;
}
.Portfolio_updateImage__3Snd3 {
  display: flex;
 
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.Portfolio_radio__lGPZ5 {
  margin: 13px 0px;
}
.Portfolio_RadioOne__2ygGg {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
}
.Portfolio_RadioTwo__1PwAo {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.Portfolio_CrossIconContainerExperience__1Gix3 {
  display: flex;
  width: 100%;
}
.Portfolio_CrossIconContainer__cCWF- {
  display: flex;
}
.Portfolio_crossIcon__1GHdf {
  cursor: pointer;
  margin-left: -15px;
}
.Portfolio_crossIconExperience__11qML {
  margin-left: -10px;
}
.Portfolio_fileIcon__sCBna {
  margin: 10px;
  border-radius: 10px;
  padding: 34px;
  border: 1px solid #ece9e9;
}
.Portfolio_CertificationContainer__3uomh {
  display: flex;
  width: 100%;
}
.Portfolio_crossIconCertificate__2Bnpj {
  margin-left: -10px;
}
.Portfolio_certificationListContainer__12yTW {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
}
.Portfolio_certificationLoader__1S-cE {
  align-self: center;
}
.Portfolio_experienceContainer__3sr6z {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
}
.Portfolio_experienceListContainer__2E_x6 {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
}
.Portfolio_RadioTwoFreelance__2pfm- {
  border: 1px solid #ece9e9;
  padding: 8px 25px;
  border-radius: 8px;
  margin-top: 8px;
}
.Portfolio_plusIcon__2h7r0 {
  display: flex;
  margin: auto;
}
.Portfolio_radioFreelancer__29Xtb {
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Portfolio_Maincontainer_border__3UZfy {
  width: 100%;
  margin: auto;
  background-color: #fff;
}
.Portfolio_subHeading__ttzFg {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.Portfolio_subHeadingExperience__3XVSu {
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 10px;
}
.Portfolio_heading__2vuke {
  font-size: 19px;
  font-weight: 700;
  padding-top: 10px;
}
.Portfolio_uploadImageIcon__2Eb6y {
  margin: 10px;
}
.Portfolio_imageUpload__2B9Xx {
  position: absolute;
  bottom: 15px;
}
.Portfolio_uploadBoxIcon__WGJZ3 {
  padding-right: 15px;
}
.Portfolio_uploadBoxIconContainer__3l8o9 {
  align-items: center;
  display: flex;
}
.Portfolio_selectContainer__1YCpy {
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.Portfolio_optionTile__2QBtW {
  padding: 15px;
  border: 1px solid #ece9e9;
  border-radius: 10px;
}

.Portfolio_TileText__cjG2g {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.Portfolio_borderBottomHeading__3rlZS{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.Portfolio_inputSections__2p95A{
  margin: 20px 0;
}
.Portfolio_manadatoryFields__2-lwB{
  color: red;
}
.Portfolio_SkipBtnContainer__1iaZS{
  text-align: center;
    font-weight: 600;
    cursor: pointer;
}
@media (min-width: 1023px) {
  .Portfolio_Maincontainer_border__3UZfy {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .Portfolio_web_view__3vgE3 {
    display: flex;
    align-items: center;
    width: 60%;
  }
  .Portfolio_mainContainer__3-_oS {
    width: 100%;
  padding: 0px 20px;
  }
  .Portfolio_mainHeading__aQcq3 {
    font-size: 25px;
    font-weight: bold;
  margin: 0px;
  }
 
  .Portfolio_SkipBtnContainer__1iaZS{
    margin-left: 20px;
    border-bottom: 2px solid gray;
    font-weight: 600;
    cursor: pointer;
  }
  .Portfolio_ActionBtn__2OcGj {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.CompanyDetails_mainContainer__2dsQU {
  padding: 20px;
 
}

.CompanyDetails_labelDesign__3QmKi {
  margin: 15px 0px 10px 0px;
  font-family: "Roboto", sans-serif;
  font-size: large;
  font-weight: 400;
}
.CompanyDetails_radio__3cnpC {
  margin: 13px 0px;
}
.CompanyDetails_error_message__2maGB {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.CompanyDetails_RadioOne__1TyGa {
  border: 1px solid #ece9e9;
  padding: 8px;
  cursor: pointer;
}
.CompanyDetails_RadioTwo__3GYaS {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 8px;
  cursor: pointer;
}

.CompanyDetails_input_container__jip8I{
  margin-top: 25px;
}
.CompanyDetails_inputArea__3-Npa {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}


.CompanyDetails_ActionBtn__3BBNH {
  display: flex;
  justify-content: center;
}
.CompanyDetails_back_button__2n0dD {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.CompanyDetails_uploadLogoText__7KPcp{
  margin-left: 30px;
  align-self: center;
}
.CompanyDetails_updateImage__3CTP6 {
display: flex;

border-radius: 60px;
border: 1px solid #ece9e9;
align-items: center;
}
.CompanyDetails_imageEditIcon__11qdf {
display: flex;
height: 30px;
width: 30px;
border-radius: 50px;
cursor: pointer;
background: white;
margin-left: -39px;
border: 1px solid #848484;
align-items: center;
}
.CompanyDetails_uploadContainer__1HKGv {
display: flex;
align-items: center;
}
.CompanyDetails_updatetImageContainer__3YNRP {
display: flex;
align-items: end;
}
.CompanyDetails_form_upload_label__3qa6i {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid #ece9e9;
  align-items: center;
}
.CompanyDetails_uploadIcon__32N0b {
cursor: pointer;
color: gray;
height: 22px;
width: 22px;
margin: auto;
}
.CompanyDetails_subHeading__1yKLE {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.CompanyDetails_heading__zbZ3V {
  font-size: 19px;
  font-weight: 700;
  padding-top: 30px;
}
.CompanyDetails_Maincontainer_border__1kFpu{
  width: 100%;
  margin: auto;
  background-color: #fff;
}
.CompanyDetails_selectContainer__3IcYy{
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.CompanyDetails_optionTile__1d4Fa{
  padding: 30px;
  border: 1px solid #ece9e9;
  border-radius: 10px;
  cursor: pointer;
}
.CompanyDetails_optionTileSelected__2hIuI{
  padding: 30px;
  border: 1px solid #1778f2;
  border-radius: 10px;
  cursor: pointer;
}
.CompanyDetails_TileText__1USWi{
  font-weight: 600;
  text-align: center;
}
.CompanyDetails_companyLogoName__31RCi{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
}
.CompanyDetails_borderBottomHeading__2eD0F{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.CompanyDetails_inputSections__23Lw7{
  margin: 20px 0;
}

.CompanyDetails_companyLogoAndContent__2_PBh{
  display: flex;
  align-items: center;
}
.CompanyDetails_uploadLogoContent__16YhL{
  margin-left: 20px;
}
.CompanyDetails_manadatoryFields__1JXVz{
  color: red;
}
@media (min-width: 1023px) {
  .CompanyDetails_mainContainer__2dsQU {
    padding:0px 20px;
  }
  .CompanyDetails_web_view__3mJI2 {
    display: flex;
    align-items: center;
  }
  .CompanyDetails_Maincontainer_border__1kFpu {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .CompanyDetails_webView_image__352HA {
    display: flex;
    width: 13vw;
    margin: auto;
  }
  .CompanyDetails_mainHeading__1JyUq {
    font-size: 25px;
    font-weight: bold;
  margin: 0px;
  }
  .CompanyDetails_radio__3cnpC{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  .CompanyDetails_RadioOne__1TyGa {
    width: 300px;
  }
  .CompanyDetails_RadioTwo__3GYaS {
   width: 300px;
   margin-top: 0px;
   margin-left: 10px;
  }
  .CompanyDetails_twoColoumnField__2v-_d{
    width: 295px;
  }
  .CompanyDetails_twoColoumnContainer__2CujY{
    display: flex;
    padding: 5px 0px;
    flex-direction: row;
    justify-content: space-between;
  }

}

.GatewayPaymentStatus_modal__2D4UH {
  background-color: white;
  padding: 20px;
  text-align: center;
  width: 90%;
  margin: auto;
  border: 1px solid #00a484;
  border-radius: 5px;
}

.GatewayPaymentStatus_mainContainer__24Bo6 {
  margin-top: 30px;
}
.GatewayPaymentStatus_successfulHeading__1rzqT {
  font-weight: 600;
  font-size: 30px;
  color: #00a484;
}

.GatewayPaymentStatus_failHeading__2XMD3 {
  font-weight: 600;
  font-size: 30px;
  color: #cd2626;
}
.GatewayPaymentStatus_detailContainer__1rrGr {
  margin-top: 20px;
}
.GatewayPaymentStatus_detailDiv__2K1Gy {
  display: flex;

  justify-content: space-between;
  font-size: 20px;
}
.GatewayPaymentStatus_detailvalue__3dfuP {
  font-weight: 600;
}

.GatewayPaymentStatus_mainHeading__1ZDGp {
  font-weight: bold;
  color: gray;
}

.GatewayPaymentStatus_backMessage__3CLm3 {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .GatewayPaymentStatus_modal__2D4UH {
    width: 30%;
  }
}


.HireType_hireTypeMainDiv__2FZ4h{
    margin-top: 60px;
}
.HireType_mapDiv__1xQwz{
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    
}
.HireType_detailDiv__CZ31t{
    width: 55%;
    margin-left: 10px;
    color: #707070;
}
.HireType_border__2GCmb{
    height: 50px;
    margin-right:10px ;
    margin-top: 5px;
}
.HireType_typeDiv__CPbgN{
    font-weight: 800;
    width: 30%;
    text-align: right;
    padding-right: 20px;
   
}
.HowToHireSec_mainDiv__Nuam4 {
  margin-top: 40px;
}

.HowToHireSec_firstContentDiv__3cuWo {
  display: flex;
  margin-bottom: 30px;
}

.HowToHireSec_firstSerial__1wrkL {
  color: #375ba7;
  text-align: right;
  font-size: 52px;
  font-weight: 700;
  margin-top: 6px;
  width: 18%;
  margin-right: 32px;
}
.HowToHireSec_secondSerial__1vIvp {
  color: #1c5652;
  font-size: 48px;
  font-weight: 700;
  width: 18%;
  margin: auto;
  margin-top: 6px;
  text-align: left;
}
.HowToHireSec_fourthSerial__1oVws {
  color: #fa9e27;
  font-size: 48px;
  font-weight: 700;
  width: 18%;
  margin: auto;
  margin-top: 6px;
  text-align: left;
}
.HowToHireSec_thirdSerial__2FKP4 {
  color: #4b6a35;
  text-align: right;
  font-size: 52px;
  font-weight: 700;
  margin-top: 6px;
  width: 18%;
  margin-right: 32px;
}
.HowToHireSec_contentInnerDiv__3UNXD {
  margin-top: 16px;
  width: 75%;
}
.HowToHireSec_contentHeading__1DK1j {
  font-weight: 800;
  font-size: 21px;
  margin-bottom: 10px;
}
.HowToHireSec_contentHeadingSecond__aou5D {
  font-weight: 800;
  font-size: 21px;

  margin-bottom: 10px;
  text-align: right;
}
.HowToHireSec_firstParagraph__3dMPI {
  text-align: left;
  color: #5d5d5d;
  margin-right: 7px;
}
.HowToHireSec_secondParagraph__3t1f3 {
  text-align: center;
  color: #5d5d5d;
  margin-left: 7px;
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .HowToHireSec_firstSerial__1wrkL {
    color: #375ba7;
    text-align: right;
    font-size: 48px;
    font-weight: 700;
    margin-top: 8px;
    width: 40%;
    margin-right: 35px;
  }
  .HowToHireSec_secondSerial__1vIvp {
    color: #1c5652;
    font-size: 48px;
    font-weight: 700;
    width: 40%;
    margin-left: 35px;
    margin-top: 8px;
  }
  .HowToHireSec_fourthSerial__1oVws {
    color: #fa9e27;
    font-size: 48px;
    font-weight: 700;
    width: 40%;
    margin-left: 35px;
    margin-top: 8px;
  }
  .HowToHireSec_thirdSerial__2FKP4 {
    color: #4b6a35;
    text-align: right;
    font-size: 48px;
    font-weight: 700;
    margin-top: 8px;
    width: 40%;
    margin-right: 35px;
  }
}

.HowToHire_contentDiv__1piSa {
    padding: 20px;
  }
  .HowToHire_readMore__uA5Ja {
    font-size: 11px;
    background: #f1f7ff;
    width: 20%;
    padding: 2px;
    text-align: center;
    color: #1778f2;
    border-radius: 10px;
  }
  .HowToHire_heading__3UCkR {
    font-size: 25px;
    font-weight: 800;
    line-height: 1.2;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .HowToHire_subHeading__3-mhF {
    font-weight: 600;
    font-size: 13px;
  }
  .HowToHire_faqContent__tQOb- {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .HowToHire_faqContentDiv__37Qcu {
    margin-top: 30px;
  }
  .HowToHire_faqSubHeading__1qaF0 {
    font-weight: 800;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .HowToHire_bannerMob__vrG-I {
   
    margin-top: 10px;
  }
  .HowToHire_faqHeading__1m87F {
    font-weight: 600;
    font-size: 48px;
    letter-spacing: 1px;
    text-align: center;
  }
  .HowToHire_workTypeHeading__3N1xF {
    margin-top: 0px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding-top: 60px;
  }
  .HowToHire_faq_ActionButton__gyvPF {
    display: flex;
    justify-content: center;
}
  .HowToHire_inputDiv__1hwau {
    margin-top: 20px;
    display: flex;
    width: 100%;
    padding: 5px;
    border: 1px solid #dfdfdf;
    cursor: pointer;
  }
  .HowToHire_icon__bwlmG {
    font-size: 25px;
    color: #dfdfdf;
  }
  
  .HowToHire_inputArea__MfcIY {
    width: 100%;
    border: none;
    outline: none;
  }
  .HowToHire_workTypeSubHeading__3l470 {
    text-align: center;
    font-weight: 600;
  }
  .HowToHire_PARBtn_Link__21Dyp {
    text-decoration: none;
  }
  
  @media screen and (min-width: 768px) {
    .HowToHire_faqMainDiv__6gsx8 {
      margin-top: 30px;
      justify-content: center;
      padding-left: 10px;
    }
    .HowToHire_faqContentDiv__37Qcu {
      width: 30%;
    }
  
    .HowToHire_readMore__uA5Ja {
      font-size: 10px;
      background: #f1f7ff;
      width: 30%;
      padding: 1px;
      color: #1778f2;
      border-radius: 10px;
    }
    .HowToHire_inputDiv__1hwau {
      margin: auto;
      margin-top: 20px;
      display: flex;
      width: 90%;
      padding: 5px;
      border: 1px solid #dfdfdf;
    }
  }
  
.HireTypeWeb_mainDiv__2fafC {
  margin-top: 50px;
  margin-left: 70px;
}

.HireTypeWeb_contentDiv__i70Ff {
  display: flex;
  margin-bottom: 40px;
}
.HireTypeWeb_secondTypeDiv__Ch3ow {
  width: 40%;
  text-align: left;
  font-weight: 800;
  font-size: 40px;
  margin-left: 43px;
}
.HireTypeWeb_typeDiv__2tBrl {
  width: 40%;
  text-align: right;
  font-weight: 800;
  font-size: 40px;
  margin-right: 49px;
}
.HireTypeWeb_secondParagraph__ofS2e {
  width: 40%;
  padding: 10px;
  text-align: right;
  margin-right: 30px;
}
.HireTypeWeb_paragraph__28XKr {
  width: 40%;
  padding: 10px;
  margin-left: 30px;
}
.HireTypeWeb_borderDivFirst__22uGO {
  border: 2px solid #375ba7;
  height: 70px;
  margin-top: 20px;
}
.HireTypeWeb_borderDivSecond__2-2hb {
  border: 2px solid #1c5652;
  height: 70px;
  margin-top: 20px;
}
.HireTypeWeb_borderDivThird__6iR-B {
  border: 2px solid #4b6a35;
  height: 70px;
  margin-top: 20px;
}

.HireTypeWeb_borderDivFourth__38TGE {
  border: 2px solid #1c5652;
  height: 70px;
  margin-top: 20px;
}

.HowToHireWeb_mainDiv__1oi0l {
  padding: 60px;
}
.HowToHireWeb_imageDiv__1jVt- {
  display: flex;
}
.HowToHireWeb_hireImage__3HuKx {
  margin-left: 35px;
  
}
.HowToHireWeb_headingDiv__2DqA1 {
  width: 50%;
  margin-top: 100px;
}

.HowToHireWeb_helpYouHireDiv__5ny2t {
  margin-top: 70px;
}
.HowToHireWeb_faq_ActionButton__2Anvv {
  display: flex;
  justify-content: center;
}
.HowToHireWeb_searchInput__2nRMd {
  width: 100%;
  outline: none;
  border: none;
  padding-top: 4px;
}
.HowToHireWeb_workTypeHeading__1O2HV {
  /* margin-top: 80px; */
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.HowToHireWeb_workTypeSubHeading__3z_en {
  text-align: center;
  font-weight: 600;
}
.HowToHireWeb_PARBtn_Link__otYWd {
  text-decoration: none;
}
.HowToHireWeb_inputDiv__5N-ba {
  padding: 6px;
  display: flex;
  justify-content: center;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #999999;
  margin: auto;
  align-items: center;
  cursor: pointer;
}
.HowToHireWeb_searchIcon__3-Mu0 {
  margin-left: 50px;
}

.HowToHireWeb_mainHeading__3HmLK {
  margin-top: 25px;
  font-weight: 800;
  font-size: 40px;
  line-height: 1.2;
  margin: 0px;
}

.HowToHireWeb_subHeading__qi8Io {
  font-size: 18px;
  font-weight: 700;
  
}
.HowToHireWeb_faqHeading__2LpgR {
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  padding: 30px 0px 0px 0px;
  margin: 0px;
}
.HowToHireWeb_mapDiv__1ZLHd {
  margin-top: 20px;
  justify-content: center;
  padding: 30px;
}
.HowToHireWeb_faqInnerDiv__3tAjp {
  width: 30%;
  padding-right: 45px;
}
.HowToHireWeb_faqSubHeading__2W-7p {
  font-weight: 800;
  font-size: 18px;
}
.HowToHireWeb_faqText__3sYeY {
  font-size: 15px;
}
.HowToHireWeb_readMore__2hBkJ {
  width: 80px;
  margin-top: 10px;
  font-size: 12px;
  padding: 4px;
  text-align: center;
  border-radius: 10px;
  color: #1778f2;
  background-color: #f1f7ff;
  cursor: pointer;
}
.HowToHireWeb_hoetohireSec__3jv9Q {
  width: 80%;
  margin: auto;
}

.HowToHireWeb_rightWork__qpOv9{
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0px;
}
.FutureWork_FutureWork_bannerovertext__1Zecd {
  position: absolute;
  font-size: 20px;
  color: #fff;
  text-align: center;
  top: 76%;
}
.FutureWork_FutureWork_bannerimage__AJZx7 {
  width: 100%;
  height: 600px;
}
.FutureWork_FutureWork_bannerimageweb__2v938 {
  display: none;
}
.FutureWork_FutureWork_maintext__18_ZI {
  font-size: 21px;
  font-weight: bold;
  margin: 30px;
}
.FutureWork_FutureWork_imagefirst__3HBUO {
  width: 90%;
  margin: auto;
  display: block;
  border-radius: 6px;
  display: block;
}
.FutureWork_FutureWork_imagefirstweb__29XzV {
  display: none;
}
.FutureWork_FutureWork_text__eoDrI {
  font-size: 23px;
  margin: 30px;
}
.FutureWork_FutureWork_borderbottomfirst__3hoNP {
  width: 109px;
  border-bottom: 3px solid #fb6e00;
  margin-left: 30px;
  border-radius: 5px;
  margin-bottom: 50px;
}
.FutureWork_FutureWork_borderbottomsecond__1MPPd {
  width: 66px;
  border-bottom: 6px solid #fb6e00;
  border-radius: 5px;
  margin: 50px 0px;
}

.FutureWork_FutureWork_borderbottomthird__3Iqki {
  width: 77px;
  border-bottom: 6px solid #1c2f43;
  margin-left: 30px;
  border-radius: 5px;
  margin: 50px 0px;
}
.FutureWork_FutureWork_bgcolor__1oZ0G {
  background: #1c2f43;
  color: #fff;
}
.FutureWork_FutureWork_lastheadingfirst__2_ZQ- {
  font-size: 33px;
  font-weight: 200;
  padding: 20px 20px 0px 20px;
}
.FutureWork_FutureWork_lastheadingsecond__2dFwO {
  font-size: 33px;
  font-weight: bold;
  margin: 0px 20px;
}
.FutureWork_ActionButton__2viH0 {
  margin: 0px 20px;
  display: block;
}
.FutureWork_FutureWork_bgcolorwebone__2ZW52 {
  display: block;
}
.FutureWork_FutureWork_bgcolorwebtwo__2nqmx {
  display: none;
}
.FutureWork_PARBtn_Link__2q5go {
  text-decoration: none;
  color: #fff;
}

@media screen and (min-width: 1023px) {
  .FutureWork_FutureWork_bannerimageweb__2v938 {
    width: 100%;
    display: block;
    height: 450px;
  }
  .FutureWork_FutureWork_bannerovertext__1Zecd {
    right: 30px;
    width: 35%;
    top: 250px;
  }

  .FutureWork_FutureWork_bannerimage__AJZx7 {
    display: none;
  }

  .FutureWork_FutureWork_dflex__2fv1Z {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    padding-top: 75px;
  }
  .FutureWork_FutureWork_maintext__18_ZI {
    text-align: center;
  }
  .FutureWork_FutureWork_borderbottomfirst__3hoNP {
    margin: auto;
  }
  .FutureWork_FutureWork_borderbottomthird__3Iqki {
    align-items: right;
  }

  .FutureWork_FutureWork_imagefirstweb__29XzV {
    width: 50%;
    display: block;
    border-radius: 6px;
  }

  .FutureWork_FutureWork_imagefirst__3HBUO {
    display: none;
  }
  .FutureWork_FutureWork_bgcolorwebone__2ZW52 {
    background: #f2f2f2;
    display: block;
    width: 50%;
    border-radius: 10px 0px 0px 10px;
    color: #000;
  }
  .FutureWork_FutureWork_bgcolorwebtwo__2nqmx {
    background: #1c2f43;

    width: 50%;
    text-align: center;
    padding-top: 30px;
    border-radius: 0px 10px 10px 0px;
    font-size: 30px;
    display: block;
  }
  .FutureWork_FutureWork_bgcolor__1oZ0G {
    background: none;
    display: flex;
    width: 80%;
    height: 170px;
    margin: auto;
    padding-top: 80px;
  }
  .FutureWork_ActionButton__2viH0 {
    display: none;
  }
  .FutureWork_FutureWork_border__3L5js {
    border-bottom: 5px solid #fb6e00;
    width: 20%;
    padding-top: 10px;
    margin: auto;
  }
}

.PackageOption_mainContainer__1vwqa {
  padding: 15px;
  background-color: white;
  margin-top: 10px;
  margin-right: 10px;
}

.PackageOption_heading__2yL5u {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
}
.PackageOption_mainHeading__3T0ZS {
  padding: 10px;
  font-weight: 800;
  font-size: 20px;
}

.PackageOption_currentPlanMainDiv__3vU0w {
  visibility: hidden;
}

.PackageOption_activePlan__1QU6h {
  display: flex;
  justify-content: right;
  width: 88%;
}

.PackageOption_mainHeadingData__2sbG3 {
  text-align: center;
  padding: 10px;
  font-weight: 800;
  font-size: 20px;
}
.PackageOption_planSelection__1pOon {
  border: 1px solid black;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}

.PackageOption_active__Co6xq {
  background-color: #4cb3be;
  color: #ffff;
  border: 1px solid #4cb3be;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}

.PackageOption_planMainDiv__2jzcS {
  display: flex;
  flex-direction: row-reverse;
  overflow: scroll;
  margin-bottom: 10px;
}
.PackageOption_planMainDiv__2jzcS::-webkit-scrollbar {
  display: none;
}
.PackageOption_tableFormat__2VP33 {
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 2%;
  border: 1px solid #7070702e;
}
.PackageOption_selectedPackageDetails__30Wzr {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin-top: 30px;
}

.PackageOption_packageType__SzrET {
  font-size: 15px;
  font-weight: 600;
}

.PackageOption_packageAmount__2xq-n {
  font-size: 30px;
  font-weight: 800;
  margin-left: 10px;
}
.PackageOption_tableHeads__3tWsw {
  padding: 10px;
  font-size: 11px;
}
.PackageOption_tableData__2d3AY {
  padding: 10px;
  font-size: 11px;
  text-align: center;
  border-bottom: 1px solid #7070702e;
}

.PackageOption_planHeading__3FVlj {
  padding: 10px;
  font-size: 11px;
}
.PackageOption_fistSectionHeads__DatEx {
  width: 70%;
}

.PackageOption_tableHeadsBorder__1rCon {
  border-bottom: 1px solid #7070702e;
}

.PackageOption_displayTable__3M8H0 {
  display: flex;
  flex-direction: row-reverse;
  width: 30%;
}

.PackageOption_tableContents__13QkT {
  width: 100%;
  text-align: center;
}

.PackageOption_tableContentsSelected__1064U {
  width: 100%;
  text-align: center;
  background-color: #4cb3be;
  color: #ffffff;
}
.PackageOption_currentPlanInnerDiv__16ddg {
  margin-top: 20px;
  width: 100%;
}
.PackageOption_packageNameHeadingDiv__2-hTO {
  display: flex;
  border: 1px solid #7070702e;
  justify-content: space-between;
  align-items: center;
}
.PackageOption_packageNameContent__1CSNq {
  padding: 10px;
}
.PackageOption_packageNameHeading__Wk760 {
  font-weight: 800;
  padding: 10px;
}
.PackageOption_CurrentPlanContainer__2gOxJ {
  display: flex;
  justify-content: space-between;

  margin-top: 5%;
}

.PackageOption_CurrentPlansection__3A2oV {
  font-weight: 600;
  color: #000;
}

@media (min-width: 1023px) {
  .PackageOption_mainContainer__1vwqa {
    padding: 15px;
    background-color: white;
    margin-top: 10px;
    margin-right: 10px;
  }
  .PackageOption_ActionBtnContainer__k0AqC {
    display: flex;
    justify-content: right;
    flex-direction: row-reverse;
    width: 80%;
    margin: auto;
  }
  .PackageOption_contents__2XTuH {
    padding: 10px;
    font-size: 16px;
    border-bottom: 1px solid #7070702e;
  }
  .PackageOption_CurrentPlanContainer__2gOxJ {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-top: 22px;
  }
  .PackageOption_tableFormat__2VP33 {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 1%;
    border: 1px solid #7070702e;
  }
  .PackageOption_tableContents__13QkT {
    width: 100%;
    text-align: center;
  }

  .PackageOption_tableContentsSelected__1064U {
    width: 100%;
    text-align: center;
    background-color: #4cb3be;
    color: #ffffff;
  }
  .PackageOption_selectedPackageDetails__30Wzr {
    display: flex;
    width: 50%;
  }
  .PackageOption_buyPlanButton__1z73V {
    width: 16%;
    margin-left: 4px;
  }
  .PackageOption_mainHeading__3T0ZS {
    font-weight: 800;
    font-size: 20px;
  }

  .PackageOption_fistSectionHeads__DatEx {
    width: 100%;
  }

  .PackageOption_displayinOrder__3KcC0 {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }
  .PackageOption_contentsBold__2gPcv {
    padding: 10px;
    font-weight: 800;
    font-size: 20px;
  }

  .PackageOption_tableHeads__3tWsw {
    padding: 10px;
    font-size: 16px;
  }

  .PackageOption_currentPlanInnerDiv__16ddg {
    width: 40%;
  }
  .PackageOption_packageNameHeadingDiv__2-hTO {
    display: flex;
    border: 1px solid #7070702e;
    justify-content: space-between;
  }
  .PackageOption_packageNameContent__1CSNq {
    padding: 10px;
  }
  .PackageOption_packageNameHeading__Wk760 {
    font-weight: 800;
    padding: 10px;
  }
}

.WorkRequirementDashboard_pageLayout__2lbQ8 {
    padding: 15px;
  }
  
  .WorkRequirementDashboard_pageDivider__RCSLP {
    display: flex;
  }
  
  .WorkRequirementDashboard_filterContainer__34N2D {
    height: auto;
    width: 25%;
    background-color: white;
    padding: 20px;
  }
  
  .WorkRequirementDashboard_filterByHeading__3xMMo {
    font-weight: bold;
    padding: 10px 10px 3px 10px;
    font-size: 20px;
  
    border-bottom-width: 2px;
    border-bottom-color: black;
    border-bottom-style: solid;
  }
  
  .WorkRequirementDashboard_filterOptionHeadings__1499I {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .WorkRequirementDashboard_checkBoxContainer__ou2FE {
    display: flex;
    align-items: center;
    
  }
  
  .WorkRequirementDashboard_budgetTextInput__3pLu9 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .WorkRequirementDashboard_textInput__1hgek {
    width: 50%;
    margin: 3px;
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .WorkRequirementDashboard_goButton__3DifG {
    padding: 3px;
    border: 1px solid gray;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    border-radius: 3px;
  }
  .WorkRequirementDashboard_goButton__3DifG:hover {
    cursor: pointer;
    background-color: #1678f2;
    color: white;
  }
  
  .WorkRequirementDashboard_sortAndRequirmentContainer__1C6Kr {
 
    background-color: white;
    margin-left: 10px;
  }
  
  .WorkRequirementDashboard_sortAndPageContainer__1Hg1F {
    justify-content: center;
  }
  .WorkRequirementDashboard_sortContainer__2fqBz {
    display: flex;
    overflow: scroll;
    width: 100%;
    justify-content: space-around;
  }
  
  .WorkRequirementDashboard_sortHeading__SZclU {
    font-weight: bold;
  }
  
  .WorkRequirementDashboard_sortHeading__SZclU:hover {
    cursor: pointer;
  }
  
 
  .WorkRequirementDashboard_sortOptions__3U3W0:hover {
    cursor: pointer;
  }
  .WorkRequirementDashboard_sortOptionSelected__16sHH {
    border-bottom-color: #c07fff;
    border-bottom-style: solid;
    border-bottom-width: 2px;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
    color: #c07fff;
    font-weight: bold;
  }
  
  .WorkRequirementDashboard_sortOptionSelected__16sHH:hover {
    cursor: pointer;
  }
  
  .WorkRequirementDashboard_pageNumbersContainer__35r_1 {
    display: flex;
    margin-right: 10%;
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  }
  
  .WorkRequirementDashboard_pageNumbersContainerBottom__1VC8y {
    display: flex;
    margin-right: 10%;
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    justify-content: end;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  
  .WorkRequirementDashboard_pageCount__lObY- {
    background-color: #1778f2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    width: 28px;
    margin: 2px 6px;
    color: #fff;
    font-size: 14px;
    height: 28px;
  }
  .WorkRequirementDashboard_searchBarContainer__3gB_U {
  
    display: flex;
    justify-content: center;
  
    width: 100%;
  }
  .WorkRequirementDashboard_searchIcon__3rHcF {
    padding: 10px 8px;
    height: 22px;
    background-color: #1678f2;
    color: white;
    margin-top: 13px;
  }
  
  .WorkRequirementDashboard_searchIcon__3rHcF:hover {
    cursor: pointer;
    background-color: #0c58b4;
  }
  
  .WorkRequirementDashboard_searchInput__2Djiu {
    padding: 3px 0px;
    width: 50%;
  }
  .WorkRequirementDashboard_searchInput__2Djiu:focus {
    outline: none;
  
    border: 1px solid #1678f2;
    border-right-width: 0px;
  }
  .WorkRequirementDashboard_searchInput__2Djiu::-webkit-input-placeholder {
    font-size: 16px;
    color: rgb(197, 197, 197);
  }
  .WorkRequirementDashboard_searchInput__2Djiu:-ms-input-placeholder {
    font-size: 16px;
    color: rgb(197, 197, 197);
  }
  .WorkRequirementDashboard_searchInput__2Djiu::placeholder {
    font-size: 16px;
    color: rgb(197, 197, 197);
  }
  
  .WorkRequirementDashboard_borderLine__2nXym {
    border-bottom: 1px solid #dedede;
    width: 40%;
  }
  
  .WorkRequirementDashboard_buttons1__3OCHw {
    display: flex;
  }
  .WorkRequirementDashboard_pageCountIcon__-z0GB {
    color: #1778f2;
    margin: 2px 6px;
  }
  .WorkRequirementDashboard_pageCountIcon__-z0GB:hover {
    cursor: pointer;
  }
  .WorkRequirementDashboard_PaginationContainer__1Z3qQ {
    padding: 22px 0px;
    text-align: center;
    
  }
  .WorkRequirementDashboard_buttonone__1P8Ih {
    color: #fff;
    background-color: #1778f2;
    padding: 8px 19px;
    border: none;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    border-radius: 5px;
  }
  .WorkRequirementDashboard_CurrentPageText__1V2Za{
    border: 1px solid #1778f2;
    border-radius: 8px;
    padding: 3px 10px;
    width: 30px;
    margin-right: 5px;
    color: #1778f2;
    margin-left: 8px;
  }
  .WorkRequirementDashboard_CurrentPageTextNext__2HtQy{
    border: 1px solid gray;
    border-radius: 8px;
    padding: 3px 10px;
    width: 30px;
   
  }
  .WorkRequirementDashboard_CurrentPageDotted__2euHW{
    margin-top: 8px;
    margin-right: 10px;
    margin-left: 5px;
  }
  .WorkRequirementDashboard_buttononePre__1-vJu{
   color: gray;
    font-size: 15px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px 14px; 
    margin-top: 6px;
    margin-left: 3px;
  }
  
  @media screen and (min-width: 768px) {
    .WorkRequirementDashboard_pageLayout__2lbQ8 {
      padding: 15px;
      background-color: #f5f6fb;
    }
    .WorkRequirementDashboard_PaginationContainer__1Z3qQ {
      padding: 22px 22px 8px 22px;
      text-align: right;
    }
    .WorkRequirementDashboard_searchInput__2Djiu {
      padding: 3px 0px;
      width: 100%;
    }
    .WorkRequirementDashboard_sortAndPageContainer__1Hg1F {
      display: flex;
      padding: 10px;
      padding-bottom: 0px;
      justify-content: space-between;
    }
    .WorkRequirementDashboard_pageNumbersContainer__35r_1 {
      justify-content: inherit;
      margin-top: 0;
    }
    .WorkRequirementDashboard_sortContainer__2fqBz {
      display: grid;
      justify-content: inherit;
      
    }
   
  }
