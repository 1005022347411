.mainContainer{
    margin: 20px;
    background-color: white;
    border: 1px solid #c8c8c8;
}
.mainSkill{
    font-weight: bold;
    padding-top: 8px;
}
.userName{
    font-weight: 400;
}
.description{
margin-top: 10px;
}
.requirementStatus{
    margin-top: 10px;
}

.reqStatusHeading{
    font-weight: bold;
    color: gray;
    margin-top: 10px;
  
}
.reqStatusHeadingFirst{
    font-weight: bold;
    color: gray;
    margin-top: 10px;
}
.reqStatusData{
 
    color: black;
    
}
.payment{
    font-weight: bold;
}

.paymentAndOtherDetail{
    margin-top: 20px;
    font-size: 20px;
}
.viewButtom{
    font-weight: bold;
    margin-top: 20px;
    text-align: right;
    font-size: 20px;
}
.viewReq{
    text-decoration: none;
    color: black;
}
.mainSection{
    border-bottom: 1px solid #c8c8c8;
    padding: 2%;
}
@media screen and (min-width: 768px) {

    .mainContainer{
        padding: 0%;
    }
    .paymentAndOtherDetail{
        display: flex;
    }
    .webMarginInOrder{
        margin-left: 20px;
    }
    .requirementStatus{
        display: flex;
   
    }
    .reqStatusHeading{
        margin-left: 20px;
    }
    
}