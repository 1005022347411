.mainDiv{
    margin-top: 20px;
    border: 1px solid #7070702E;
    border-bottom: none;
    background-color: #FFFEF8;
    padding: 9px 0px;
    margin: 10px;
}
.planMainDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
.dataDiv{
    display: flex;
}

.mainMapDiv{
    width: 30%;
    background-color: #4CB3BE
}

.headingMainDiv{
    width: 70%;
}

.planType{
    display: flex;
    justify-content: flex-end;
    width: 92%;
    color: #4CB3BE;
    font-weight: 700;
    margin-bottom: 10px;    
}

.planHeading{
 padding: 9px 6px;
font-size: 11px;
border-bottom: 1px solid #7070702E ; 
}

.planDetailsDiv{ 
    text-align: center;
    padding: 6px;
    margin: 0px;
    color: #FFFFFF;  
    background-color: #4CB3BE
}

