.packageHeading {
  text-align: center;
  margin-top: 25px;
  font-weight: 800;
  font-size: 45px;
}
.planDiv {
  margin-top: 50px;
  margin-left: 100px;
  width: 79%;
  border: 1px solid #7070702e;
}
.plans {
  display: flex;
  width: 100%;
}

.planType {
  width: 21%;
  background-color: #ffffff;
  padding: 10px;

  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}

.oneSection {
  background-color: #f6ca48;
}
.activePlanType {
  width: 21%;
  background-color: #4cb3be;
  font-weight: bold;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}

.planHeadingMain {
  background-color: #ffffff;
  padding: 9px;
  font-weight: bolder;
  font-size: 18px;
  width: 40%;
}

.planSelectionDiv {
  display: flex;
  width: 79%;
  margin-left: 100px;
  border: 1px solid #7070702e;
}

.planDetails {
  padding: 10px;
  border-bottom: 1px solid #7070702e;
}

.planDetailsButtonDiv {
  padding: 10px;
  border-top: none;
  border-radius: 5px;
}

.activePlanDetails {
  padding: 10px;
  border-bottom: 1px solid #7070702e;
  background-color: #4cb3be;
  color: #ffffff;
}

.planDetailsHeadingMainDiv {
  width: 38%;
}
.conatiner {
  width: 21%;
  text-align: center;
  cursor: pointer;
}
.planHeading {
  padding: 10px;
  border-bottom: 1px solid #7070702e;
}

.planConfirm {
  background-color: #4cb3be;
  border: 1px solid #4cb3be;
  color: #ffffff;
  padding: 5px;
}

.planPriceDiv {
  width: 300px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-right: 100px;
}

.totalPrice {
  margin-right: 10px;
  font-weight: 900;
}

.perYear {
  font-size: 21px;
  font-weight: lighter;
}
.planPrices {
  width: 25%;
  font-weight: 700;
  font-size: 32px;
  margin-left: 15px;
}
.active {
  background-color: #4cb3be;
  color: #ffff;
  border: 1px solid #4cb3be;
  width: 40%;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}
.selectButtonDiv {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.currentPlanMainDiv {
  margin: 10px;
  border: 1px solid #7070702e;
  border-radius: 5px;
  margin-left: 100px;
  width: 34%;
}
.currentPlanDiv {
  display: flex;
  justify-content: space-between;
}
.currentPlanHeading {
  padding: 11px;
}
.currentPlanSubHeading {
  padding: 11px;
}
.planDurationDiv {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #7070702e;
}
.planDurationMainDiv {
  margin-top: 20px;
}

.planDuration {
  font-weight: 500;
  padding: 6px 11px;
}
.upgradePlan {
  margin: auto;
}

.upgradePlanMainDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}
.packDetailsHeading {
  padding: 0px 11px;
}
.selectButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  width: 163px;
  border: #f6ca48;
  border-radius: 5px;
  background-color: #f6ca48;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .planHeading:last-child {
    padding: 0 10px;
  }
  .planPriceDiv {
    width: 210px;
  }
  .planHeadingMain {
    background-color: #ffffff;
    padding: 9px;
    font-weight: bolder;
    font-size: 18px;
    width: 41%;
  }
}
