.mainContainer {
  padding: 0px 20px;
  width: 90%;
}
.radio{
  display: flex;
  flex-direction: row;
}

.portfolio{
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
}
.portfolioContainer{
  padding-left: 20px;
}
.rozgaarAppQuestion{
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.RadioOne {
  border: 1px solid #ece9e9;
  padding: 8px;
  width: 140px;
  cursor: pointer;
}
.RadioTwo {
  border: 1px solid #ece9e9;
  padding: 8px;
  width: 140px;
  cursor: pointer;
  margin-left: 10px;
}
.input_container{
  margin-top: 25px;
}

.ActionBtn {
  display: flex;
  justify-content: center;
}
.back_button {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}

.webView_image {
  display: flex;
  width: 13vw;
  margin: auto;
}
.image_container {
  display: block;
  width: 40%;
}

.inputSections{
  margin: 20px 0;
}

.certificationContainer{

  margin: 10px 0px;
}
.plusIcon{
  padding: 7px 10px;
  cursor: pointer;
  background-color: #339933;
  display: table;
  border-radius: 8px;
  color: white;
  margin: auto;
}
.updateImage {
  display: flex;
  height: 100px;
  width: 100px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.radio {
  margin: 13px 0px;
}
.CrossIconContainerExperience {
  display: flex;
  cursor: pointer;
  width: 100%;
}
.CrossIconContainer{
  display: flex;
}
.crossIcon{
  
  margin-left: -15px;
}
.crossIconExperience {
  margin-left: -10px;
}
.fileIcon{
  margin: 10px;
  border-radius: 10px;
  padding: 34px;
  border: 1px solid #ece9e9;
}
.CertificationContainer{
  display: flex;
  width: 100%;
}
.crossIconCertificate{
  margin-left: -10px;
  cursor: pointer;
}
.certificationListContainer{
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
  
}
.certificationLoader{
  align-self: center;
}
.experienceContainer{
  margin: 10px 0px;
  border-radius: 3px;
}
.experienceListContainer {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
}
.RadioTwoFreelance {
  border: 1px solid #ece9e9;
  padding: 8px 25px;
  margin-top: 8px;
  cursor: pointer;
}

.radioFreelancer {
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Maincontainer_border {

  margin: auto;
  background-color: #fff;
}
.subHeading {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.subHeadingExperience{
  font-size: 19px;
  font-weight: 600;
  padding-bottom: 10px;
}
.heading {
  font-size: 19px;
  font-weight: 700;
  padding-top: 30px;
}
.certificationHeadingContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
  justify-content: space-between;
}
.addExperience{
  padding: 5px 10px;
  background-color: #339933;
  color: white;
  text-align: center;
  border-radius: 3px;
  margin: auto;
  width: fit-content;
  margin-top: 10px;
  cursor: pointer;
}
.borderBottomHeading{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.manadatoryFields{
  color: red;
}
@media (min-width: 1023px) {
  .Maincontainer_border {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
   
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .web_view {
    display: flex;
    width: 80%;
    align-items: center;
  }
  .mainContainer {
    width: auto;
     padding-left: 20px;
   }
   .mainHeading {
    font-size: 25px;
    font-weight: bold;
  margin: 0px;
  }
    .RadioOne {
      width: 300px;
    }
    .RadioTwo {
     width: 300px;
     margin-top: 0px;
     margin-left: 10px;
    }
    .RadioTwoFreelance{
      width: 265px;
    }
}
