.mainContainer{
    background-color: white;
    margin: auto;
    text-align: center;
    padding: 20px;
}
.headingFont{
    font-size: 20px;
    font-weight: 800;
}
@media (min-width: 768px) {
    .contentWeb{
        width: 50%;
    margin: auto;

    }
}