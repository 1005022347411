.mainContainer{
    margin:10px 5px;
    border:1px solid red;
    background-color: antiquewhite;
    border-radius: 10px;
    
}
.iconAlignment{
    display: flex;
    align-items: center;
    padding: 5px;
}
.errorMessage{
 padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
}