.mainContainer {
  padding: 20px;
  width: 90%;
}

.radio {
  margin: 13px 0px;
}
.heading {
  font-size: 18px;
  font-weight: 600;
}
.portfolio {
  margin-top: 20px;

  font-size: 18px;
  font-weight: 600;
}
.rozgaarAppQuestion {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.RadioOne {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
}
.RadioTwo {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.input_container {
  margin-top: 25px;
}

.ActionBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.back_button {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.webView_image {
  display: flex;
  width: 13vw;
  margin: auto;
}
.image_container {
  display: block;
  width: 40%;
}

.portfolioImageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.certificationContainer {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
}
.plusIcon {
  display: flex;
  margin: auto;
}
.updateImage {
  display: flex;
 
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.radio {
  margin: 13px 0px;
}
.RadioOne {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
}
.RadioTwo {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.CrossIconContainerExperience {
  display: flex;
  width: 100%;
}
.CrossIconContainer {
  display: flex;
}
.crossIcon {
  cursor: pointer;
  margin-left: -15px;
}
.crossIconExperience {
  margin-left: -10px;
}
.fileIcon {
  margin: 10px;
  border-radius: 10px;
  padding: 34px;
  border: 1px solid #ece9e9;
}
.CertificationContainer {
  display: flex;
  width: 100%;
}
.crossIconCertificate {
  margin-left: -10px;
}
.certificationListContainer {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
}
.certificationLoader {
  align-self: center;
}
.experienceContainer {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
}
.experienceListContainer {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  width: 100%;
}
.RadioTwoFreelance {
  border: 1px solid #ece9e9;
  padding: 8px 25px;
  border-radius: 8px;
  margin-top: 8px;
}
.plusIcon {
  display: flex;
  margin: auto;
}
.radioFreelancer {
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Maincontainer_border {
  width: 100%;
  margin: auto;
  background-color: #fff;
}
.subHeading {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.subHeadingExperience {
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 10px;
}
.heading {
  font-size: 19px;
  font-weight: 700;
  padding-top: 10px;
}
.uploadImageIcon {
  margin: 10px;
}
.imageUpload {
  position: absolute;
  bottom: 15px;
}
.uploadBoxIcon {
  padding-right: 15px;
}
.uploadBoxIconContainer {
  align-items: center;
  display: flex;
}
.selectContainer {
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.optionTile {
  padding: 15px;
  border: 1px solid #ece9e9;
  border-radius: 10px;
}

.TileText {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.borderBottomHeading{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.inputSections{
  margin: 20px 0;
}
.manadatoryFields{
  color: red;
}
.SkipBtnContainer{
  text-align: center;
    font-weight: 600;
    cursor: pointer;
}
@media (min-width: 1023px) {
  .Maincontainer_border {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .web_view {
    display: flex;
    align-items: center;
    width: 60%;
  }
  .mainContainer {
    width: 100%;
  padding: 0px 20px;
  }
  .mainHeading {
    font-size: 25px;
    font-weight: bold;
  margin: 0px;
  }
 
  .SkipBtnContainer{
    margin-left: 20px;
    border-bottom: 2px solid gray;
    font-weight: 600;
    cursor: pointer;
  }
  .ActionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
