
.hireTypeMainDiv{
    margin-top: 60px;
}
.mapDiv{
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    
}
.detailDiv{
    width: 55%;
    margin-left: 10px;
    color: #707070;
}
.border{
    height: 50px;
    margin-right:10px ;
    margin-top: 5px;
}
.typeDiv{
    font-weight: 800;
    width: 30%;
    text-align: right;
    padding-right: 20px;
   
}