.mainContainer {
  padding: 10px 20px;
  width: 90%;
}
.input_container {
  margin-top:5%;

}

.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}

.ActionBtn {
  display: flex;
  justify-content: center;
}
.webView_image {
  display: flex;
  width: 13vw;
  margin: auto;
}
.subHeading {
  font-size: 14px;
  padding-bottom: 10px;
  color: grey;
}
.heading {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
    margin-bottom: 10px;

}
.Maincontainer_border {

  margin: auto;
  background-color: #fff;
}
.inputSections{
  margin: 20px 0;
}

.borderBottomHeading{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.relatedSkillContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: scroll;
}

.relatedSkillBox {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.relatedSkillBox:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}
.addIcon {
  margin-left: 5px;
}
.subskillHeading{
  font-size: 19px;
  font-weight: 700;
  padding: 10px 0px;
}

.manadatoryFields{
  color: red;
}

.pageArealayout{
  margin-top: 5%;
}
@media (min-width: 1023px) {
  .Maincontainer_border {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .input_container{
    margin-top: 2%;
  }
  .fields {
    padding: 10px 20px;
  }
  .CheckboxStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .workPolicyCheck {
    width: 270px;
  }
  .countryStateContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .cityPincodeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .cityDropdown {
    width: 400px;
  }
  .workPolicyCheckbox {
    width: 200px;
  }
  .mainHeading {
    font-size: 25px;
    font-weight: bold;
margin: 0px;
  
  }
  .mainContainer {
   width: auto;
    padding-left: 20px;
  }

  .heading {
    font-size: 18px;
    font-weight: 700;
  
  }
  .subHeading {
    font-size: 16px;
   
  }
  .web_view {
    display: flex;
    width: 80%;
    
    align-items: center;
  }
  .pageArealayout{
    margin-top: 5%;
  }
}
