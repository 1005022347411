.buttonBoxCenter {
    background-color: #FCD017;;
    color: black;
   border-radius: 3px;
    display: flex;
    padding: 6px 6px 6px 6px;
margin: auto;
    margin-bottom: 25px;
    border: none;
    margin-top: 10px;
    
  }
  .buttonBoxleft {
    background-color: #FCD017;;
    color: black;
   border-radius: 3px;
    display: flex;
    padding: 10px 10px 10px 10px;

    margin-bottom: 25px;
    border: none;
    margin-top: 20px;
    
  }
  .buttonText {
    align-self: center;
    margin: auto;
    padding: 0px 10px;
    font-weight: bold;
  cursor: pointer;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .centerHeading{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  .subHeading{
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .buttonBoxLeft:hover {
    background-color: white;
    border-width: 1px;
    border-color: #8600b0;
    border-style: solid;
    color: #8600b0;
    cursor: pointer;
  }

  .buttonBoxWhite {
    background-color: #ffffff;
    color: #0f587d;
    height: auto;
    border-radius: 6px;
  
    display: flex;
    padding: 10px 10px 10px 10px;
    margin-top: 25px;
    border: none;
  }
  
  @media screen and (min-width: 768px) {
    .centerHeading{
      font-size: 60px;
    }
  .subHeading{
  margin-top: -10px;
  }
  }