.top_link {
  margin: 30px 20px;
  color: #707070;
}
.home_link {
  text-decoration: none;
}
.home_link:first-child {
  color: #1778f2;
}
.para_text_container {
  margin: 0px 20px;
}
.para_text {
  margin: 10px 0px 30px 0px;
}
.freelancer_para {
  padding: 10px 0px 20px 0px;
  background-color: #999999;
  margin-right: 20px;
  border-radius: 0px 70px 70px 0px;
}
.freelancer_Heading {
  color: #ffffff;
  margin: 20px;
  margin-top: 20px;
}
.freelancer_para_text {
  margin: 10px 40px 0px 20px;
  color: #ffffff;
}
.clients_para {
  padding: 10px 0px 20px 0px;
  background-color: #64adf7;
  margin: 20px 0px 0px 20px;
  border-radius: 70px 0px 0px 70px;
}
.clients_Heading {
  color: #ffffff;
  margin: 20px;
  text-align: right;
}
.clients_para_text {
  margin: 10px 20px 0px 40px;
  text-align: right;
  color: #ffffff;
}
.core_values_container {
  background-color: #f5f5f5;
  padding: 10px 0px;
  margin: 20px 0px;
}
.para_text_mob {
  margin: 0px 20px;
}
.para_text_mob_right {
  margin: 0px 20px;
}
.core_values_heading {
  text-align: center;
}
.core_values_para {
  text-align: center;
}
.core_image {
  
  display: none;
}
.core_imageMob{
  display: block;
  margin-top: 20px;
}
.core_values_text {
  margin: 20px 35px;
}
.image_bg {
  background-color: #1778f2;
  padding: 12px 16px;
  width: 50px;
  text-align: center;
  position: relative;
}
.bottom_text_div {
  margin: -47px 20px 10px 50px;
  background-color: #ffffff;
  border-radius: 0px 28px 28px 0px;
  box-shadow: 10px 5px 12px 12px #00000014;
  padding: 16px 0px 16px 40px;
}
.bottom_text_divLeft {
  margin: -47px 50px 10px 20px;
  background-color: #ffffff;
  border-radius: 28px 0px 0px 28px;
  box-shadow: 10px 5px 12px 12px #00000014;
  padding: 16px 0px 16px 0px;
}
.image_left {
  display: flex;
  justify-content: flex-end;
}
.bottom_text_left {
  text-align: right;
  margin-right: 40px;
}
.image_bg_left {
  background-color: #f2c810;
  padding: 12px 16px;
  width: 50px;
  text-align: center;
  position: relative;
}
.key_success_image {
  height: 32px;
}
@media screen and (min-width: 1023px) {
  .top_link {
    margin: 30px 13vw;
    color: #707070;
  }
  .para_text_container {
    margin: 0px 13vw;
  }
  .freelancer_para {
    padding: 5vw 13vw;
    margin-right: 11vw;
    border-radius: 0px 200px 200px 0px;
  }
  .freelancer_Heading {
    margin: 0px;
  }
  .freelancer_para_text {
    margin: 20px 40px 0px 0px;
  }
  .clients_para {
    padding: 5vw 0px;
    margin: 50px 0px 0px 11vw;
    border-radius: 240px 0px 0px 240px;
  }
  .clients_Heading {
    margin: 0px 13vw;
    text-align: right;
  }
  .clients_para_text {
    margin: 30px 13vw;
    text-align: right;
  }
  .core_values_heading {
    margin: 50px 0px;
  }
  .core_image {
   display: block;
  }
  .core_imageMob{
    display: none;
  }
  .core_values_text {
    margin: 40px 11vw;
  }
  .key_success_web {
    display: flex;
    justify-content: space-around;
  }
  .para_text_mob {
    margin: 0px 0px 0px 50px;
  }
  .para_text_mob_right {
    margin: 0px 50px 0px 0px;
  }
  .bottom_text_divLeft { 
    width: 35vw;
    margin-bottom: 30px;
    padding: 28px 0px 28px 0px;
  }
  .bottom_text_div {
    width: 35vw;
    margin-bottom: 30px;
    padding: 28px 0px 28px 70px;
  }
  .image_bg {
    padding: 18px 26px;
  }
  .image_bg_left {
    padding: 18px 26px;
  }
  .bottom_text_left {
    text-align: right;
    margin-right: 70px;
  }
}
