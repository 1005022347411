.mainContainer {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 5%;
  font-weight: bold;
}

.heading {
  font-weight: 800;
  font-size: 20px;
}
.iconDesign {
  font-size: 50px;
  margin-right: 20px;
  margin-left: 10px;
}
.content {
  color: grey;
}
.iconAndContent {
  display: flex;
  align-items: center;
}
.progressbar {
  margin-left: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #e9eaff;
}
.mandatoryFields {
  display: flex;
}
.mandatoryFieldsText {
  color: grey;
  margin-left: 5px;
}

.iconAndMandatoryText {
  display: flex;
  color: grey;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
}

.marginForHundred {
  margin-top: 3%;
}
@media screen and (min-width: 1023px) {
  .mainContainer {
    margin-top: 5%;
  }
}
