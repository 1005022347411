.shadowDiv {
  border-radius: 12px;
}
.shadowMainDiv {
  position: relative;
}
.cardImageDiv {
  width: 210px;
}
.banner {
  width: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
  opacity: 1;
}
.bannerDiv {
  width: 100%;
  position: absolute;
}
.bannerDiv .banner {
  opacity: 0.5;
}
.articleBanner {
  
  margin: auto;
  display: block;
}
.postedDateDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.iconDiv {
  font-size: 15px;
}
.postedText {
  font-size: 12px;
  font-weight: 700;
  padding-right: 2px;
}
.shadowDivHeading {
  padding: 15px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  background-color: #fff;
}
.cardMainDiv {
  padding: 20px;
}
.contentMainDiv {
  padding: 15px;
}
.imageCard {
  
  object-fit: cover;
}
.shadowDivContentHeading {
  font-weight: 800;
  font-size: 17px;
  margin: 20px 0 10px 0;
}
.cardHeading {
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 5px;
  line-height: 1.2;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.articleDiv {
  display: flex;
  overflow: scroll;
}
.articleCard {
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.cardContent {
  font-size: 13px;
  color: black;
  font-weight: 500;
  margin-top: 5px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.articleCardInfoDiv {
  display: flex;
  align-items: center;
}
.info {
  margin-left: 7px;
  font-size: 13px;
}
.articleDiv::-webkit-scrollbar {
  display: none;
}
.infoMainDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  color: black;
  width: 100%;
}
.icon,
.watchIcon {
  color: 848484px !important;
}
.iconLink {
  text-decoration: none;
  color: black;
  margin-left: 10px;
}
