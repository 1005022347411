.HelpFreelancers_flex {
  display: block;
  margin-top: 30px;
  width: 100%;
  justify-content: space-around;
  
}
.HelpFreelancers_image {
  display: none;
}
.HelpFreelancers_imagemob {
 
  text-align: center;
  margin: auto;
  display: block;
  padding: 20px;
  
}
.HelpFreelancers_mapdiv {
  text-align: center;
}
.HelpFreelancers_icons {
margin: auto;
 
  
}
.HelpFreelancers_iconradiusYellow {
   border-radius: 50px;
   height: 50px;
   width: 50px;
    padding: 10px;
    background: #fff5d6;
    margin: auto;
    display: flex;
    justify-content: center
}
.HelpFreelancers_iconradiusBlueOther {
   
  border-radius: 50px;
   height: 50px;
   width: 50px;
  padding: 10px;
  background: #C7D0DD;
  margin: auto;
  display: flex;
  justify-content: center
}

.HelpFreelancers_iconradiusblue {
   
  border-radius: 50px;
   height: 50px;
   width: 50px;
  padding: 10px;
  background: #f0efff;
  margin: auto;
  display: flex;
  justify-content: center
}
.HelpFreelancers_iconradiusGreen{
  border-radius: 50px;
   height: 50px;
   width: 50px;
  padding: 10px;
  background: #C6E0CB;
  margin: auto;
  display: flex;
  justify-content: center
}


.HelpFreelancers_textflex {
  display: flex;
  padding-top: 20px;
}
.HeadingText {
  color: #828282;
  padding: 10px;

}
.HelpFreelancers_mainheader {
  padding: 15px;
  margin-top: 40px;
}
.mainHeading{
  font-weight: bold;
  margin-top: 5px;
}
@media screen and (min-width: 1024px) {
  .HelpFreelancers_flex {
    display: flex;
    margin-top: 40px;
  }
  .HeadingText {
    width: 50%;
    margin: auto;
  
  }
  .HelpFreelancers_image {
    display: block;
   
     margin-right: 1rem;
     
  }
  .HelpFreelancers_maindiv {
      width: 75%;
  } 
  .HelpFreelancers_imagemob {
    display: none;
  }

  .HelpFreelancers_mainheader {
    padding-top: 20px;
    padding-left: 0;
  }
}

@media screen and (min-width: 1400px) {
    .HelpFreelancers_image {
        margin-top: 0;
   
      }
}


