.pageLayout {
  background-color: #f5f6fb;
}
.pageColumn {
  display: flex;
  justify-content: center;
}
.leftArea {
  width: 70%;
}
.rightArea {
  width: 30%;
}
.pageLoader{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}