.pageLayout {
    padding: 15px;
  }
  
  .pageDivider {
    display: flex;
  }
  
  .filterContainer {
    height: auto;
    width: 25%;
    background-color: white;
    padding: 20px;
  }
  
  .filterByHeading {
    font-weight: bold;
    padding: 10px 10px 3px 10px;
    font-size: 20px;
  
    border-bottom-width: 2px;
    border-bottom-color: black;
    border-bottom-style: solid;
  }
  
  .filterOptionHeadings {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .checkBoxContainer {
    display: flex;
    align-items: center;
    
  }
  
  .budgetTextInput {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .textInput {
    width: 50%;
    margin: 3px;
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .goButton {
    padding: 3px;
    border: 1px solid gray;
    height: fit-content;
  
    border-radius: 3px;
  }
  .goButton:hover {
    cursor: pointer;
    background-color: #1678f2;
    color: white;
  }
  
  .sortAndRequirmentContainer {
 
    background-color: white;
    margin-left: 10px;
  }
  
  .sortAndPageContainer {
    justify-content: center;
  }
  .sortContainer {
    display: flex;
    overflow: scroll;
    width: 100%;
    justify-content: space-around;
  }
  
  .sortHeading {
    font-weight: bold;
  }
  
  .sortHeading:hover {
    cursor: pointer;
  }
  
 
  .sortOptions:hover {
    cursor: pointer;
  }
  .sortOptionSelected {
    border-bottom-color: #c07fff;
    border-bottom-style: solid;
    border-bottom-width: 2px;
   width: fit-content;
    color: #c07fff;
    font-weight: bold;
  }
  
  .sortOptionSelected:hover {
    cursor: pointer;
  }
  
  .pageNumbersContainer {
    display: flex;
    margin-right: 10%;
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  }
  
  .pageNumbersContainerBottom {
    display: flex;
    margin-right: 10%;
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    justify-content: end;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  
  .pageCount {
    background-color: #1778f2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    width: 28px;
    margin: 2px 6px;
    color: #fff;
    font-size: 14px;
    height: 28px;
  }
  .searchBarContainer {
  
    display: flex;
    justify-content: center;
  
    width: 100%;
  }
  .searchIcon {
    padding: 10px 8px;
    height: 22px;
    background-color: #1678f2;
    color: white;
    margin-top: 13px;
  }
  
  .searchIcon:hover {
    cursor: pointer;
    background-color: #0c58b4;
  }
  
  .searchInput {
    padding: 3px 0px;
    width: 50%;
  }
  .searchInput:focus {
    outline: none;
  
    border: 1px solid #1678f2;
    border-right-width: 0px;
  }
  .searchInput::placeholder {
    font-size: 16px;
    color: rgb(197, 197, 197);
  }
  
  .borderLine {
    border-bottom: 1px solid #dedede;
    width: 40%;
  }
  
  .buttons1 {
    display: flex;
  }
  .pageCountIcon {
    color: #1778f2;
    margin: 2px 6px;
  }
  .pageCountIcon:hover {
    cursor: pointer;
  }
  .PaginationContainer {
    padding: 22px 0px;
    text-align: center;
    
  }
  .buttonone {
    color: #fff;
    background-color: #1778f2;
    padding: 8px 19px;
    border: none;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    border-radius: 5px;
  }
  .CurrentPageText{
    border: 1px solid #1778f2;
    border-radius: 8px;
    padding: 3px 10px;
    width: 30px;
    margin-right: 5px;
    color: #1778f2;
    margin-left: 8px;
  }
  .CurrentPageTextNext{
    border: 1px solid gray;
    border-radius: 8px;
    padding: 3px 10px;
    width: 30px;
   
  }
  .CurrentPageDotted{
    margin-top: 8px;
    margin-right: 10px;
    margin-left: 5px;
  }
  .buttononePre{
   color: gray;
    font-size: 15px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px 14px; 
    margin-top: 6px;
    margin-left: 3px;
  }
  
  @media screen and (min-width: 768px) {
    .pageLayout {
      padding: 15px;
      background-color: #f5f6fb;
    }
    .PaginationContainer {
      padding: 22px 22px 8px 22px;
      text-align: right;
    }
    .searchInput {
      padding: 3px 0px;
      width: 100%;
    }
    .sortAndPageContainer {
      display: flex;
      padding: 10px;
      padding-bottom: 0px;
      justify-content: space-between;
    }
    .pageNumbersContainer {
      justify-content: inherit;
      margin-top: 0;
    }
    .sortContainer {
      display: grid;
      justify-content: inherit;
      
    }
   
  }