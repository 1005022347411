.buttonBox {
  background-color: #fcd017;
  color: black;
  height: auto;
  min-width: 60%;
  border-radius: 6px;
  display: flex;
  margin: auto;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.buttonBoxFullWidth {
  background-color: #fcd017;
  color: black;
  height: auto;
  min-width: 100%;
  border-radius: 6px;
  display: flex;
  margin: auto;
  padding: 4px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  cursor: pointer;
}
.flagContainerFocus {
  display: flex;
  border: 1px solid #1778f2;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}
.flagContainer {
  display: flex;
  border: 1px solid #ece9e9;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}
.uldropdown {
  list-style: none;
  margin-left: -30px;
}
.flagImage {
  height: 18px;
  width: 30px;
  margin-right: 10px;
  margin-top: 5px;
}
.dialCode {
  outline: none;
  border: none;
  font-size: 18px;
  margin: 5px 0px 8px 12px;
}
.contriesList {
  height: 200px;
  overflow: scroll;
  margin-top: -10px;
  border: 1px solid #ece9e9;
  border-top: none;
  background-color: #fff !important;
  transition: all 0.3s ease;
}

.mobileWrapper {
  display: flex;
  align-items: center;
}
.dropdownLi {
  background-color: white;
  list-style: none;
  text-align: left;
  margin: 4px;
  cursor: pointer;
}
.dropdownLi:hover {
  color: #858585;
}
.uploadBoxIcon {
  padding-right: 15px;
}
.uploadBoxIconContainer {
  align-items: center;
  display: flex;
}
.dualButton {
  display: flex;
  justify-content: center;
}
.buttonBoxdual {
  background-color: #fcd017;

  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 40%;
}
.buttonBoxdualSecond {
  background-color: #dad1d1;
  color: rgb(36, 29, 29);
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 40%;
  margin-right: 10px;
}
.buttonBoxWhite {
  background-color: #ffffff;
  color: #0f587d;
  height: auto;
  border-radius: 6px;

  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  border: none;
}
.editBtnContainer {
  background-color: #fcd017;
  border-radius: 6px;

  padding: 3px 12px;
  border: none;
  cursor: pointer;
}

.buttonBoxLeft {
  background-color: #fcd017;

  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 40%;
}

.buttonBoxLeft:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.buttonBoxsmall {
  background-color: #fcd017;

  border-radius: 6px;

  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 100px;
}

.buttonBoxsmall:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.buttonBoxPlan {
  border-radius: 6px;
  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid grey;
  min-width: 100px;
  cursor: pointer;
}
.buttonBoxPlan:hover {
  background-color: #fcd017;
  border-radius: 6px;
  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 100px;
  cursor: pointer;
}
.buttonBoxHeader {
  background-color: #fcd017;
  height: 38px;
  margin: auto;
  border-radius: 6px;
  display: flex;
  padding: 0px 30px;
  width: 40%;
  margin-top: 25px;
  border: none;
}

.buttonBoxmedium {
  background-color: #000;
  color: #fff;
  height: auto;
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  border: none;
}
.buttonBoxmedium:hover {
  cursor: pointer;
  background-color: #1778f2;
}

.buttonBoxSignup {
  background-color: #000;
  color: #fff;
  height: auto;
  display: flex;
  padding: 5px 3px 5px 3px;
  border: none;
  margin-left: 30px;
}
.buttonBoxSignup:hover {
  cursor: pointer;
  background-color: #282f39;
}
.buttonText {
  align-self: center;
  margin: auto;
  padding: 0px 10px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}

.buttonTextSignUp {
  align-self: center;
  margin: auto;
  padding: 0px 10px;
}

.flagContainer {
  display: flex;
  border: 1px solid #ece9e9;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}

.buttonBox:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.buttonBoxWhite:hover {
  background-color: #1778f2;
  color: white;
  cursor: pointer;
}

.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.inputBoxContainer {
  padding: 20px;
}

/* iconinput--------------------------- */

.newRadioButton {
  margin-right: 12px;
  margin-top: 20px;
}

.iconInputDiv {
  border: 1px solid #ece9e9;
  display: flex;
  padding: 4px;
  align-items: center;
}
.focuedIconInputDiv {
  border: 1px solid #27b1be;
  display: flex;
  padding: 4px;
  
}

.inputAreaIcon {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.icon {
  margin-left: 10px;
  margin-right: 5px;
}
.inputAreaSmall {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  max-width: 400px;
  margin: 10px 0px;
  font-family: inherit;
  font-size: 16px;
}
.inputAreaSmall::placeholder {
  font-size: 16px;
  font-family: inherit;
}

.inputAreaIcon::placeholder {
  font-size: 16px;
 padding-top: 4px;
}

.divider {
  margin-left: 20px;
}

/* iconinput------------------------------------ */

.inputArea_name {
  padding: 10px 10px;
  border: 1px solid #ece9e9;
  outline: none;
  margin: 10px 0px;
  font-size: 18px;
}
.inputArea_name:focus {
  border-color: #27b1be;
}

.inputArea {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  word-break: break-word;
  margin: 10px 0px;
  font-size: 18px;
}
.inputArea:focus {
  border-color: #27b1be;
}
.inputArea_dropdown {
  border: 1px solid #ece9e9;
  height: 45px;
  font-size: 18px;
  outline: none;
  padding: 12px;
  cursor: pointer;
  background-color: white;
  margin: 15px 0px;
  color: #000;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>');
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}

.inputArea_dropdown:focus {
  border-color: #27b1be;
}
.uploadIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.inputArea_upload {
  padding: 10px 10px;
  outline: none;
  display: flex;
  text-align: center;
  height: 200px;
  width: 50%;
  border: 1px solid #ece9e9;
  margin: 10px 0px 30px;
  cursor: pointer;
  font-size: 18px;
}
.inputArea_withIcon {
  padding: 12px;
  width: 90%;
  border: none;
  font-size: 18px;
  outline: none;
}
.inputArea_withIcon:focus {
  border-color: #27b1be;
}
.option_dropdown {
  height: 45px;
  cursor: pointer;
}
.input_fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  border: 1px solid #ece9e9;
}
.input_fields:focus {
  border-color: #27b1be;
}
.eyeIcon {
  margin-right: 10px;
  height: 23px;
  width: 23px;
  cursor: pointer;
}
.tickIcon {
  color: #2ec72e;
  margin-right: 10px;
  height: 24px;
  width: 24px;
}
.CheckboxStyle {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ece9e9;
  margin: 7px 5px;
  cursor: pointer;
}
.CheckBoxInput {
  display: flex;
}
.form_upload_label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.inputArea_Custom_dropdown {
  border: none;
  padding: 6px;
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  outline: none;
  padding-top: 9px;
  /* background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>'); */
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}
::placeholder {
  font-size: 18px;
  color: #858585;
}
.inputAreaDateContainer {
  width: 100%;
  height: 45px;
  margin: 15px 0px;
}
.inputAreadate {
  background-color: #fff;
  font-size: 18px;
  width: 97%;
  border: 1px solid #ece9e9;
  outline: none;
  padding: 10px 5px;
}
.inputAreadate:focus {
  border: 1px solid #27b1be;
}
.CheckBox_Text {
  padding-left: 28px;
  color: #808080;
  font-size: 14x;
}
.CheckBoxInput_Text {
  padding-left: 8px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.checkBox_input {
  height: 19px;
  cursor: pointer;
}

.RiTextTransition {
  color: #000;
  font-size: 25px;
}

.mainHeadingContainer {
  font-size: 40px;
  padding: 10px;
  font-family: "Manrope", sans-serif;
}

.newHeadingFirst {
  margin-bottom: 7px;
}

.thirdLine {
  color: #858585;
  font-size: 20px;
}
.thirdLineWhite {
  color: #fff;
  font-size: 30px;
}

.headingTwo {
  margin-top: -10px;
  line-height: 1.2;
}
.input_fields_mobile {
  border: 1px solid #27b1be;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.IconContainer {
  display: flex;
  align-items: center;
}
.inputArea_nameSocial {
  padding: 10px 10px;
  border: 1px solid #ece9e9;
  outline: none;
  margin: 10px 0px;
  width: 100%;
  font-size: 18px;
  margin-left: 12px;
}
.cityloader {
  margin-top: 4rem;
}

@media screen and (min-width: 1023px) {
  .mainHeadingContainer {
    font-size: 60px;
    padding: 0;
    font-family: "Manrope", sans-serif;
  }
  .RiTextTransition {
    color: #000;
    font-size: 40px;
  }
  .newHeadingFirst {
    margin-bottom: 0;
  }

  .buttonBoxLeft {
    min-width: 300px;
  }

  .buttonBoxLeft {
    min-width: 300px;
  }
}
