
.mainContainer{
    padding: 10px;
    margin-top: 40px;
}
.boxesAlingment{
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    
}
.headingBox{
    width: 45%;
   font-size: 30px;
    
    font-family: "Poppins", sans-serif;
    line-height: 30px;
    margin: auto;
}
.contentBoxHeading{
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
    padding-top: 20px;
}
.contentBoxSubHeading{
    font-size: 13px;
}
.contentBoxMainContent{
    font-size: 15px;
    padding-top: 20px;
    padding: 10px;
}
.BoxA{
    width: 48%;
    background-color: #282F39; 
    height: 180px;
    color: white;
    text-align: center;
    
    
}

.BoxColorChange{
    width: 48%;
    background-color: #606060; 
    height: 180px;
    color: white;
    text-align: center;
}
.imageBox{
    width: 48%;
    background-color: #282F39; 
    height: 180px;
}
.boxImage{
    /* width: auto;
    max-width: 100%;
    object-fit:cover; */
}

.wideImageAlingment{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.iconInBoxes{
    font-size: 20px;
    margin-top: 20px;
}

@media screen and (min-width: 1024px) {
.boxesAlingment{
    justify-content: center;
}
    .headingBox{
        width: 200px;
       font-size: 40px;
       height: 250px;
        font-family: "Poppins", sans-serif;
        line-height: 50px;
        margin: 10px;
    }
.BoxA{
    width: 200px;
    background-color: #282F39; 
    height: 250px;
    color: white;
    text-align: center;
    margin: 10px;
     
}
.BoxColorChange{
    width: 200px;
    background-color: #606060; 
    height: 250px;
    color: white;
    text-align: center;
    margin: 10px;
}
.imageBox{
    width: 200px;
    background-color: #282F39; 
    height: 250px;
    margin: 10px;
}
.mainContainer{
    width: 80%;
    margin: auto;
    padding-top: 100px;
}

.wideImageAlingment{
    margin-left: 0px;
    width:auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin: 10px;
   
    height: 250px;
}



}