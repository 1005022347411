.mainContainer{
    background-color: white;
    border-radius: 3px;
    border: 1px solid #c8c8c8;
    padding: 10px 20px;
    margin-top: 10px;
    height: 76px;

}
.heading{
    margin: 5px 0;
    font-weight: 800;
    font-size: 25px;

}

.arrowIcon{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  font-size: 15px;
}



