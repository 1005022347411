.top_link {
  margin: 28px 0px;
  color: #707070;
}
.home_link {
  text-decoration: none;
  color: #707070;
}
.home_linkTerm{
  color: #707070;
  text-decoration: none;
}
.home_link:first-child {
  color: #1778f2;
}
.terms_container {
  margin: 20px;
}
.terms_para {
  margin: 20px 0px;
  text-align: justify;
}
.terms_heading {
  text-align: left;
  color: #1778f2;
  text-decoration: none;
}
.ordered_list {
  margin: 30px 0px;
  text-align: justify;
}
.ordered_li {
  margin: 20px 0px;
}
.ordered_li::marker {
  margin-left: 10px;
}
.subHeading_overview {
  margin-top: 5px;
  font-weight: 400;
}
.overView_heading {
  margin: 0;
  margin-top: 40px;
  font-weight: 600;
}
@media screen and (min-width: 1023px) {
  .terms_container {
    margin: 40px 13vw;
  }
  .terms_heading {
    margin-bottom: 35px;
  }
}
