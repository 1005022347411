.mainContainer {
  padding: 15px;
  background-color: white;
  margin-top: 10px;
  margin-right: 10px;
}

.heading {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
}
.mainHeading {
  padding: 10px;
  font-weight: 800;
  font-size: 20px;
}

.currentPlanMainDiv {
  visibility: hidden;
}

.activePlan {
  display: flex;
  justify-content: right;
  width: 88%;
}

.mainHeadingData {
  text-align: center;
  padding: 10px;
  font-weight: 800;
  font-size: 20px;
}
.planSelection {
  border: 1px solid black;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}

.active {
  background-color: #4cb3be;
  color: #ffff;
  border: 1px solid #4cb3be;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}

.planMainDiv {
  display: flex;
  flex-direction: row-reverse;
  overflow: scroll;
  margin-bottom: 10px;
}
.planMainDiv::-webkit-scrollbar {
  display: none;
}
.tableFormat {
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 2%;
  border: 1px solid #7070702e;
}
.selectedPackageDetails {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin-top: 30px;
}

.packageType {
  font-size: 15px;
  font-weight: 600;
}

.packageAmount {
  font-size: 30px;
  font-weight: 800;
  margin-left: 10px;
}
.tableHeads {
  padding: 10px;
  font-size: 11px;
}
.tableData {
  padding: 10px;
  font-size: 11px;
  text-align: center;
  border-bottom: 1px solid #7070702e;
}

.planHeading {
  padding: 10px;
  font-size: 11px;
}
.fistSectionHeads {
  width: 70%;
}

.tableHeadsBorder {
  border-bottom: 1px solid #7070702e;
}

.displayTable {
  display: flex;
  flex-direction: row-reverse;
  width: 30%;
}

.tableContents {
  width: 100%;
  text-align: center;
}

.tableContentsSelected {
  width: 100%;
  text-align: center;
  background-color: #4cb3be;
  color: #ffffff;
}
.currentPlanInnerDiv {
  margin-top: 20px;
  width: 100%;
}
.packageNameHeadingDiv {
  display: flex;
  border: 1px solid #7070702e;
  justify-content: space-between;
  align-items: center;
}
.packageNameContent {
  padding: 10px;
}
.packageNameHeading {
  font-weight: 800;
  padding: 10px;
}
.CurrentPlanContainer {
  display: flex;
  justify-content: space-between;

  margin-top: 5%;
}

.CurrentPlansection {
  font-weight: 600;
  color: #000;
}

@media (min-width: 1023px) {
  .mainContainer {
    padding: 15px;
    background-color: white;
    margin-top: 10px;
    margin-right: 10px;
  }
  .ActionBtnContainer {
    display: flex;
    justify-content: right;
    flex-direction: row-reverse;
    width: 80%;
    margin: auto;
  }
  .contents {
    padding: 10px;
    font-size: 16px;
    border-bottom: 1px solid #7070702e;
  }
  .CurrentPlanContainer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-top: 22px;
  }
  .tableFormat {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 1%;
    border: 1px solid #7070702e;
  }
  .tableContents {
    width: 100%;
    text-align: center;
  }

  .tableContentsSelected {
    width: 100%;
    text-align: center;
    background-color: #4cb3be;
    color: #ffffff;
  }
  .selectedPackageDetails {
    display: flex;
    width: 50%;
  }
  .buyPlanButton {
    width: 16%;
    margin-left: 4px;
  }
  .mainHeading {
    font-weight: 800;
    font-size: 20px;
  }

  .fistSectionHeads {
    width: 100%;
  }

  .displayinOrder {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }
  .contentsBold {
    padding: 10px;
    font-weight: 800;
    font-size: 20px;
  }

  .tableHeads {
    padding: 10px;
    font-size: 16px;
  }

  .currentPlanInnerDiv {
    width: 40%;
  }
  .packageNameHeadingDiv {
    display: flex;
    border: 1px solid #7070702e;
    justify-content: space-between;
  }
  .packageNameContent {
    padding: 10px;
  }
  .packageNameHeading {
    font-weight: 800;
    padding: 10px;
  }
}
