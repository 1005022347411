.Container {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
 
  border-radius: 3px;
  border: 1px solid #c8c8c8;
  padding: 10px 20px;

}
.priceWrapper {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c8c8c8;
}
.packageTitle {
  margin: 0;
  font-size: 25px;
  margin-bottom: -3px;
  font-weight: 900;
  
}

.GetStarted{
  text-decoration: none;
  font-size: 21px;
}
.price {
  font-size: 25px;
  font-weight: bold;
}
.perYear {
  font-size: 12px;
}
.planStartWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c8c8c8;
  margin-top: 1rem;
  padding-bottom: 5px;
}
.buttonplan {
  background-color: #fdca51;
  border: none;
  outline: none;
  margin-top: 1rem;
  padding: 7px 18px;
  border-radius: 4px;
}
.buttonplan > svg {
  margin-right: 4px;
}

.creditCount{
  font-size: 25px;
}
.creditLeft{
  font-size: 14px;
}

@media screen and (min-width: 760px) {

  .Container {
    box-sizing: border-box;  
  }

}
