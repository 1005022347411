.mainContainer {
  background-color: white;
}

.mainCardContainer {
  background-color: white;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);

  margin-top: 20px;

  border-radius: 5px;
}
.imagecontainer {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.workType {
  color: indigo;
  font-weight: bolder;

  margin-left: 10px;
}
.tagFeatured {
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;
  margin-left: 6px;
  margin-top: 8px;
}
.featuredListing {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.addonNameIcon {
  margin-right: 5px;
 
}
.AddonName {
  font-size: 15px;
  color: #7c7c7c;
}
.addonContainer {
  display: flex;
  align-items: center;
}
.twoSectionDivider {
  display: flex;
  flex-direction: column;
}

.skillContainer {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}

.titleArea {
  margin-top: 10px;
}

.postType {
  margin-left: 5px;
}
.skillTags {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  color: #707070;
  background-color: #f5f5f5;
  width: fit-content;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
}
.skilltagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.statusAndMenu {
  font-weight: bold;
  font-size: 20px;
}
.menuWithStatus {
  text-align: left;
  width: 100%;
}
.dropDownStatus {
  align-self: flex-end;
}
.ViewBtnContainer {
  margin: 0px 7px;
}
.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2%;
}
.icondots {
  margin-top: 6px;
}
.icondots:hover {
  cursor: pointer;
}
.workPolicy {
  padding: 3px 7px;
  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: fit-content;
  font-size: 14px;
}
.iconContainer {
  display: flex;
  margin-bottom: 5px;
}

.workingType {
  display: flex;
  align-items: center;
  margin-left: 2%;
}
.mobileMenuOption {
  display: flex;
}
.statusAnddate {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  color: grey;
}
.highlightText {
  font-weight: bold;
  margin-top: 10px;
}

.Commission {
  color: #fea11b;
}

.Contract {
  color: #486a31;
}
.One-Time {
  color: #365aa9;
}
.Monthly {
  color: #125552;
}
.requirementCardButton {
  padding: 10px;
  border-width: 1px;
  border-color: #365aa9;
  border-style: solid;
  margin-top: 10px;
  color: #365aa9;
  font-size: 14px;
}
.requirementCardButton:hover {
  cursor: pointer;
}
.buttonWithIconContainer {
  display: flex;
  justify-content: end;
}
.colorBlue {
  color: #365aa9;
  font-weight: normal;
}
.workStatus {
  background-color: #d6ffe3;
  border-radius: 5px;
  padding: 5px;

  font-weight: bold;
  font-size: 14px;
  color: #43c962;
  width: fit-content;
}

.postedDate {
  font-style: italic;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
}
.NoRequirementCard {
  margin: 10px 12px;
}
.NameDiv {
  padding: 2px 0px 0px 0px;
}
.leftSectionWidth {
  width: 100%;
  margin: 0px 7px;
}
@media (min-width: 768px) {
  .mainContainer {
    padding: 15px;
    margin-left: 10px;
  }
  .mainCardContainer {
    margin-right: 10px;
    width: 80%;
  }
  .leftSectionWidth {
    width: 60%;
    margin: 2%;
  }

  .highlightText {
    margin-top: 0;
    justify-content: end;

    display: flex;
  }
  .twoSectionDivider {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .menuWithStatus {
    width: 40%;
  }
  .featuredListing {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .tagFeatured {
    margin-top: 4px;
  }
  .ViewBtnContainer {
    margin: 0px;
    text-align: center;
  }
}
