.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
    position: absolute;
    background-color: #fff;
    font-weight: 500;
    top: 20vh;
    height: 300px;
    width: 100vw;
    
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
    
  }
  .button {
    width: 70px;
    color: #f02f39;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .ActionBtn {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 768px) {
    .modal {
      width: 20rem;
      left: calc(60% - 20rem);
    }
  }
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .ModalCheckIcon {
    text-align: center;
  }
  .ModalCheckTextFirst {
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    
  }
  .ModalCheckTextSecond {
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    font-weight: bold;
  }
  .ModalCheckTextthird {
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    
  }
  .modalcheck {
    height: 60px;
    width: 60px;
    margin-top: -60px;
    border: 1px solid;
    padding: 20px;
    border-radius: 50%;
    background-color: #0c2551;
    color: #fff;
    border-color: #0c2551;
  }
  .ModalCheckIconText {
    text-align: center;
    font-weight: bold;
  }
  