

  .mainHeadingContainer{
    font-size: 40px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    text-align: center;
  } 
  
   .thirdLine{
    color: #252525;
    font-size: 20px;
padding-top:5px;
   
  } 
   .thirdLineWhite{
    color: #fff;
    font-size: 20px;
   
  } 
  
  .headingTwo{
    margin-top: -10px;
    line-height: 1.2;
    
  
  }
  @media screen and (min-width: 1023px) {
    .mainHeadingContainer{
      font-size: 60px;
      font-weight: 500;
      font-family: 'Manrope', sans-serif;
    }
 
  }