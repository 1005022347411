.mainDiv {
  padding: 60px;
}
.imageDiv {
  display: flex;
}
.hireImage {
  margin-left: 35px;
  
}
.headingDiv {
  width: 50%;
  margin-top: 100px;
}

.helpYouHireDiv {
  margin-top: 70px;
}
.faq_ActionButton {
  display: flex;
  justify-content: center;
}
.searchInput {
  width: 100%;
  outline: none;
  border: none;
  padding-top: 4px;
}
.workTypeHeading {
  /* margin-top: 80px; */
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.workTypeSubHeading {
  text-align: center;
  font-weight: 600;
}
.PARBtn_Link {
  text-decoration: none;
}
.inputDiv {
  padding: 6px;
  display: flex;
  justify-content: center;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #999999;
  margin: auto;
  align-items: center;
  cursor: pointer;
}
.searchIcon {
  margin-left: 50px;
}

.mainHeading {
  margin-top: 25px;
  font-weight: 800;
  font-size: 40px;
  line-height: 1.2;
  margin: 0px;
}

.subHeading {
  font-size: 18px;
  font-weight: 700;
  
}
.faqHeading {
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  padding: 30px 0px 0px 0px;
  margin: 0px;
}
.mapDiv {
  margin-top: 20px;
  justify-content: center;
  padding: 30px;
}
.faqInnerDiv {
  width: 30%;
  padding-right: 45px;
}
.faqSubHeading {
  font-weight: 800;
  font-size: 18px;
}
.faqText {
  font-size: 15px;
}
.readMore {
  width: 80px;
  margin-top: 10px;
  font-size: 12px;
  padding: 4px;
  text-align: center;
  border-radius: 10px;
  color: #1778f2;
  background-color: #f1f7ff;
  cursor: pointer;
}
.hoetohireSec {
  width: 80%;
  margin: auto;
}

.rightWork{
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0px;
}