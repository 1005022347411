.mainContainer {
  background-color: #fff;
  width: 100%;
  padding-bottom: 30px;
}
.uploadIcon {
  position: absolute;
  right: 130px;
  top: 119px;
}
.mainProfileImageContainer {
  height: 200px;
  background-color: green;
  width: 100%;
}
.mainProfileImage {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.editBtn {
  display: flex;
  justify-content: flex-end;

  margin: -60px 20px 44px 0px;
}

.profilePictureNameContainer {
  margin-top: -80px;
  text-align: center;

  width: 100%;
}
.profilePicture {
  height: 150px;
  width: 150px;
  background-color: #ffff00;
  border-radius: 50%;
  margin: auto;
}
.freelancerName {
  font-size: 30px;
  font-weight: bold;
  padding: 10px 0px;
}

.nameAndSkillContainer {
  text-align: center;
}

.skillCat {
  font-size: 20px;
  text-transform: capitalize;
}
.updateImage {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.editBtn {
  display: flex;
  justify-content: flex-end;
}
.mainHeading {
  font-weight: bold;
  font-size: 18px;
}
.socialContainer {
  text-align: center;
  padding: 20px 0px;
}
.ratingContainer {
  text-align: center;
}
.iconContainer {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  justify-content: center;
}
.socialIcon {
  padding-right: 12px;
  cursor: pointer;
}
.flagImage {
  height: 25px;
  margin-right: 10px;
  margin-top: 5px;
}
.ratings {
  padding: 10px;
}
.ratingStar {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1023px) {
  .profilePictureNameContainer {
    width: auto;
    margin-top: -70px;
    margin-left: 2%;
  }
  .uploadIcon {
    position: absolute;
    right: 20px;
    top: 117px;
  }
  .mainContainer {
    background-color: #fff;
    width: 35%;
    padding-left: 60px;
    padding-bottom: 30px;
  }
  .skillCat {
    font-size: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .nameAndSkillContainer {
    text-align: left;
  }
  .profilePicture {
    height: 150px;
    width: 150px;
    background-color: #ffff00;
    border-radius: 50%;
    margin: 0px;
  }
  .socialContainer {
    text-align: left;
    padding: 20px 0px;
  }
  .ratings {
    text-align: left;
    padding: 10px 0px;
  }
  .ratingStar {
    justify-content: unset;
  }
  .iconContainer {
    display: flex;
    flex-direction: row;
    margin: 8px 0px;
    justify-content: unset;
  }
}
