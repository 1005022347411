.contentDiv {
    padding: 20px;
  }
  .readMore {
    font-size: 11px;
    background: #f1f7ff;
    width: 20%;
    padding: 2px;
    text-align: center;
    color: #1778f2;
    border-radius: 10px;
  }
  .heading {
    font-size: 25px;
    font-weight: 800;
    line-height: 1.2;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .subHeading {
    font-weight: 600;
    font-size: 13px;
  }
  .faqContent {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .faqContentDiv {
    margin-top: 30px;
  }
  .faqSubHeading {
    font-weight: 800;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .bannerMob {
   
    margin-top: 10px;
  }
  .faqHeading {
    font-weight: 600;
    font-size: 48px;
    letter-spacing: 1px;
    text-align: center;
  }
  .workTypeHeading {
    margin-top: 0px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding-top: 60px;
  }
  .faq_ActionButton {
    display: flex;
    justify-content: center;
}
  .inputDiv {
    margin-top: 20px;
    display: flex;
    width: 100%;
    padding: 5px;
    border: 1px solid #dfdfdf;
    cursor: pointer;
  }
  .icon {
    font-size: 25px;
    color: #dfdfdf;
  }
  
  .inputArea {
    width: 100%;
    border: none;
    outline: none;
  }
  .workTypeSubHeading {
    text-align: center;
    font-weight: 600;
  }
  .PARBtn_Link {
    text-decoration: none;
  }
  
  @media screen and (min-width: 768px) {
    .faqMainDiv {
      margin-top: 30px;
      justify-content: center;
      padding-left: 10px;
    }
    .faqContentDiv {
      width: 30%;
    }
  
    .readMore {
      font-size: 10px;
      background: #f1f7ff;
      width: 30%;
      padding: 1px;
      color: #1778f2;
      border-radius: 10px;
    }
    .inputDiv {
      margin: auto;
      margin-top: 20px;
      display: flex;
      width: 90%;
      padding: 5px;
      border: 1px solid #dfdfdf;
    }
  }
  