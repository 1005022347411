.pageLayout {
  padding: 15px;
}
.sortAndRequirmentContainer {
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 10px;
}
.headingforApplication{
  font-weight: bold;
  font-size: 20px;

}

.sortAndPageContainer {
  justify-content: center;
}
.sortContainer {
  display: flex;
}
.sortHeading{
    font-weight: bold;
}

.sortHeading:hover{
    cursor: pointer;
}

.sortOptions{
    color: blue;
   
    margin-left: 20px;
}

.sortOptions:hover{
    cursor: pointer;
}

.pageNumbersContainer{
 display: flex;
margin-right: 10%;
font-weight: bold;
font-size: 20px;
align-items: center;
justify-content: center;
margin-top: 5%;

}

.pageCount{
    margin-left: 20px;
}
.pageCount:hover{
    cursor: pointer;

}

@media (max-width: 768px) {
  .pageLayout {
    padding: 15px;
    background-color: #f5f6fb;
  }

  .sortAndPageContainer {
    display: flex;
    padding: 10px;
    background-color: white;
    justify-content: space-between;
    margin-left: 10px;
  }
  .pageNumbersContainer {
    justify-content: inherit;
    margin-top: 0;
  }
}
