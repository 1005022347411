

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Pushster&family=Teko:wght@500&display=swap');
.mainPage {
  background-color: white;
  -webkit-tap-highlight-color: transparent;
}

div {
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  
}

h1 {
  color: #1e1e1e;
  font-family: 'Manrope', sans-serif;
  font-size: 35px;
  font-weight: 600;
  
  
}
h2 {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}
h3 {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}
label {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 500;
}
p {
  font-family:"Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}


h6{
  font-family: 'Teko', sans-serif;
}

textTrasition{
  font-family: 'Pushster', cursive;
}

.screeWidth{
  max-width: 1600px;

  margin: auto;
}


.linkTag{
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  display: none;
}
