.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    background-color: #FCD017;
    color: black;
    position: relative;
    font-weight: bold;
    top: 12vh;
    height: 80px;
    width: 250px;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 30;
    align-items: center;
  }
  .button {
    width: 70px;
    color: black;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  @media (min-width: 768px) {
    .modal {
      width: 20rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  