.mainContainer{
    background-color: #F6F2F1;
    padding:15px;
    padding-bottom: 0px;
    margin-top: 40px;
}

.contentForFuture{

    color: grey;
    text-align: center;
}
.imageAlignment{
    text-align: center;
    margin-top: 30px;
   
}

.KnowMoreTab{
    text-align: right;
    color: #9398a1;
font-weight: 500;
font-size: 16px;
padding: 10px;

align-items: center;
align-content: center;
justify-content: center;
display: flex;
}

.iconArrow{
    margin-top: 3px;
    font-size: 16px;
}
.webImageAlignment{
    display: none;
}
.knowmoreClick{
    text-decoration: none;
    cursor: pointer;
    color: grey;
    align-items: center;
    display: flex;
}
@media screen and (min-width: 768px) {
  
  .imageAlignment{
     display: none;
  }
  .imageAndContent{
    display: flex;
    justify-content: space-around;
  }
  .webContentArea{
      display: grid;
      width: 50%;
  }
  .contentForFuture{
font-size: 22px;
}
.webImageAlignment{
    display: flex;
}
}