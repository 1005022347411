.mainContainer{
padding: 10px;
background-color: white;
border: 1px solid #c8c8c8;
margin-top: 10px;
margin-right: 10px;
text-align: center;
}
.iconForIncome{
    margin: 3%;
}
.incomeMessage{
    font-size: 20px;
    margin: 3%;
}