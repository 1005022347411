.ArticlesFreelancers_maindiv {
  background: #ececec;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.ArticlesFreelancers_Display_Web {
  margin: auto;
  padding-top: 2%;
}
.ArticlesFreelancers_image {
  /* width: 120px; */
  object-fit: cover;

}

.imageAndArticleText_Mob {
  display: flex;
  margin: 10px;
  margin-top: 20px;
}
.ArticlesFreelancers_headingBold_Web {
  font-weight: bold;
  font-size: 14px;
}

.ArticlesFreelancers_headingBold_Web:hover {
  color: #1878f2;
  cursor: pointer;
}
.subhedingArticle_Web {
  font-size: 12px;
  color: gray;
}
.subhedingArticle_Web:hover {
  color: rgb(0, 0, 0);
  cursor: pointer;
}
.articlesHeadingSubHeading {
  margin-left: 10px;
}
@media screen and (min-width: 1024px) {
  .ArticlesFreelancers_Display_Web {
    width: 60%;
    display: flex;
    margin: auto;
    padding-top: 2%;
    justify-content: space-around;
    align-items: flex-start;
  }

  .ArticlesFreelancers_Container_Web {
    width: 100%;
  }

  .imageAndArticleText_Web {
    width: 70%;
  }
  .ArticlesFreelancers_headingBold_Web {
    font-weight: bold;
    font-size: 14px;
  }
  .subhedingArticle_Web {
    font-size: 12px;
    color: gray;
  }
  .ArticlesFreelancers_Container {
    display: block;
    width: 28%;

    border: none;
  }
  .ArticlesFreelancers_image {
 
    object-fit: cover;
  }
 
  
}
