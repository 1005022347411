.cancelModel {
  text-align: end;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}

.modal {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 525px;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 2;
  padding-bottom: 30px;
}
.backIcon {
  cursor: pointer;
  height: 25px;
}
.button {
  width: 70px;
  color: #1778f2;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.mainContainer {
  padding: 0px 30px;
}
@media screen and (max-width: 1023px) {
  .modal {
    width: 80%;
  }
}
