
.imageAndContentContainer {
  position: relative;
  text-align: center;
}

.mainContent {
  position: absolute;
  color: rgb(255, 255, 255);

  line-height: 1.2;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.mainContentStyle {
  font-size: 30px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin: 0px;
}

.kickStart {
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #fcd017;
}
.yellowLine {
  width: 40%;
  height: 5px;
  padding-left: 10%;
  margin: 10px;
  background-color: #fcd017;
  margin-left: 20px;
}

@media screen and (min-width: 1024px) {
  .imageAndContentContainerWeb {
    position: relative;
  }
  .mainContent {
    margin-left: 5%;
    left: 25%;
    width: auto;
  }
  .yellowLine {
    width: 2%;
    margin-left: 0px;
  }
  .mainContentStyle {
    font-size: 50px;
  }
  .kickStart {
    font-size: 50px;
  }
}
