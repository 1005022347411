.mainContainer {
  padding: 0px 20px;
  width: 90%;
}

.input_container {
  margin-top: 25px;
}
.radio {
  margin: 13px 0px;
}
.CheckBoxInput {
  cursor: pointer;
}
.inputArea {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}
.RadioOne {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
}
.RadioTwo {
  border: 1px solid #ece9e9;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.image_container {
  display: none;
}
.ActionBtn {
  display: flex;
  justify-content: center;
}
.back_button {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.socialHeading {
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px;
}
.webView_image {
  display: flex;
  width: 13vw;
  margin: auto;
}
.image_container {
  display: block;
  width: 40%;
}
.form_upload_label {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid #ece9e9;
  align-items: center;
}
.uploadLogoText {
  margin-left: 30px;
}
.updateImage {
  display: flex;
  
  border-radius: 60px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.imageEditIcon {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
  background: white;
  margin-left: -39px;
  border: 1px solid #848484;
  align-items: center;
}
.uploadContainer {
  display: flex;
  align-items: center;
}
.profilePhoto {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alternateFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.aternateFieldText {
  padding-left: 10px;
}
.updatetImageContainer {
  display: flex;
  align-items: end;
}
.uploadIcon {
  cursor: pointer;
  color: gray;
  height: 22px;
  width: 22px;
  margin: auto;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.subHeading {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.heading {
  font-size: 19px;
  font-weight: 700;
  padding-top: 30px;
}
.Maincontainer_border {
  margin: auto;
  background-color: #fff;
}
.disabledInput {
  pointer-events: none;
  opacity: 0.4;
}
.borderBottomHeading {
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.manadatoryFields{
  color: red;
}
@media (min-width: 1023px) {
  .mainContainer {
    width: auto;
  }
  .web_view {
    display: flex;
    width: 80%;
    align-items: center;
  }
  .profilePhoto {
    padding-top: 30px;
  }
  .Maincontainer_border {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .twoColoumnContainer {
    display: flex;
    padding: 5px 0px;
    flex-direction: row;
  }
  .genderDobContainer {
    display: flex;
    padding: 5px 0px;
    /* align-items: center; */
    flex-direction: row;
  }
  .twoColoumnField {
    width: 350px;
    padding-right: 25px;
  }
  .mainHeading {
    font-size: 25px;
    font-weight: bold;
    margin: 0px;
  }
  .socialContainer {
    width: 89%;
  }
}
