.packageHeading {
  text-align: center;
  margin-top: 25px;
  font-weight: 800;
  font-size: 25px;
}

.planSelection {
  border: 1px solid black;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}
.planMainDiv {
  display: flex;
  overflow: scroll;
  margin-bottom: 10px;
}

.planMainDiv::-webkit-scrollbar {
  display: none;
}
.active {
  background-color: #4cb3be;
  color: #ffff;
  border: 1px solid #4cb3be;
  width: 40%;
  padding: 18px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: 700;
}
.selectButtonDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.selectButton {
  padding: 9px;
  width: 150px;
  border: #f6ca48;
  border-radius: 5px;
  background-color: #f6ca48;
  font-weight: 700;
}

.currentPlanMainDiv {
  margin: 10px;
  border: 1px solid #7070702e;
  border-radius: 5px;
}
.currentPlanDiv {
  display: flex;
  justify-content: space-between;
}
.currentPlanHeading {
  font-size: 18px;
  padding: 15px;
}
.currentPlanSubHeading {
  font-size: 15px;
  font-weight: 900;
  padding: 15px;
}
.planDurationDiv {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #7070702e;
}
.planDurationMainDiv {
  margin-top: 20px;
}

.planDuration {
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
}
.packDetailsHeading {
  padding: 2px 15px;
  font-size: 15px;
  font-weight: 900;
}
