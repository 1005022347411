.mainContainer {
  background-color: white;
  margin: 10px 10px 0px 10px;
  padding: 10px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}
.addonNameIcon {
  margin-right: 5px;
  width: 15px;
}
.AddonName {
  font-size: 12px;
  color: #7c7c7c;
}
.dateAndPostType {
  color: grey;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  padding-bottom: 5px;
}
.dateAndPostTypeSection {
  margin-top: 10px;
}
.LocationSection {
  display: flex;
  align-items: center;
}
.contentIndetail {
  margin-top: 20px;
  font-size: 14px;
}
.logoPlacement {
  margin-top: 20px;
}
.riLogo {
  width: 50px;
  margin-right: 10px;
}
.LocationText {
  font-size: 15px;
  color: gray;
}
.CompanyLogowithText {
  display: flex;
  align-items: center;
}
.Comapany_Name {
  padding-top: 10px;
}
.skilltagsContainer {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}
.CompanyLogo {
  max-width: 120px;
  max-height: 300px;
}
.skillTags {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: fit-content;
  margin-right: 10px;
  font-size: 14px;
}

.location {
  margin-top: 20px;
}
.tagFeatured {
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;
  margin-right: 8px;
}
.featuredListing {
  display: flex;
  align-items: center;
  margin: 3px 0px 12px 0px;
}
.addonContainer {
  display: flex;
}
.AccpetedProposal{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 12px; */
  /* flex-direction: column; */
}
.ClientRStatusText{
  font-size: 18px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 4px;
  color: #365aa9;
  text-align: center;
  border: none;
  padding: 3px 16px;
  font-style: italic;
  border-radius: 4px;
}
.ClientRStatusTextR{
  font-size: 18px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 0px;
  color: rgb(250, 0, 0);
  text-align: center;
}
.AppliedCase{
  display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.AppliedCaseChat{
  font-size: 18px;
  color: #365aa9;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Loader{
  display: flex;
  justify-content: center;
  padding: 100px 0px;
}
.timeApplyBtn{
display: flex;
justify-content: space-between;
align-items: center;
}
.alignButton{
  display: block;
}
.closePostStatus{
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  color: #bb2409;
  padding-right: 10px;
  border: 1px solid #bb2409;
  padding: 2px 8px;
  border-radius: 2px;
}
@media (min-width: 1023px) {
  .skillTags {
    margin: 5px;
    font-size: 14px;
  }
  .mainContainer {
    background-color: white;
    margin: 10px;
    padding: 20px;
    min-height: 80%;
  }
  .title {
    width: 70%;
  }
  .featuredListing {
    display: none;
  }
  .alignButton{
    display: none;
  }
}
