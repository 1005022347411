.profileProgressCard {
  border: 1px solid #c8c8c8;
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
}
.mainDiv {
  display: flex;
  background-color: #fbfbf5;
}
.amountDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.amount {
  font-weight: 900;
  font-size: 40px;
}
.profileCompeletion {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
}
.myEarnings {
  font-weight: 800;
  font-size: 25px;
}

.arrowIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fdca51;
  padding: 3px;
}
.profileCompleteButton {
  margin-top: 20px;
  background-color: #fdca51;
  border: 1px solid #fdca51;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .mainDiv {
    flex-wrap: wrap;
  }
  .profileProgressCard {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
}
