.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.modal {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;

  padding: 8px 9px 23px 9px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}

.ActionBtn {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 30px;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.ModalCheckIcon {
  text-align: center;
}
.ModalCheckTextFirst {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
}
.ModalCheckTextSecond {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
  font-weight: bold;
}
.modalcheck {
  width: 25%;
}
.modalthird {
  width: 30%;
  height: 30px;
}
.crossicon {
  text-align: end;
}

@media screen and (max-width: 768px) {
  .modal {
    margin: 0px 20px;
    height: 200px;
  }
}
