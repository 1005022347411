.mainContainer {
  padding: 0px 30px 40px;
  background-color: white;

}
.loginWith_Otp {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}
.loginWith_Otp {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}
.Maincontainer_border {
  background-color: white;
}
.inputFields_check {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.forgot_password {
  color: #858585;
  text-align: center;
  margin-top: 15px;
}
.resend_code {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
}
.resend_code_hover {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
  cursor: pointer;
}
.resend_code_hover:hover {
  color: #858585;
}
.checkbox {
  padding: 2px;
}
.para_text {
  margin-top: 40px;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.ActionBtn {
  display: flex;
  justify-content: center;
}
.back_button {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.MobileNumber {
  padding-bottom: 30px;
}
.LoadingBtn {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;

}
  .actionButtonContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
@media screen and (min-width: 1024px) {
  .mainContainer {
    padding: 0px 30px 40px;
  }
  .Pagewrapper {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
    height: 100vh;
  }
  .Maincontainer_border {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}
