.welcomeMessage{
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.firstWelcome{
    font-size: 20px;

    display: flex;
}

.snippetsCollection{
    display: flex;
    
}
.welcomeName{

    margin-left: 5px;
    font-weight: bold;
}
.bannerContainer{
  margin-left: 10px;
    margin-right: 10px;
}
.banner{
    
   margin-top: 10px;
}
.noreqAndProfile{
    display: flex;
}


.reqAndUpgradeOption{
    display: flex;
    align-items: center;
    width: 100%;
}
.RequirementCard_Package{
    width: 60%;
}
.PackageCard{
    width: 30%;
}
.recReq{
  
    margin-top: 10px;
    margin-bottom: 10px;
}

.leftArea{

width: 65%;
margin-right: 10px;
}

.rightArea{
    width: 35%;
    margin-right: 10px;
}

.pageDivider{
    display: flex;
}

@media (min-width: 768px) {
    .welcomeMessage{
        margin-right: 10px;
    }

    .bannerContainer{
        margin-left: 0px;
    }
   
    .recReq{
        border: 1px solid #c8c8c8;
        background-color: white;
    }

    .pageDivider{
        display: flex;
    }
  
}