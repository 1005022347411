.faq_question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 15px;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}
.faq_questiondiv {
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
}

.faq_answer {
  border-bottom: 1px solid #dfdfdf;
  margin-top: 20px;
  padding-bottom: 20px;
  color: #5d5d5d;
  font-family: "Manrope";
  font-size: 15px;
}

.faq_border {
  margin: 15px 0px;
}

.faq_icon {
  width: auto;
}

@media screen and (min-width: 1024px) {
  .faq_icon {
    width: auto;
  }
  .faq_question {
    font-size: 18px;
  }

  .faq_answer {
    font-size: 16px;
  }
}
