.mainContainer {
  background-color: #ffffff;
  height: min-content;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mainHeading {
  font-weight: 800;
  padding: 10px;
  font-size: 25px;
}

@media (min-width: 768px) {
  .mainContainer {
    border: 1px solid #c8c8c8;
    padding: 20px;
  }
}
