.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    overflow: scroll;
}
.mainContainer::-webkit-scrollbar {
    display: none;
  }
.tab{
    padding: 15px 8px;
    white-space: nowrap;
    font-weight: 600;
    margin: 0px 5px;
    color: #000;
    text-decoration: none;
    font-size: 17px;
    background-color: #fff;
    align-self: center;
}
.tabSelected{
    padding: 15px 8px;
    white-space: nowrap;
    color: #000;
    font-weight: 800;
    font-size: 17px;
    margin: 0px 10px;
    text-decoration: none;
    border-bottom: 3px solid #1778f2;
    align-self: center;
}