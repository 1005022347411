.welcomeMessage {
  background-color: white;
 
  margin-top: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.firstWelcome {
  font-size: 20px;

  display: flex;
}

.snippetsCollection {
  display: flex;
}
.welcomeName {
  margin-left: 5px;
  font-weight: bold;
}
.bannerContainer {
  margin-left: 10px;
  margin-right: 10px;
}
.banner {
  width: 100%;
  margin-top: 10px;
}
.noreqAndProfile {
  display: flex;
}

.reqAndUpgradeOption {
  display: flex;
  align-items: center;
  width: 100%;
}
.RequirementCard_Package {
  width: 60%;
}
.PackageCard {
  width: 40%;
}

@media (min-width: 768px) {
  .bannerContainer {
    margin-left: 0px;
  }
}
