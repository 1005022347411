.mainDiv {
  background-color: #f8f8f8;
}

.radioSubLabel {
  text-align: left;
  font-size: 15px;
  color: grey;
}

.newLabel {
  margin-left: 28px;
  font-weight: 700;
  font-size: 17px;
  margin-top: 23px;
}

.finalAmount {
  margin-left: 10px;
}
.addonNameIcon {
  margin-right: 5px;
  width: 20px;
}
.addonContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.AddonName {
  font-size: 15px;
  color: #7c7c7c;
}

.mainHeading {
  font-weight: bolder;
  font-size: 20px;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.mainHeadingInner {
  font-weight: 900;
}

.formDiv {
  padding: 20px;
  margin-top: 10px;
  background-color: #ffffff;
}
.units {
 font-size: 18px;
  font-weight: 600;
}

.riInputs {

  margin-left: 28px;

}
.clientsBudgetDiv {
  display: flex;
  align-items: center;
}

.tipDiv {
  border: 1px solid gray;
  margin: 20px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
}

.mainSubHeading {
  font-size: 15px;
  color: grey;
}
.tipTextMainDiv {
  display: flex;
}
.clientsBudget {
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 14px;
}
.subHeading {
  font-weight: bolder;
  font-size: 20px;
}
.type {
  font-weight: 700;
  margin-left: 5px;
}
.addOnDiv {
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.duration {
  display: flex;
  align-items: center;
  font-weight: 500;
    font-size: 14px;
    font-style: italic;
color: grey;
}
.durationIcon {
  margin-right: 5px;
  margin-top: 5px;
}
.descriptionContainer {
  margin-top: 40px;
  width: 100%;
}
.tipIcon {
  color: goldenrod;
}
.tipText {
  font-size: 14px;
  color: grey;
  margin-bottom: 10px;
}
.tipsHeading {
  font-size: 20px;
  color: grey;
  margin-bottom: 10px;
}
.totalAmountHeading {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 800;
  margin-top: 14px;
  margin-left: 4px;
  margin-bottom: 3px;
}
.error_message {
  color: rgb(250, 0, 0);
  font-size: 12px;
  margin-left: 26px;
  margin-top: 5px;
}
.agreementDiv {
  display: flex;
  
  margin-top: 25px;
}

.agreementText {
  margin-left: 10px;
}

.freelancerUnit {
  font-weight: 600;
  color: grey;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 7px;
}

.agreementTextBlue {
  color: #1778f2;
  font-weight: bolder;
  margin-left: 3px;
}
.decisionButtonsDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 26px;
}
.chat {
  font-size: 18px;
  margin-right: 3px;
  color: #1778f2;
  font-weight: 700;
}
.closeForm {
  font-size: 9px;
  font-weight: 700;
  cursor: pointer;
}
.cancel {
  font-size: 12px;
  margin-right: 15px;
  margin-left: 15px;
}
.mainInputContainer {
  margin-top: 17px;
}

.closeIcon {
  text-align: end;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.addonNameIcon {
  margin-right: 5px;
}
.ClientBudgetText{
  display: flex;
  
  margin: 14px 0px 0px 0px;
  
}
.ClientBudgetHeading{
  font-size: 18px;
  font-weight: 600;
  margin: 0px 6px 0px 0px;
}
.ClientBudgetSubText{
  font-size: 18px;
  font-weight: 600;
  margin: 12px 20px 0px 0px;
  width: 220px;
}
.ErrorMessage{
  color: crimson;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
}
.buttonBoxsmall {
  background-color: #fcd017;
color: #1778f2;
  border-radius: 6px;
text-align: center;
  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 100px;
}


@media (min-width: 1023px) {
  .mainHeadingDiv {
    padding-top: 8px;
  }
  .ClientBudgetSubText{
    
    width: 145px;
  }
  .addOnsMain {
    display: flex;
    align-items: center;
  }
  .addOnDiv {
    display: flex;
  }
  .mainSubHeading {
    font-size: 16px;
    color: grey;
  }
  .radioSubLabel {
    text-align: left;
    font-size: 15px;
    color: grey;
  }

  .closeForm {
    font-size: 12px;
    font-weight: 700;
  }
  .riInputs {

    display: flex;
    align-items: center;
    margin-top: 15px;

  }

  .freelancerUnit {
    font-weight: 600;
    margin-left: 20px;
    margin-top: 7px;
    font-size: 16px;
  }

  .mainHeading {
    font-weight: 900;
    font-size: 30px;
    padding-left: 50px;
    background-color: #ffffff;
  }
  .mainHeadingInner {
    font-weight: 900;
  }
  .clientsBudget {
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
    margin-top: 14px;
  }
  .tipDiv {
    border: 1px solid gray;
    padding: 20px;
    width: 24%;
    height: 0%;
    text-align: left;
    border-radius: 10px;
  }

  .tipTextMainDiv {
    display: flex;
  }
  .formDiv {
    padding:30px 50px;
    margin-top: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }
  .subHeading {
    font-weight: 800;
    font-size: 30px;
  }
  .addonNameIcon {
    
    margin-right: 8px;
  }
  .duration {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 5px;
  }
  .proposalDetailsHeading {
    font-weight: 700;
    margin-top: 26px;
    font-size: 24px;
  }
  .multilineInput::placeholder {
    font-size: 16px;
    padding: 10px;
  }
  .amountDuration {
    font-size: 14px;
    font-weight: bolder;
  }
  .totalAmountHeading {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 700;
    margin-top: 20px;
  }
  .inputContainer {
    margin-top: 20px;
  }
  .addOn {
    margin-left: 10px;
  }
  .addOnCheckText {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 800;
  }
  .cancel {
    font-size: 16px;
    cursor: pointer;
  }
  .addOnSubText {
    font-size: 16px;
  }

  .descriptionContainer {
    margin-left: 4px;
  }
}
