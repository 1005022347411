.mainContainer {
  padding: 20px;
 
}

.labelDesign {
  margin: 15px 0px 10px 0px;
  font-family: "Roboto", sans-serif;
  font-size: large;
  font-weight: 400;
}
.radio {
  margin: 13px 0px;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.RadioOne {
  border: 1px solid #ece9e9;
  padding: 8px;
  cursor: pointer;
}
.RadioTwo {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 8px;
  cursor: pointer;
}

.input_container{
  margin-top: 25px;
}
.inputArea {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}


.ActionBtn {
  display: flex;
  justify-content: center;
}
.back_button {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.uploadLogoText{
  margin-left: 30px;
  align-self: center;
}
.updateImage {
display: flex;

border-radius: 60px;
border: 1px solid #ece9e9;
align-items: center;
}
.imageEditIcon {
display: flex;
height: 30px;
width: 30px;
border-radius: 50px;
cursor: pointer;
background: white;
margin-left: -39px;
border: 1px solid #848484;
align-items: center;
}
.uploadContainer {
display: flex;
align-items: center;
}
.updatetImageContainer {
display: flex;
align-items: end;
}
.form_upload_label {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid #ece9e9;
  align-items: center;
}
.uploadIcon {
cursor: pointer;
color: gray;
height: 22px;
width: 22px;
margin: auto;
}
.subHeading {
  font-size: 15px;
  padding-bottom: 10px;
  color: grey;
}
.heading {
  font-size: 19px;
  font-weight: 700;
  padding-top: 30px;
}
.Maincontainer_border{
  width: 100%;
  margin: auto;
  background-color: #fff;
}
.selectContainer{
  margin: 13px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.optionTile{
  padding: 30px;
  border: 1px solid #ece9e9;
  border-radius: 10px;
  cursor: pointer;
}
.optionTileSelected{
  padding: 30px;
  border: 1px solid #1778f2;
  border-radius: 10px;
  cursor: pointer;
}
.TileText{
  font-weight: 600;
  text-align: center;
}
.companyLogoName{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
}
.borderBottomHeading{
  border: 2px solid gray;
  width: 90px;
  margin-bottom: 10px;
}
.inputSections{
  margin: 20px 0;
}

.companyLogoAndContent{
  display: flex;
  align-items: center;
}
.uploadLogoContent{
  margin-left: 20px;
}
.manadatoryFields{
  color: red;
}
@media (min-width: 1023px) {
  .mainContainer {
    padding:0px 20px;
  }
  .web_view {
    display: flex;
    align-items: center;
  }
  .Maincontainer_border {
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 40px;
    display: flex;
    border-color: #e2e2e2;
    border-top-width: 0px;
  }
  .webView_image {
    display: flex;
    width: 13vw;
    margin: auto;
  }
  .mainHeading {
    font-size: 25px;
    font-weight: bold;
  margin: 0px;
  }
  .radio{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  .RadioOne {
    width: 300px;
  }
  .RadioTwo {
   width: 300px;
   margin-top: 0px;
   margin-left: 10px;
  }
  .twoColoumnField{
    width: 295px;
  }
  .twoColoumnContainer{
    display: flex;
    padding: 5px 0px;
    flex-direction: row;
    justify-content: space-between;
  }

}
