

.mainLogoDiv{
    width: 100%;
   margin-top: -10px;
    height: 90px;
    display: flex;
    width: 100%;
    justify-content:space-evenly;
  align-content: center;
  margin: auto;
}

.logoArea{
    width: 18%;
}


.logoAreaWeb{
    width: 7%;
}

.mainContainer{
    background-color: #27B1BE;
    margin-top: -10px;
   
}
.mapdiv {
    height: 50px;
    margin: auto;
    padding-top: 30px;
  }
  
  .mob_img {
   
    margin: auto;
    outline: none;
  }

@media screen and (min-width: 768px) {
    .logoArea{
        width: 12%;
    }
    .mainLogoDiv{
        max-width: 1366px;
        margin-top: -10px;
    }
   
}

  